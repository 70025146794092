import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { fetchMediaListRequest, saveMediaListFilter } from '../../actions/mediaList';
import { incMediaStat } from '../../actions/media';
import MediaMasonry from '../../components/Media/MediaMasonry';
import Stub from '../../components/MediaMasonry/Stub';
import gtm, { analyticsEvent } from '../../utils/gtm';
import { send } from '../../utils/ws';
import parseMediaFilter from '../../utils/parseMediaFilter';

class MediaMasonryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this);
    this.handleMediaClick = this.handleMediaClick.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.save) {
      this.props.saveMediaListFilter(this.props.id);
    }

    if (!this.props.isLoaded || this.props.forceUpdate) {
      this.loadMediaList(this.props.id, 0, this.props.limit);
    }
  }

  loadMediaList(id, skip, limit) {
    this.props.fetchMediaListRequest(id, skip, limit);
  }

  handleInfiniteLoad() {
    if (this.props.mediaList) {
      this.loadMediaList(this.props.id, this.props.realMediaListSize, this.props.limit);
    }
  }

  handleMediaClick(media) {
    this.props.incMediaStat({ ...this.props.statPayload, media, autoPlay: this.props.autoPlay, type: 'click' });
  }

  handleContextMenu(media) {
    const mainTag = get(this.props, 'statPayload.tag');
    const message = ['click', mainTag || '-tsymbal-', media, this.props.locale].join('\n');
    send(message);

    if (mainTag) {
      analyticsEvent('ab', 'Использовал гифку после поиска');
    }

    this.props.incMediaStat({ ...this.props.statPayload, media, autoPlay: this.props.autoPlay, type: 'other' });
    const event = this.props.contextMenuEvent || (this.props.isMobile ? 'mobile_copy_image_s' : 'desktop_right_button_s');
    gtm({ event });
  }

  render() {
    if (this.props.isLoaded) {
      if (this.props.mediaList.length === 0) return this.props.empty;
      return (
        <MediaMasonry
          {...this.props}
          onClick={this.handleMediaClick}
          onInfiniteLoad={this.handleInfiniteLoad}
          onContextMenu={this.handleContextMenu}
        />
      );
    }

    return (
      <Stub {...this.props} itemsCount={8} itemHeight={this.props.isMobile ? 150 : 250} />
    );
  }
}

MediaMasonryContainer.propTypes = {
  id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  save: PropTypes.bool,
  skip: PropTypes.number, // eslint-disable-line
  limit: PropTypes.number,
  autoPlay: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  realMediaListSize: PropTypes.number.isRequired,
  empty: PropTypes.element,
  contextMenuEvent: PropTypes.string,
  statPayload: PropTypes.object, // eslint-disable-line
  mediaList: PropTypes.arrayOf(PropTypes.string),
  fetchMediaListRequest: PropTypes.func.isRequired,
  saveMediaListFilter: PropTypes.func.isRequired,
  incMediaStat: PropTypes.func.isRequired,
  forceUpdate: PropTypes.bool,
};

MediaMasonryContainer.defaultProps = {
  isMobile: false,
  save: false,
  mediaList: null,
  empty: null,
  skip: 0,
  limit: 80,
  statPayload: {},
  contextMenuEvent: undefined,
  forceUpdate: false,
};

const mapStateToProps = (state, props) => {
  const columnCount = state.env.isMobile // eslint-disable-line
    ? (typeof window === 'undefined' ? 1 : 2)
    : (typeof window === 'undefined' ? 3 : 4);
  const list = state.media.lists[props.id] || {};
  let mediaList = list.ids;
  if (mediaList && props.mainMedia) {
    // always start from the beginning if we go through related gifs
    const filter = parseMediaFilter(props.id);
    const i = filter.section === 'media' ? 0 : mediaList.indexOf(props.mainMedia);

    if (i >= 0) {
      const minimumLength = Math.min(20, mediaList.length);
      let newList = mediaList.slice(i + 1);
      if (newList.length < minimumLength) {
        newList = [
          ...newList,
          ...mediaList.slice(0, minimumLength - newList.length - 1),
        ];
      }
      mediaList = newList;
    }
  }

  return {
    mediaList,
    columnCount,
    isMobile: state.env.isMobile,
    isLoaded: !!list.isLoaded,
    isLoading: !!list.isLoading,
    realMediaListSize: list.ids ? list.ids.length : 0,
    mediaEntities: state.media.entities,
    gutter: state.env.isMobile ? 5 : 15,
    autoPlay: state.autoPlay,
    locale: state.intl.locale,
  };
};

const mapDispatchToProps = {
  fetchMediaListRequest,
  saveMediaListFilter,
  incMediaStat,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaMasonryContainer);
