export const CHANGE_ENV_VARIABLE = 'CHANGE_ENV_VARIABLE';
export const INIT_ENV = 'INIT_ENV';

export const changeEnvVariable = (name, value) => ({
  name,
  value,
  type: CHANGE_ENV_VARIABLE,
});

export const initEnv = () => ({
  type: INIT_ENV,
});
