export default (userAgent) => {
  if (/telegrambot/i.test(userAgent)) return 'telegram';
  else if (/twitterbot.*facebookexternalhit|facebookexternalhit.*twitterbot/i.test(userAgent)) return 'iMessage';
  else if (/developers.google.com\/\+\/web\/snippet/i.test(userAgent)) return 'googleplus';
  else if (/twitterbot/i.test(userAgent)) return 'twitter';
  else if (/facebook/i.test(userAgent)) return 'facebook';
  else if (/vkshare/i.test(userAgent)) return 'vk';
  else if (/odklbot/i.test(userAgent)) return 'ok';
  else if (/google/i.test(userAgent)) return 'google';
  else if (/redditbot/i.test(userAgent)) return 'reddit';
  else if (/whatsapp/i.test(userAgent)) return 'whatsapp';
  return 'browser';
};
