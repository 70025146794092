import * as actions from '../../actions/upload';

const initialState = {
  progress: {},
  count: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.START_UPLOAD:
      return {
        ...state,
        progress: {},
        count: action.files.length,
      };
    case actions.FILE_UPLOAD_REQUEST:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.id]: 0,
        },
      };
    case actions.FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.id]: action.progress >= 100 ? 99 : action.progress,
        },
      };
    case actions.FILE_UPLOAD_FAILURE:
    case actions.FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.id]: 100,
        },
      };
    }
    default: return state;
  }
};
