import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape } from 'react-intl';
import PopularMedia from './PopularMedia';
import Meta from '../../containers/Meta';
import EventsPanel from '../../containers/EventsPanel';
import SearchHints from '../../containers/PageHome/SearchHints';
import SmilesPanel from '../../containers/SmilesPanel';
import { getGalleryLdJson } from '../../utils/ldJson';
import getShareSocialImage from '../../utils/getShareSocialImage';
import Adsense from '../Adsense';
import AnalyticsPageView from '../AnalyticsPageView';
import './index.css';

const PageHome = ({ isMobile, firstMedia, server, intl, locale }) => {
  let searchLdJson;
  let galleryLdJson;

  if (firstMedia && typeof window === 'undefined') {
    searchLdJson = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: 'https://gifer.com/',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://gifer.com/s/{search_term_string}',
        'query-input': 'required name=search_term_string',
      },
    };

    galleryLdJson = getGalleryLdJson({
      intl,
      media: firstMedia,
      server,
      messageValues: {},
      seedKey: firstMedia.id,
      url: process.env.REACT_APP_DOMAIN,
      intlPrefix: 'page_home.article_',
    });
  }

  return (
    <main className={`page-home ${isMobile ? 'mobile' : 'desktop'}`}>
      <article>
        <AnalyticsPageView />
        <Meta
          id="page_home"
          url="/"
          meta={[
            {
              property: 'og:image',
              content: getShareSocialImage(locale),
            },
          ]}
        />
        <Helmet>
          {searchLdJson && <script type="application/ld+json">{JSON.stringify(searchLdJson)}</script>}
          {galleryLdJson && <script type="application/ld+json">{JSON.stringify(galleryLdJson)}</script>}
        </Helmet>
        <section>
          <SearchHints />
        </section>

        <section className="page-home__smiles">
          <SmilesPanel />
        </section>

        <section className="page-home__events">
          <EventsPanel isMobile={isMobile} />
        </section>

        <Adsense slot={9628811922} />

        <section>
          <PopularMedia isMobile={isMobile} />
        </section>
      </article>
    </main>
  );
};

PageHome.propTypes = {
  isMobile: PropTypes.bool,
  intl: intlShape.isRequired,
  firstMedia: PropTypes.object,  // eslint-disable-line
  server: PropTypes.string,
  locale: PropTypes.string,
};

PageHome.defaultProps = {
  isMobile: false,
  server: undefined,
  locale: 'en',
};

export default injectIntl(PageHome);
