import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontIcon, Button } from '../../Common';
import Media from '../../../containers/Media';
import { tagToURI } from '../../../utils/tagToURI';
import './index.scss';

const MediaTags = props => (
  <ul itemProp={props.itemProp} className="media-thumb-tags">
    {props.tags.map(({ tag }) => (
      <li key={tag.id}>
        <Link to={`/gifs/${tagToURI(tag.id)}`}>{tag.id}</Link>
        &nbsp;
      </li>
    ))}
  </ul>
);

MediaTags.propTypes = {
  itemProp: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
};

MediaTags.defaultProps = {
  tags: [],
  itemProp: undefined,
};

class MediaThumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mouseHovered: false };
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClick() {
    this.props.onClick(this.props.id);
  }

  handleMouseEnter() {
    if (this.props.isMobile) return;
    this.timeout = setTimeout(() => {
      this.setState({ mouseHovered: true });
    }, 100);
  }

  handleMouseLeave() {
    if (this.props.isMobile) return;
    clearTimeout(this.timeout);
    this.setState({ mouseHovered: false });
  }

  render() {
    const classes = [
      'media-thumb',
      this.props.isMobile ? 'mobile' : 'desktop',
    ];

    if (typeof window === 'undefined') {
      classes.push('static');
    }

    if (this.props.safeStatus === 'danger') {
      classes.push('danger');
    }

    const showSchemaOrg = !!this.props.mainMedia;
    const showTags = typeof window === 'undefined' || (this.props.showActions && this.state.mouseHovered);

    return (
      <div
        itemProp={this.props.itemProp}
        itemScope={showSchemaOrg}
        itemType={showSchemaOrg ? 'http://schema.org/ImageObject' : null}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      >
        <figure className={classes.join(' ')}>
          <Link
            itemProp={showSchemaOrg ? 'url' : null}
            to={{
              pathname: this.props.href || `/${this.props.id}`,
              state: { thumb: true },
            }}
          >
            <Media
              alt={this.props.alt}
              addMetaContentUrl
              paddingTop={this.props.paddingTop}
              id={this.props.id}
              autoPlay={this.state.mouseHovered}
              mainMedia={this.props.mainMedia}
              onContextMenu={this.props.onContextMenu}
              imagePreviewAltId={this.props.imagePreviewAltId}
            />

            {this.props.title && <figcaption>{this.props.title}</figcaption>}
          </Link>

          {this.props.showActions && this.state.mouseHovered && (
            <div className="media-thumb__actions">
              <Button
                onClick={this.props.onClickLike}
                type="transparent"
                icon={(
                  <FontIcon
                    name="icon-like"
                    color={this.props.liked ? '#ef4a4a' : 'white'}
                    size={24}
                  />
                )}
              />
              <CopyToClipboard text={this.props.downloadURL}>
                <Button
                  onClick={this.props.onClickCopy}
                  type="transparent"
                  icon={(
                    <FontIcon
                      name="icon-copy-url"
                      color="white"
                      size={24}
                    />
                  )}
                />
              </CopyToClipboard>
            </div>
          )}

          {showTags && this.props.tags && (
          <figcaption>
            <MediaTags
              itemProp={showSchemaOrg ? 'keywords' : null}
              tags={this.props.tags}
            />
          </figcaption>
          )}
        </figure>
      </div>
    );
  }
}

MediaThumb.propTypes = {
  isMobile: PropTypes.bool,
  href: PropTypes.string,
  mainMedia: PropTypes.string,
  itemProp: PropTypes.string,
  id: PropTypes.string,
  alt: PropTypes.string,
  paddingTop: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.node,
  liked: PropTypes.bool,
  downloadURL: PropTypes.string,
  imagePreviewAltId: PropTypes.string,
  showActions: PropTypes.bool,
  onClick: PropTypes.func,
  onClickLike: PropTypes.func,
  onClickCopy: PropTypes.func,
  onContextMenu: PropTypes.func,
  safeStatus: PropTypes.string,
};

MediaThumb.defaultProps = {
  mainMedia: undefined,
  itemProp: undefined,
  id: undefined,
  alt: undefined,
  paddingTop: undefined,
  tags: undefined,
  title: undefined,
  downloadURL: undefined,
  liked: undefined,
  href: undefined,
  isMobile: false,
  imagePreviewAltId: undefined,
  showActions: true,
  onClickLike: undefined,
  onClickCopy: undefined,
  onContextMenu: undefined,
  safeStatus: undefined,
  onClick: () => { },
};

export default MediaThumb;
