import { connect } from 'react-redux';
import Categories from '../../components/VerticalMenuDesktop/Categories/index';

const mapStateToProps = (state) => {
  const categories = Object.values(state.categories.entities);
  const specialCategory = categories.find(category => category.special) || {};

  return {
    categories,
    specialCategory,
    myId: state.auth.myId,
    isAuth: !!state.auth.myId,
  };
};

export default connect(mapStateToProps)(Categories);
