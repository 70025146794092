import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MasonryObserver from '../MasonryObserver';
import SmartTags from '../../containers/SmartTags';
import Meta from '../../containers/Meta';
import { tagFromURI } from '../../utils/tagToURI';
import './index.css';
import { getGalleryLdJson } from '../../utils/ldJson';
import { getMediaSrc } from '../../utils/url';
import getPageTagAltId from '../../utils/getPageTagAltId';
import Adsense from '../Adsense';
import gtm from '../../utils/gtm';
import AnalyticsPageView from '../AnalyticsPageView';

class PageSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewAltId: undefined,
    };
  }

  UNSAFE_componentWillMount() {
    this.setPageTagAltId();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.setPageTagAltId();
    }
  }

  setPageTagAltId() {
    const imagePreviewAltId = getPageTagAltId(this.props.match.params.id);
    this.setState({ imagePreviewAltId });

    if (this.props.history.action === 'POP') {
      gtm({ event: imagePreviewAltId, dimension2: imagePreviewAltId });
    }

    return imagePreviewAltId;
  }

  render() {
    const { intl } = this.context;
    const { server, isUnsafeTag } = this.props;
    const tagName = this.props.name;
    const Name = tagName.charAt(0).toUpperCase() + tagName.slice(1);
    const url = `${process.env.REACT_APP_DOMAIN}/${this.props.locale}/gifs/${this.props.match.params.id}`;
    const messageValues = {
      ...this.props.messageValues,
      name: tagName,
      count: this.props.count,
      Name,
      page: 1,
    };
    const media = this.props.firstMedia;
    let ldJSON;

    if (media && this.props.showLdJSON && typeof window === 'undefined') {
      ldJSON = getGalleryLdJson({
        url,
        intl,
        media,
        server,
        messageValues,
        seedKey: tagName,
        intlPrefix: 'page_tag.article_',
      });
    }

    return (
      <div className="page-tag" itemProp="mainEntity" itemScope="image" itemType="http://schema.org/ImageGallery">
        <AnalyticsPageView ref={this.props.match.params.id} />
        <Helmet>
          {ldJSON && <script type="application/ld+json">{JSON.stringify(ldJSON)}</script>}
          {this.props.count === 0 && <meta name="robots" content="noindex, follow" />}
        </Helmet>
        <Meta
          id="page_tag"
          values={messageValues}
          url={this.props.url}
          showHreflang={this.props.showHreflang}
          meta={media ? [{
            property: 'og:image',
            content: getMediaSrc(this.props.server, media.file.path, 'gif'),
          }] : undefined}
        />

        {(this.props.tagExists || media) && !isUnsafeTag && (
          <div className="page-tag__banner">
            <Adsense slot={1884740190} />
          </div>
        )}

        <div className="header">
          <div className="header-text">

            <link itemProp="url" content={url} />
            <link itemProp="mainEntityOfPage" content={url} />
            <meta itemProp="keywords" content={intl.formatMessage({ id: 'page_tag.article_keywords' }, messageValues)} />

            <div className="page-tag__tag-name">
              <h1 itemProp="headline name">
                <FormattedHTMLMessage
                  id="page_tag.tag_name"
                  values={{ name: tagName }}
                />
              </h1>
            </div>
            {typeof window === 'undefined' && (
              <div className="page-tag__tag-description">
                <FormattedHTMLMessage
                  id="page_tag.article_description"
                  values={messageValues}
                />
              </div>
            )}
          </div>
        </div>

        <div className="page-tag__smart-tags">
          <SmartTags
            showMore={false}
            listId={this.props.mediaFilter}
            exclude={[tagFromURI(this.props.match.params.id)]}
          />
        </div>

        <MasonryObserver
          save
          locale={this.props.locale}
          query={this.props.match.params.id}
          id={this.props.mediaFilter}
          statPayload={{ tag: tagFromURI(this.props.match.params.id) }}
          imagePreviewAltId={this.state.imagePreviewAltId}
        />
      </div>
    );
  }
}

PageSearch.propTypes = {
  url: PropTypes.string.isRequired,
  showHreflang: PropTypes.bool.isRequired,
  showLdJSON: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mediaFilter: PropTypes.string.isRequired,
  isUnsafeTag: PropTypes.bool,
  tagExists: PropTypes.bool,
  server: PropTypes.string,
  match: PropTypes.object.isRequired, // eslint-disable-line
  firstMedia: PropTypes.object, // eslint-disable-line
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  messageValues: PropTypes.objectOf(PropTypes.string),
};

PageSearch.defaultProps = {
  server: undefined,
  tagExists: false,
  isUnsafeTag: true,
  messageValues: {},
};

PageSearch.contextTypes = {
  intl: PropTypes.objectOf(PropTypes.any),
};

export default withRouter(PageSearch);
