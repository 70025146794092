import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import MediaMasonry from '../../../containers/MediaMasonry';
import emptyImgGif from '../../../assets/empty-uploaded.gif';
import emptyImgWebp from '../../../assets/empty-uploaded.webp';
import Meta from '../../../containers/Meta';
import { LinearProgress } from '../../Common';
import AutoPlayButton from '../../../containers/AutoPlayButton';
import './index.css';

const PartUploaded = props => (
  <div className="page-user-part-uploaded">
    <Meta id="page_user_uploaded" showHreflang={false} values={{ user: props.username }} />
    {props.showProgress && <LinearProgress {...props} />}

    <div className="page-user-part-uploaded__header">
      <div className="page-user-part-uploaded__title">
        <span className="page-user-part-uploaded__uploaded">
          <FormattedHTMLMessage id="page_user_uploaded.h1" values={{ user: props.username }} />
        </span>
        <span className="page-user-part-uploaded__uploaded-count">
          {props.counters.gifs}
        </span>
      </div>
      <AutoPlayButton />
    </div>

    <MediaMasonry
      save
      forceUpdate
      id={`user/${props.match.params.id}/uploaded`}
      empty={(
        <div style={{ textAlign: 'center' }}>
          <picture>
            <source srcSet={emptyImgWebp} type="image/webp" />
            <source srcSet={emptyImgGif} type="image/gif" />
            <img src={emptyImgGif} alt="empty content" />
          </picture>
          <div className="page-user-part-added__content-empty">
            <FormattedHTMLMessage
              id="page_user.empty_uploaded"
              values={{ user: props.username }}
            />
          </div>
        </div>
      )}
    />
  </div>
);

PartUploaded.propTypes = {
  counters: PropTypes.objectOf(PropTypes.string),
  username: PropTypes.string.isRequired,
  showProgress: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired, // eslint-disable-line
};

PartUploaded.defaultProps = {
  counters: {
    gifs: 0,
  },
};

export default PartUploaded;
