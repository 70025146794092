import * as actions from '../../actions/searchHints';

const initialState = {
  error: null,
  isLoading: false,
  isLoaded: false,
  hints: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_SEARCH_HINTS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        isLoaded: false,
      };
    case actions.FETCH_SEARCH_HINTS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case actions.FETCH_SEARCH_HINTS_SUCCESS:
      return {
        ...state,
        hints: action.hints,
        isLoading: false,
        isLoaded: true,
      };
    default: return state;
  }
};
