import { delay } from 'redux-saga';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as actions from '../../actions/search';
import { getLocale } from '../selectors';
import * as api from '../../utils/api/search';

function* search(action) {
  try {
    yield call(delay, 150);
    const locale = yield select(getLocale);
    const matches = yield call(api.search, action.query, locale);
    yield put(actions.searchSuccess(matches));
  } catch (error) {
    yield put(actions.searchFailure(error.message));
  }
}

export function* watchSearchRequest() {
  yield takeLatest(actions.SEARCH_REQUEST, search);
}
