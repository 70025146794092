import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { useFFmpeg } from '../contexts/ffmpegContext';
import classNames from 'classnames';
import iconUpload from '../../../assets/maker/icon-upload.svg';
import LayoutHeader from './layout/layoutHeader';
import { FormattedMessage } from 'react-intl';

/**
 * Renders dropzone for file uploading and writes uploaded file to ffmpeg FS
 */
const FileUploader = () => {
  const { writeSourceFile, working, isLoaded } = useFFmpeg();
  const onDrop = useCallback(
    ([file]) => {
      writeSourceFile(file);
    },
    [writeSourceFile],
  );

  return (
    <>
      <LayoutHeader />
      <div className="maker-uploader">
        <h1 className="text-shadow">
          <FormattedMessage id="page_maker.title" />
        </h1>
        <Dropzone
          multiple={false}
          accept={['image/gif', 'video/*']}
          onDrop={onDrop}
          className={classNames(
            'maker-uploader__dropzone',
            (working || !isLoaded) && 'animate-pulse',
          )}
        >
          <div className="flex">
            <img src={iconUpload} alt="" />
            <div>
              <h3 className="text-shadow">
                {working ? <FormattedMessage id="page_maker.drop_zone_uploading" /> : <FormattedMessage id="page_maker.drop_zone_title" />}
              </h3>
              {!working && (
                <p>
                  <FormattedMessage id="page_maker.drop_zone_description" />
                </p>
              )}
            </div>
          </div>
        </Dropzone>
      </div>
    </>
  );
};

export default FileUploader;
