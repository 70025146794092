export const FETCH_SMILES_REQUEST = 'FETCH_SMILES_REQUEST';
export const FETCH_SMILES_SUCCESS = 'FETCH_SMILES_SUCCESS';
export const FETCH_SMILES_FAILURE = 'FETCH_SMILES_FAILURE';

export const FETCH_SMILE_REQUEST = 'FETCH_SMILE_REQUEST';
export const FETCH_SMILE_SUCCESS = 'FETCH_SMILE_SUCCESS';
export const FETCH_SMILE_FAILURE = 'FETCH_SMILE_FAILURE';

export const fetchSmilesRequest = () => ({
  type: FETCH_SMILES_REQUEST,
});

export const fetchSmilesSuccess = data => ({
  type: FETCH_SMILES_SUCCESS,
  data,
});

export const fetchSmilesFailure = error => ({
  type: FETCH_SMILES_FAILURE,
  error,
});

export const fetchSmileRequest = slug => ({
  type: FETCH_SMILE_REQUEST,
  slug,
});

export const fetchSmileSuccess = data => ({
  type: FETCH_SMILE_SUCCESS,
  data,
});

export const fetchSmileFailure = () => ({
  type: FETCH_SMILE_FAILURE,
});
