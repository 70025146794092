import { get } from 'lodash';
import { connect } from 'react-redux';
import { tagFromURI } from '../../utils/tagToURI';
import { openMenu } from '../../actions/menu';
import HorizontalMenu from '../../components/HorizontalMenu';
import { openModal } from '../../actions/modal';
import ModalUploadGif from '../UploadGif/Modal';
import AuthModal from '../AuthModal/index';

const getTagFromUrl = () => {
  const matches = typeof window !== 'undefined' && /\/s\/([^/?]+)/.exec(window.location.href);
  return matches ? `${tagFromURI(matches[1])}` : '';
};

const mapStateToProps = state => ({
  isSearchOpen: state.search.isOpen,
  query: state.search.query,
  pathname: get(state, 'router.location.pathname'),
  tagUrl: getTagFromUrl(),
  isAuth: !!state.auth.myId,
});

const mapDispatchToProps = dispatch => ({
  openMenu: () => dispatch(openMenu()),
  onUploadClick: () => dispatch(openModal(ModalUploadGif)),
  openAuthPopup: () => dispatch(openModal(AuthModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HorizontalMenu);
