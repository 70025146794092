import PropTypes from 'prop-types';
import React from 'react';
import TagThumb from '../../../containers/TagThumb';
import { Masonry } from '../../Common';
import './index.css';

class TagThumbList extends React.Component {
  constructor(props) {
    super(props);
    this.handleRenderItem = this.handleRenderItem.bind(this);
    this.handleCellHeight = this.handleCellHeight.bind(this);
  }

  handleCellHeight(index, width) {
    return width * 0.6;
  }

  handleRenderItem(index) {
    const tagId = this.props.tags[index];
    return (<TagThumb category={this.props.category} tag={tagId} />);
  }

  render() {
    return (
      <Masonry
        columnCount={this.props.isMobile ? 1 : (typeof window === 'undefined' ? 3 : 4)}  // eslint-disable-line
        gutter={this.props.gutter}
        offset={150}
        data={this.props.tags}
        render={this.handleRenderItem}
        cellHeight={this.handleCellHeight}
      />
    );
  }
}

TagThumbList.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  gutter: PropTypes.number.isRequired,
  category: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TagThumbList;
