import { connect } from 'react-redux';
import { closeMenu } from '../../actions/menu';
import VerticalMenuMobile from '../../components/VerticalMenuMobile';

const mapStateToProps = state => ({
  categories: Object.values(state.categories.entities),
});

const mapDispatchToProps = {
  closeMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalMenuMobile);
