import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './index.css';

const User = (props) => {
  if (!props.name) return null;

  return (
    <div className="page-media-user" style={props.style}>
      <FormattedMessage id="page_media.author" />
      :&nbsp;
      <Link rel="nofollow" to={`/user/${props.id}`}>{props.name}</Link>
    </div>
  );
};

User.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
};

User.defaultProps = {
  id: '',
  name: '',
  style: {},
};

export default User;
