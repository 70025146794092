import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import { FontIcon } from '../../../Common';
import Item from '../../Item';
import './index.css';

class CarouselDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  handlePrevSlide = () => {
    this.setState(({ currentSlide }) => ({ currentSlide: currentSlide - 1 }));
  }

  handleNextSlide = () => {
    this.setState(({ currentSlide }) => ({ currentSlide: currentSlide + 1 }));
  }

  render() {
    const {
      data, initialSlideWidth, initialSlideHeight, slidesToShow, slideWidth, colors,
    } = this.props;

    return (
      <div className="events-panel__carousel" style={{ height: initialSlideHeight }}>
        <Carousel
          key={`${data.length}_${initialSlideHeight}`}
          withoutControls
          disableKeyboardControls
          heightMode="max"
          edgeEasing="easeLinear"
          initialSlideHeight={initialSlideHeight}
          initialSlideWidth={initialSlideWidth}
          slideWidth={slideWidth}
          slideIndex={this.state.currentSlide}
          slidesToShow={slidesToShow}
          afterSlide={slideIndex => this.setState({ currentSlide: slideIndex })}
        >
          {data.map((item, i) => (
            <article key={item.id} className="events-panel__item" style={{ height: initialSlideHeight }}>
              <Item {...item} borderColor={colors[i % colors.length]} />
            </article>
          ))}
        </Carousel>

        {this.state.currentSlide > 0 && (
          <div className="events-panel__navigation events-panel__navigation-prev" onClick={this.handlePrevSlide}>
            <FontIcon className="events-panel__navigation-icon" name="icon-arrow-left-2" />
          </div>
        )}

        {data.length > slidesToShow && this.state.currentSlide !== data.length - slidesToShow && (
          <div className="events-panel__navigation events-panel__navigation-next" onClick={this.handleNextSlide}>
            <FontIcon className="events-panel__navigation-icon" name="icon-arrow-right-2" />
          </div>
        )}
      </div>
    );
  }
}

CarouselDesktop.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.arrayOf(PropTypes.string),
  slideWidth: PropTypes.number.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  initialSlideWidth: PropTypes.number.isRequired,
  initialSlideHeight: PropTypes.number.isRequired,
};

CarouselDesktop.defaultProps = {
  data: [],
  colors: [],
};

export default CarouselDesktop;
