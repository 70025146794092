import { SET_MESSAGES, SET_LOCALE } from '../../actions/intl';

export const initialState = {
  locale: null,
  locales: process.env.REACT_APP_LOCALES.split(';'),
  messages: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.locale]: action.messages,
        },
      };
    case SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    default:
      return state;
  }
};
