export const FETCH_UPLOAD_SERVERS_REQUEST = 'FETCH_UPLOAD_SERVERS_REQUEST';
export const FETCH_UPLOAD_SERVERS_SUCCESS = 'FETCH_UPLOAD_SERVERS_SUCCESS';
export const FETCH_UPLOAD_SERVERS_FAILURE = 'FETCH_UPLOAD_SERVERS_FAILURE';

export const fetchUploadServersRequest = () => ({
  type: FETCH_UPLOAD_SERVERS_REQUEST,
});

export const fetchUploadServersSuccess = data => ({
  type: FETCH_UPLOAD_SERVERS_SUCCESS,
  data,
});

export const fetchUploadServersFailure = error => ({
  type: FETCH_UPLOAD_SERVERS_FAILURE,
  error,
});
