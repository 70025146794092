import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getMediaSrc } from '../../../utils/url';
import './index.css';

const Item = ({ slug, borderColor, cover, name, description }) => {
  // I was so lazy for extracting the server value...
  const { path } = cover.file;
  const gifSrc = getMediaSrc('https://i.gifer.com', path, 'gif');
  const webpSrc = getMediaSrc('https://i.gifer.com', path, 'webp');

  return (
    <Link to={`/events/${slug}`} className="events-panel-item">
      <div className="events-panel-item__image-container" style={{ borderColor }}>
        <picture>
          <source srcSet={webpSrc} type="image/webp" />
          <source srcSet={gifSrc} type="image/gif" />
          <img className="page-event__photostatus-image" src={gifSrc} alt="" />
        </picture>
      </div>

      <div className="events-panel-item__info">
        <div className="events-panel-item__title">
          {name}
        </div>

        <div className="events-panel-item__description">
          {description}
        </div>
      </div>
    </Link>
  );
};

Item.propTypes = {
  slug: PropTypes.node,
  borderColor: PropTypes.string,
  cover: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  description: PropTypes.string,
};

Item.defaultProps = {
  slug: 0,
  borderColor: 'red',
  cover: { file: {} },
  name: undefined,
  description: undefined,
};

export default Item;
