import React from 'react';
import Sticky from 'react-stickynode';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontIcon } from '../Common';
import Categories from '../../containers/VerticalMenuDesktop/Categories';
import logoCube from '../../assets/logo-cube.svg';
import './index.css';

const Menu = ({ intl }) => (
  <Sticky>
    <div className="vertical-menu">
      <Link className="vertical-menu__logo" to="/">
        <img alt={intl.formatMessage({ id: 'logo_alt' })} src={logoCube} className="vertical-menu__logo-image" />
        <FontIcon size="27px" color="#fff" name="icon-logo-text" />
      </Link>
      <nav className="vertical-menu__categories">
        <Categories />
      </nav>

      <div className="vertical-menu__links">
        <Link className="vertical-menu__links-item" to="/p/privacy">
          <FormattedMessage id="menu.privacy-policy" />
        </Link>
        <Link className="vertical-menu__links-item" to="/p/tos">
          <FormattedMessage id="menu.terms-of-service" />
        </Link>
      </div>
    </div>
  </Sticky>
);

Menu.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Menu);
