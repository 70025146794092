export const INIT_AUTH = 'INIT_AUTH';
export const INIT_AUTH_SUCCESS = 'INIT_AUTH_SUCCESS';
export const SOCIAL_LOGIN_REQUEST = 'SOCIAL_LOGIN_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_FAILURE = 'CHECK_EMAIL_FAILURE';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

export const initAuth = () => ({ type: INIT_AUTH });
export const initAuthSuccess = () => ({ type: INIT_AUTH_SUCCESS });

export const socialLoginRequest = provider => ({
  provider,
  type: SOCIAL_LOGIN_REQUEST,
});

export const loginRequest = data => ({
  data,
  type: LOGIN_REQUEST,
});

export const loginFailure = error => ({
  error,
  type: LOGIN_FAILURE,
});

export const loginSuccess = (id, entities) => ({
  id,
  entities,
  type: LOGIN_SUCCESS,
});

export const signupRequest = data => ({
  data,
  type: SIGNUP_REQUEST,
});

export const signupFailure = error => ({
  error,
  type: SIGNUP_FAILURE,
});

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });

export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });

export const logoutFailure = error => ({
  error,
  type: LOGOUT_FAILURE,
});

export const checkEmailRequest = email => ({
  email,
  type: CHECK_EMAIL_REQUEST,
});

export const checkEmailFailure = (email, error) => ({
  email,
  error,
  type: CHECK_EMAIL_FAILURE,
});

export const checkEmailSuccess = (email, exists) => ({
  email,
  exists,
  type: CHECK_EMAIL_SUCCESS,
});

export const clearAuthError = () => ({
  type: CLEAR_AUTH_ERROR,
});
