import { convertSecondsToTime } from '../../helpers/time';
import { Rnd } from 'react-rnd';
import React, { useEffect, useMemo, useState } from 'react';
import { useElementSize } from 'usehooks-ts';

const MAX_DURATION_IN_SECONDS = 10;

const EditorControls = ({ duration, onChangePosition, isLoading }) => {
  const [containerRef, { width: containerWidth }] = useElementSize();
  const [handlerState, setHandlerState] = useState({
    width: 100,
    height: 18,
    x: 0,
    y: 0,
  });

  const maxWidth = useMemo(
    () => (MAX_DURATION_IN_SECONDS / duration) * containerWidth,
    [containerWidth, duration],
  );

  const startPosition = useMemo(
    () => (handlerState.x / containerWidth) * duration,
    [containerWidth, duration, handlerState.x],
  );

  const endPosition = useMemo(
    () => ((handlerState.x + handlerState.width) / containerWidth) * duration,
    [containerWidth, duration, handlerState.width, handlerState.x],
  );

  useEffect(() => {
    onChangePosition(startPosition, endPosition);
  }, [endPosition, onChangePosition, startPosition]);

  const handleDrag = (_, data) => {
    setHandlerState((value) => ({ ...value, x: data.x, y: data.y }));
  };

  const handleResize = (_, __, ref, ___, position) => {
    setHandlerState({
      ...position,
      width: ref.offsetWidth,
      height: ref.offsetHeight,
    });
  };

  return (
    <div
      ref={containerRef}
      className="maker-editor__controls"
    >
      <Rnd
        bounds="parent"
        dragAxis="x"
        disableDragging={isLoading}
        maxWidth={maxWidth}
        enableResizing={{
          top: false,
          right: !isLoading,
          bottom: false,
          left: !isLoading,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        size={{ width: handlerState.width, height: handlerState.height }}
        position={{ x: handlerState.x, y: handlerState.y }}
        onResize={handleResize}
        onDrag={handleDrag}
      >
        <div className="maker-editor__controls__handler">
          <span className="maker-editor__controls__t1">
            {convertSecondsToTime(startPosition)}
          </span>
          <span className="maker-editor__controls__t2">
            {convertSecondsToTime(endPosition)}
          </span>
        </div>
      </Rnd>
    </div>
  );
};

export default EditorControls;
