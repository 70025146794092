import { CHANGE_ENV_VARIABLE } from '../../actions/env';

const initialState = {
  isMobile: false,
  width: 0,
  height: 0,
  url: '',
  clientType: 'browser',
  speed: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ENV_VARIABLE:
      return {
        ...state,
        [action.name]: action.value,
      };
    default: return state;
  }
};
