import React from 'react';
import PropTypes from 'prop-types';
import SmileItem from './SmileItem';
import smiles from '../../utils/smiles';
import './index.css';

const SmilesPanel = ({ locale, data, isDimmed, activeSlug, isLoaded }) => (
  <ul className="smiles-panel">
    {isLoaded && data.filter(i => i.locales.includes(locale)).map(item => (
      <li className="smiles-panel__item" key={item.id}>
        <SmileItem
          isActive={activeSlug === item.slug}
          isDimmed={isDimmed}
          src={smiles[item.cover]}
          {...item}
        />
      </li>
    ))}
    {!isLoaded && [...Array(8)].map((el, index) => (
      <li className="smiles-panel__item" key={index} /> //eslint-disable-line
    ))}
  </ul>
);

SmilesPanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  activeSlug: PropTypes.string,
  isDimmed: PropTypes.bool,
  locale: PropTypes.string,
  isLoaded: PropTypes.bool,
};

SmilesPanel.defaultProps = {
  data: [],
  isDimmed: false,
  activeSlug: undefined,
  locale: 'en',
  isLoaded: false,
};

export default SmilesPanel;
