import { connect } from 'react-redux';
import TagThumb from '../../components/Tags/TagThumb';

const mapStateToProps = (state, props) => {
  const tag = state.fullTags[props.tag] || {};
  return {
    id: tag.id,
    cover: tag.cover,
    isMobile: state.env.isMobile,
  };
};

export default connect(mapStateToProps)(TagThumb);
