import React from 'react';
import DeviceLayout from '../../containers/DeviceLayout';
import Mobile from '../../containers/Layout/Layouts/Mobile';
import Desktop from './Layouts/Desktop';
import './reset.css';
import '../../assets/flags/style.css';
import './index.css';

const Layout = props => (
  <DeviceLayout
    mobile={<Mobile {...props} />}
    desktop={<Desktop {...props} />}
  />
);

export default Layout;
