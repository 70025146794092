import * as actions from '../../actions/categories';

export const initialState = {
  ids: [],
  error: null,
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null,
      };
    case actions.FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isLoaded: false,
      };
    case actions.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        ids: action.ids,
      };
    default: return state;
  }
};
