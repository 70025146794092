import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Button, FontIcon } from '../../Common';
import ModalUploadGif from '../../../containers/UploadGif/Modal';
import logoCube from '../../../assets/logo-cube.svg';
import './Mobile.css';

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = { showTooltip: false };
    this.handleClickUpload = this.handleClickUpload.bind(this);
    this.handleClickOverlay = this.handleClickOverlay.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) { // eslint-disable-line
      this.setState({ showTooltip: false });
    }
  }

  handleClickUpload(event) {
    event.stopPropagation();
    if (this.state.showTooltip) {
      this.closeTooltip();
    } else {
      this.openTooltip();
    }
  }

  handleClickOverlay() {
    this.closeTooltip();
  }

  openTooltip() {
    this.setState({ showTooltip: true });
  }

  closeTooltip() {
    this.setState({ showTooltip: false });
  }

  render() {
    return (
      <header className="hor-menu mobile">
        <div className="controls">
          <Button
            className="menu-button"
            type="transparent"
            icon={<FontIcon size="27px" name="icon-menu" color="white" />}
            onClick={this.props.openMenu}
          />

          <Link className="logo" to="/">
            <img src={logoCube} alt={this.props.intl.formatMessage({ id: 'logo_alt' })} className="hor-menu__logo" />
            <FontIcon size="21px" name="icon-logo-text" color="white" />
          </Link>

          <div className="hor-menu__upload">
            <Button
              className={`hor-menu__upload-button ${this.state.showTooltip ? 'hor-menu__upload-button_active' : ''}`}
              type="transparent"
              icon={<FontIcon size="27px" name="icon-plus" color="white" />}
              onClick={this.handleClickUpload}
            />

            {this.state.showTooltip && (
              <div className="hor-menu__upload-tooltip" ref={el => (this.tooltip = el)}>
                <ModalUploadGif showTitle />
              </div>
            )}

            {this.state.showTooltip && (
              <div
                className="hor-menu__overlay"
                onTouchMove={this.handleClickOverlay}
                onClick={this.handleClickOverlay}
              />
            )}
          </div>
        </div>
      </header>
    );
  }
}

Mobile.propTypes = {
  openMenu: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  // onUploadClick: PropTypes.func.isRequired,
};

export default injectIntl(withRouter(Mobile));
