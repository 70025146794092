import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button } from '../../Common';
import BackButton from '../BackButton';
import Input from '../Input';

class PartLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { password: '' };
    this.handleChangePassword = this.handleChange.bind(this, 'password');
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(key, event) {
    this.setState({ [key]: event.target.value });
  }

  handleSubmit() {
    this.props.onSubmit({ email: this.props.email, password: this.state.password });
  }

  render() {
    return (
      <div className="auth-part-login content">
        <div className="sub-header">
          <BackButton onClick={this.props.onClickBack} />
          <FormattedMessage id="auth.greeting_login" />
        </div>
        <div className="user-email">{this.props.email}</div>
        <Input
          autoFocus
          type="password"
          error={this.props.error}
          style={{ marginTop: 10 }}
          value={this.state.password}
          onChange={this.handleChangePassword}
          onSubmit={this.handleSubmit}
          placeholder={this.props.intl.formatMessage({ id: 'auth.placeholder.password' })}
        />
        {this.props.error &&
          <div className="auth-error">
            <FormattedMessage id="auth.wrong_password" />
          </div>
        }
        <Button
          block
          type="gradient"
          onClick={this.handleSubmit}
          style={{ marginTop: 10 }}
        >
          <FormattedMessage id="auth.loginButton" />
        </Button>
      </div>
    );
  }
}

PartLogin.propTypes = {
  error: PropTypes.string,
  intl: intlShape.isRequired,
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
};

export default injectIntl(PartLogin);
