import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Mobile from './Layouts/Mobile';
import Desktop from './Layouts/Desktop';
import Meta from '../../containers/PageMedia/Meta';
import DeviceLayout from '../../containers/DeviceLayout';
import AnalyticsPageView from '../AnalyticsPageView';

const PageMedia = props => (
  <Fragment>
    {props.isLoaded && <AnalyticsPageView dep={props.id} />}
    {props.isLoaded && (!props.tags || props.tags.length === 0) && (
      <Helmet>
        <meta name="robots" content="noindex, follow" />
      </Helmet>
    )}


    <Meta
      id={props.textKey}
      values={props.textValues}
      mediaId={props.id}
      url={`/${props.id}`}
    />

    <DeviceLayout
      mobile={<Mobile key={props.id} {...props} />}
      desktop={<Desktop {...props} />}
    />
  </Fragment>
);

PageMedia.propTypes = {
  id: PropTypes.string,
  textKey: PropTypes.string,
  textValues: PropTypes.object, // eslint-disable-line
};

PageMedia.defaultProps = {
  id: undefined,
  textKey: undefined,
};

export default PageMedia;
