import React from 'react';
import PropTypes from 'prop-types';

const Stub = ({ itemsCount, itemHeight, columnCount, gutter }) => (
  <div
    className="media-masonry-stub"
    style={{
      display: 'grid',
      gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
      gridGap: gutter,
    }}
  >
    {[...Array(itemsCount)].map((el, index) => (
      <div
        className="media-masonry-stub__item"
        style={{
          height: itemHeight,
          background: '#161616',
        }}
        key={index} //eslint-disable-line
      />
    ))}
  </div>
);


Stub.propTypes = {
  itemsCount: PropTypes.number,
  itemHeight: PropTypes.number,
  columnCount: PropTypes.number,
  gutter: PropTypes.number,
};

Stub.defaultProps = {
  itemsCount: 8,
  itemHeight: 250,
  columnCount: 2,
  gutter: 0,
};

export default Stub;
