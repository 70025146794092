import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageEmbed from '../../components/PageEmbed';
import { fetchMediaRequest } from '../../actions/media';

class PageEmbedContainer extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchMediaRequest(this.props.id);
  }

  render() {
    return <PageEmbed {...this.props} />;
  }
}

PageEmbedContainer.propTypes = {
  id: PropTypes.string.isRequired,
  fetchMediaRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;
  const { locale } = state.intl;
  const { isMobile } = state.env;
  const media = state.media.entities[id];
  const server = media && media.file && state.uploadServers.data[media.file.server];
  return {
    id,
    server,
    locale,
    isMobile,
    ...media,
  };
};

const mapDispatchToProps = {
  fetchMediaRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEmbedContainer);
