import { call, put, takeEvery } from 'redux-saga/effects';
import { addLocaleData } from 'react-intl';
import ru from 'react-intl/locale-data/ru';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
import fr from 'react-intl/locale-data/fr';
import de from 'react-intl/locale-data/de';
import * as actions from '../../actions/intl';
import { getMessages } from '../../utils/api/intl';
import getCurrentLocale from '../../utils/getCurrentLocale';

const reactIntlLocaleData = [ru, en, es, pt, fr, de];

function* initIntl() {
  const locale = yield call(getCurrentLocale);
  yield reactIntlLocaleData.map(localeData => call(addLocaleData, localeData));
  yield put(actions.setLocale(locale));
  yield put(actions.startFetchMessages(locale));
}

function* fetchMessages(action) {
  try {
    const messages = yield call(getMessages, action.locale);
    yield put(actions.setMessages(action.locale, messages));
  } catch (error) {
    // console.log(error);
  }
}

export function* watchFetchMessagesRequest() {
  yield takeEvery(actions.START_FETCH_MESSAGES, fetchMessages);
}

export function* watchInitIntl() {
  yield takeEvery(actions.INIT_INTL, initIntl);
}
