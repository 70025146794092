import PropTypes from 'prop-types';
import React from 'react';
import './index.css';

const TagThumbTitle = props => (
  <div className={`tag-thumb-title ${props.type} ${props.isMobile ? 'mobile' : 'desktop'}`}>
    <h2 className="title">
      <span className="hash" />
      {props.children}
    </h2>
  </div>
);

TagThumbTitle.propTypes = {
  type: PropTypes.oneOf(['default', 'purple']),
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

TagThumbTitle.defaultProps = {
  type: 'default',
};

export default TagThumbTitle;
