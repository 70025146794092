import React from 'react';
import Tags from '../PageMedia/Tags';
import AutoPlayButton from '../../containers/AutoPlayButton';
import './index.css';

const SmartTags = props => (
  <div className="smart-tags">
    <div className="smart-tags__container">
      <div className="smart-tags__list">
        <Tags showMore={false} {...props} />
      </div>
      <div className="smart-tags__autoplay">
        <AutoPlayButton />
      </div>
    </div>
  </div>
);

export default SmartTags;
