import throttle from 'lodash/throttle';
import React, { Component } from 'react';
import SearchDropdown from '../../containers/SearchDropdown';

class MobileSearch extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = throttle(this.handleScroll.bind(this), 200);
    this.state = { showMenu: false };
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const top = window.scrollY;
    const showMenu = top > 50;
    if (this.state.showMenu === showMenu) return;
    this.setState({ showMenu });
  }

  render() {
    return (
      <SearchDropdown
        isMobile
        showOverlay
        showMenuButton={this.state.showMenu}
      />
    );
  }
}

export default MobileSearch;
