import { connect } from 'react-redux';
import { likeMediaRequest } from '../../actions/likes';
import { openModal } from '../../actions/modal';
import { showAlert } from '../../actions/alertMessage';
import Controls from '../../components/PageMedia/Controls/index';
import AuthModal from '../AuthModal/index';
import EmbedModal from '../../components/EmbedModal';

const mapStateToProps = (state, props) => {
  const media = state.media.entities[props.id];
  const server =
    media && media.file && state.uploadServers.data[media.file.server];
  const mediaURL = server ? `${server}/${props.id}.gif` : '';
  const downloadURL = server
    ? `${server}/embedded/download/${props.id}.gif`
    : '';

  return {
    mediaURL,
    downloadURL,
    isMobile: state.env.isMobile,
    isAuth: !!state.auth.myId,
    liked: state.media.entities[props.id].liked,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  likeMedia: (...args) => dispatch(likeMediaRequest(...args)),
  openAuthPopup: () => dispatch(openModal(AuthModal)),
  showAlert: (...args) => dispatch(showAlert(args)),
  onClickEmbed: () => {
    dispatch(openModal(EmbedModal, { id: props.id }));

    if (typeof props.onClickEmbed === 'function') {
      props.onClickEmbed();
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Controls);
