import React from 'react';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash';
import { Helmet } from 'react-helmet';
import { FormattedHTMLMessage } from 'react-intl';
import Meta from '../../containers/Meta';
import SmilesPanel from '../../containers/SmilesPanel';
import MasonryObserver from '../MasonryObserver';
import SmartTags from '../../containers/SmartTags';
import { getGalleryLdJson } from '../../utils/ldJson';
import './index.css';
import AnalyticsPageView from '../AnalyticsPageView';

const PageSmile = (
  { name, slug, listId, statQuery, media, server, isMobile, locale },
  { intl },
) => {
  const mp4Src = `${server}/${media.id}.mp4`;
  const url = `${process.env.REACT_APP_DOMAIN}/${locale}/emoji/${slug}`;
  const messageValues = {
    name,
    Name: upperFirst(name),
  };

  let ldJSON;
  if (typeof window === 'undefined') {
    ldJSON = getGalleryLdJson({
      url,
      intl,
      media,
      server,
      messageValues,
      seedKey: slug,
      intlPrefix: 'page_smile.article_',
    });
  }

  return (
    <>
      <AnalyticsPageView dep={slug} />
      <Helmet>
        {ldJSON && (
          <script type="application/ld+json">
            {JSON.stringify(ldJSON)}
          </script>
        )}
      </Helmet>

      <Meta
        id="page_smile"
        url={`/emoji/${slug}`}
        values={messageValues}
        meta={[
          {
            property: 'og:image',
            content: mp4Src,
          },
        ]}
      />

      <div className="page-smile">
        <div className="page-smile__panel">
          <SmilesPanel isDimmed activeSlug={slug} />
        </div>

        <h1 className="page-header page-smile__header">
          <FormattedHTMLMessage
            id="page_smile.h1"
            values={{ name }}
          />
        </h1>

        <div className="page-smile__tags-container">
          <SmartTags showMore={false} listId={listId} />
        </div>

        {listId && (
          <MasonryObserver
            key={listId}
            id={listId}
            save
            locale={locale}
            statPayload={{ tag: statQuery }}
            query={statQuery}
            contextMenuEvent={isMobile
              ? 'mobile_right_button_gif_smiles'
              : 'desktop_right_button_gif_smiles'}
          />
        )}
      </div>
    </>
  );
};

PageSmile.propTypes = {
  name: PropTypes.string,
  slug: PropTypes.string,
  listId: PropTypes.string,
  statQuery: PropTypes.string,
  isMobile: PropTypes.bool,
  locale: PropTypes.string,
  media: PropTypes.objectOf(PropTypes.any),
};

PageSmile.defaultProps = {
  name: undefined,
  slug: undefined,
  listId: undefined,
  statQuery: undefined,
  isMobile: false,
  locale: 'en',
  media: {},
};

PageSmile.contextTypes = {
  intl: PropTypes.objectOf(PropTypes.any),
};

export default PageSmile;
