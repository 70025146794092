let ws;

export const init = () => {
  if (typeof window === 'undefined') return;

  try {
    ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
    ws.addEventListener('error', error => console.log('ws error', error));
    ws.addEventListener('open', () => console.log('ws opened'));
    ws.addEventListener('close', () => (ws = undefined));
  } catch (error) {
    console.log('ws error', error);
  }
};

export const send = message => {
  if (!ws) {
    console.error('ws was not initiated');
    return;
  }

  ws.send(message);
};
