import { connect } from 'react-redux';
import SearchResults from '../../components/SearchResults';

const mapStateToProps = state => ({
  query: state.search.query,
  isLoading: state.search.isLoading,
  matches: state.search.matches.slice(0, 8),
});

export default connect(mapStateToProps)(SearchResults);
