import PropTypes from 'prop-types';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PartLogin from '../../containers/AuthModal/PartLogin';
import PartSignup from '../../containers/AuthModal/PartSignup';
import PartHome from '../../containers/AuthModal/PartHome';
import PartSocial from '../../containers/AuthModal/PartSocial';
import Modal from '../../containers/Modal';
import './index.css';

class AuthModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      part: 'home',
      email: '',
      showEmailInput: false,
    };
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
  }

  handleSubmitEmail(email, exists) {
    this.setState({ email, part: exists ? 'login' : 'signup' });
  }

  render() {
    if (this.props.isAuth) return null;

    let content;
    switch (this.state.part) {
      case 'home':
        content = (
          <PartHome
            key="home"
            email={this.state.email}
            showInput={this.state.showEmailInput}
            toggleInput={() => this.setState({ showEmailInput: !this.state.showEmailInput })}
            onSubmitEmail={this.handleSubmitEmail}
            showOtherMethods={() => this.setState({ part: 'social' })}
          />
        );
        break;

      case 'login':
        content = (
          <PartLogin
            key="login"
            email={this.state.email}
            onClickBack={() => this.setState({ part: 'home' })}
          />
        );
        break;

      case 'signup':
        content = (
          <PartSignup
            key="signup"
            email={this.state.email}
            onClickBack={() => this.setState({ part: 'home' })}
          />
        );
        break;

      case 'social':
        content = (
          <PartSocial
            key="social"
            onClickBack={() => this.setState({ part: 'home' })}
            onClickEmail={() => this.setState({ part: 'home', showEmailInput: true })}
          />
        );
        break;

      default: content = '';
    }

    return (
      <Modal className={`page-auth ${this.props.isMobile ? 'mobile' : 'desktop'}`}>
        <ReactCSSTransitionGroup
          component="ul"
          className="page-auth__animation-container"
          transitionName={this.state.part !== 'home' ? 'fade-wait' : 'fade-wait-reverse'}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {content}
        </ReactCSSTransitionGroup>
      </Modal>
    );
  }
}

AuthModal.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default AuthModal;
