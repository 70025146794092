import { combineReducers } from 'redux';
import entities from './entities';
import tags from './tags';
import fetch from './fetch';

export default combineReducers({
  entities,
  fetch,
  tags,
});
