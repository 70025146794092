import en from '../assets/share/social-en.png';
import ru from '../assets/share/social-ru.png';
import ua from '../assets/share/social-ua.png';
import es from '../assets/share/social-es.png';
import pt from '../assets/share/social-pt.png';
import fr from '../assets/share/social-fr.png';
import de from '../assets/share/social-de.png';
import ja from '../assets/share/social-ja.png';

const shareSocialImages = { en, ru, ua, es, pt, fr, de, ja };

export default (locale) => shareSocialImages[locale];
