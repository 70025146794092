export const START_UPLOAD = 'START_UPLOAD';
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

export const startUpload = files => ({
  files,
  type: START_UPLOAD,
});

export const fileUploadProgress = (id, progress) => ({
  id,
  progress,
  type: FILE_UPLOAD_PROGRESS,
});

export const fileUploadSuccess = (id, data) => ({
  id,
  data,
  type: FILE_UPLOAD_SUCCESS,
});

export const fileUploadRequest = (id, file) => ({
  id,
  file,
  type: FILE_UPLOAD_REQUEST,
});

export const fileUploadFailure = (id, error) => ({
  id,
  error,
  type: FILE_UPLOAD_FAILURE,
});
