import { takeEvery, call, put } from 'redux-saga/effects';
import * as actions from '../../actions/auth';
import { showAlert } from '../../actions/alertMessage';
import { closeModal } from '../../actions/modal';
import * as api from '../../utils/api/auth';

function* login(action) {
  try {
    const { result, entities } = yield call(api.login, action.data);
    yield put(actions.loginSuccess(result, entities));
    yield put(showAlert('alert.auth_success', 'success', { user: entities.users[result].username }));
    yield put(closeModal());
  } catch (error) {
    yield put(showAlert('alert.some_error', 'error'));
    yield put(actions.loginFailure(error || 'Bad request'));
  }
}

function* signup(action) {
  try {
    const { result, entities } = yield call(api.signup, action.data);
    yield put(actions.loginSuccess(result, entities));
    yield put(closeModal());
  } catch (error) {
    yield put(showAlert('alert.some_error', 'error'));
    yield put(actions.signupFailure(error));
  }
}

function* initAuth() {
  try {
    const { result, entities } = yield call(api.fetchMe);
    yield put(actions.loginSuccess(result, entities));
  } catch (error) {
    // console.log(error);
  } finally {
    yield put(actions.initAuthSuccess());
  }
}

function* logout() {
  try {
    yield call(api.logout);
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield put(actions.logoutFailure(error));
  }
}

function* socialLogin({ provider }) {
  try {
    const { entities, result } = yield call(api.socialLogin, provider);
    yield put(actions.loginSuccess(result, entities));
    yield put(showAlert('alert.auth_success', 'success', { user: entities.users[result].username }));
    yield put(closeModal());
  } catch (error) {
    yield put(showAlert('alert.some_error', 'error'));
    yield put(actions.loginFailure(error));
  }
}

function* checkEmail({ email }) {
  try {
    const result = yield call(api.checkEmail, email);
    yield put(actions.checkEmailSuccess(email, result));
  } catch (error) {
    yield put(actions.checkEmailFailure(email, error));
  }
}

export function* watchSignupRequest() {
  yield takeEvery(actions.SIGNUP_REQUEST, signup);
}

export function* watchLoginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, login);
}

export function* watchInitAuth() {
  yield takeEvery(actions.INIT_AUTH, initAuth);
}

export function* watchLogoutRequest() {
  yield takeEvery(actions.LOGOUT_REQUEST, logout);
}

export function* watchSocialLoginRequest() {
  yield takeEvery(actions.SOCIAL_LOGIN_REQUEST, socialLogin);
}

export function* watchCheckEmailRequest() {
  yield takeEvery(actions.CHECK_EMAIL_REQUEST, checkEmail);
}
