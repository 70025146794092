import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect } from 'react-router-dom';
import PageHome from '../../containers/PageHome';
import PageMedia from '../../containers/PageMedia';
import PageCategory from '../../containers/PageCategory';
import PageSearch from '../../containers/PageSearch';
import PageTag from '../../containers/PageTag';
import PageUser from '../../containers/PageUser';
import PageEvent from '../../containers/PageEvent';
import PageSmile from '../../containers/PageSmile';
import PageEmbed from '../../containers/PageEmbed';
import PagePrivacy from '../PagePrivacy';
import PageTOS from '../PageTOS';
import { tagFromURI, tagToURI } from '../../utils/tagToURI';
import Layout from '../Layout';
import PageGifMaker from '../PageGifMaker';

/**
 * Check for extra spaces & dash in tag and page number before render tag/search page
 * @param url
 * @param Component
 * @returns {function}
 */
const checkTagParamsInUrl = (url, Component) => (routeProps) => {
  const id = decodeURIComponent(routeProps.match.params.id);
  const clearId = tagToURI(tagFromURI(routeProps.match.params.id), false);

  if (id !== clearId) {
    return <Redirect to={`${url}/${tagToURI(clearId)}`} />;
  }

  if (/p=(\d+)/.test(routeProps.location.search)) {
    return <Redirect to={`${url}/${routeProps.match.params.id}`} />;
  }

  return <Component {...routeProps} />;
};

const RouteWithLayout = ({ isMobile, ...routeParams }) => (
  <Layout isMobile={isMobile}>
    <Route {...routeParams} />
  </Layout>
);

const App = props => {
  const correctedLocale = props.locale === 'ua' ? 'uk' : props.locale;

  return (
    <>
      <Helmet htmlAttributes={{ lang: props.locale }} />
      <IntlProvider {...props} locale={correctedLocale}>
        <Switch>
          <Route exact path="/embed/:id" component={PageEmbed} />
          <Route exact path="/maker" component={PageGifMaker} />
          <RouteWithLayout exact path="/" component={PageHome} />
          <RouteWithLayout exact path="/events/:id" component={PageEvent} />
          <RouteWithLayout exact path="/emoji/:slug" component={PageSmile} />
          <RouteWithLayout exact path="/category/:id" component={PageCategory} />
          <RouteWithLayout
            exact
            path="/search/:id"
            render={routeProps => <Redirect to={`/gifs/${routeProps.match.params.id}`} />}
          />
          <RouteWithLayout
            exact
            path="/s/:id"
            render={checkTagParamsInUrl('/s', PageSearch)}
          />
          <RouteWithLayout
            exact
            path="/gifs/:id"
            render={checkTagParamsInUrl('/gifs', PageTag)}
          />
          <RouteWithLayout path="/user/:id" component={PageUser} />
          <RouteWithLayout exact path="/p/privacy" component={PagePrivacy} />
          <RouteWithLayout exact path="/p/tos" component={PageTOS} />
          <RouteWithLayout exact path="/:id" component={PageMedia} />
        </Switch>
      </IntlProvider>
    </>
  );
};

App.propTypes = {
  isMobile: PropTypes.bool,
  locale: PropTypes.string,
};

App.defaultProps = {
  isMobile: false,
  locale: PropTypes.string,
};

export default App;
