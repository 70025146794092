import PropTypes from 'prop-types';
import React from 'react';
import Matches from '../Matches';
import './Desktop.css';

class Desktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: -1 };
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const changedIndex = this.state.active !== nextState.active;
    const wasLoaded = this.props.isLoading === true && nextProps.isLoading === false;
    return changedIndex || wasLoaded;
  }

  componentDidUpdate(prevProps) {
    const wasLoaded = prevProps.isLoading === true && this.props.isLoading === false;
    if (wasLoaded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ active: -1 });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown(event) {
    let { active } = this.state;
    active += event.key === 'ArrowDown' ? 1 : 0;
    active -= event.key === 'ArrowUp' ? 1 : 0;

    if (active === -2) {
      active = this.props.matches.length - 1;
    }

    if (active === this.props.matches.length) {
      active = -1;
    }

    if (this.state.active !== active) {
      event.stopPropagation();
      event.preventDefault();
      this.setState({ active });
      this.props.onKeyboardSelect(active, this.props.matches[active]);
    }
  }

  render() {
    return (
      <div className="search-results desktop">
        <div className="search-results-panel" ref={el => (this.el = el)}>
          <Matches
            activeItem={this.state.active}
            matches={this.props.matches}
            highlight={this.props.query}
          />
        </div>
      </div>
    );
  }
}

Desktop.propTypes = {
  query: PropTypes.string,
  matches: PropTypes.arrayOf(PropTypes.string).isRequired,
  onKeyboardSelect: PropTypes.func,
  isLoading: PropTypes.bool,
};

Desktop.defaultProps = {
  onKeyboardSelect: () => { },
  query: '',
  isLoading: false,
};

export default Desktop;
