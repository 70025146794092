import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

// todo: apply the list throughout the project.
export const List = props => (
  <div className="common-list" {...props} />
);

List.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Item = ({ icon, children, ...rest }) => {
  const Component = rest.href ? 'a' : 'div';

  return (
    <Component className="common-list__item" {...rest}>
      {icon && (
        <div className="common-list__item-icon">
          {icon}
        </div>
      )}
      <div className="common-list__item-children">
        {children}
      </div>
    </Component>
  );
};

Item.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

Item.defaultProps = {
  icon: undefined,
};

export default List;
