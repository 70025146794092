import { takeEvery, call, put, select } from 'redux-saga/effects';
import uniqueId from 'lodash/uniqueId';
import * as actions from '../../actions/upload';
import { createMediaRequest } from '../../actions/media';
import * as api from '../../utils/api/upload';
import * as selectors from '../selectors';

function* startUploadFiles(action) {
  yield action.files.map((file) => {
    // generate id for each file
    const id = uniqueId('upload_file');
    return put(actions.fileUploadRequest(id, file));
  });
}

function* uploadFile(action) {
  try {
    const uploadServers = yield select(selectors.getUploadServers);
    const uploadFunc = typeof action.file === 'string' ? api.uploadByUrl : api.uploadFile;
    const result = yield call(uploadFunc, action.id, action.file, uploadServers);
    yield put(actions.fileUploadSuccess(action.id, result));
    yield put(createMediaRequest({
      width: result.width,
      height: result.height,
      file: result,
    }));
  } catch (error) {
    yield put(actions.fileUploadFailure(action.id, error.message));
  }
}

export function* watchFileUploadRequest() {
  yield takeEvery(actions.FILE_UPLOAD_REQUEST, uploadFile);
}

export function* watchStartUpload() {
  yield takeEvery(actions.START_UPLOAD, startUploadFiles);
}
