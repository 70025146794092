import PropTypes from 'prop-types';
import React from 'react';
import { tagToURI } from '../../../utils/tagToURI';
import Item from '../Item';

const Matches = ({ matches, highlight, activeItem }) =>
  <div>
    {matches.map((match, i) =>
      <Item
        href={`/gifs/${tagToURI(match)}`}
        key={match}
        title={match}
        highlight={highlight}
        isActive={activeItem === i}
      />
    )}
  </div>;

Matches.propTypes = {
  matches: PropTypes.arrayOf(PropTypes.string),
  highlight: PropTypes.string,
  activeItem: PropTypes.number,
};

export default Matches;
