import { CREATE_MEDIA_SUCCESS } from '../../actions/media';

export default (state = {}, action) => {
  if (action.entities && action.entities.fullUsers) {
    // eslint-disable-next-line
    state = {
      ...state,
      ...action.entities.fullUsers,
    };
  }

  switch (action.type) {
    case CREATE_MEDIA_SUCCESS: {
      const { creator } = action.entities.media[action.id];
      if (!state[creator] || !state[creator].counters) return state;

      return {
        ...state,
        [creator]: {
          ...state[creator],
          counters: {
            ...state[creator].counters,
            gifs: state[creator].counters.gifs + 1,
          },
        },
      };
    }
    default: return state;
  }
};
