import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFFmpeg } from '../../contexts/ffmpegContext';
import { WorkerEvents } from '../../helpers/ffmpegEvents';
import EditorControls from './controls';
import LayoutHeader from '../layout/layoutHeader';
import { startUpload } from '../../../../actions/upload';
import { connect } from 'react-redux';
import { LinearProgress } from '../../../Common';

const Editor = ({ onClickBack, uploadFile }) => {
  const { readSourceFile, readDestinationFile, cutSourceFile, working, emitter } = useFFmpeg();
  const sourceRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [position, setPosition] = useState({ start: 0, end: 0 });
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => readSourceFile(), [readSourceFile]);

  // Display loaded file
  useEffect(() => {
    const readFileDoneHandler = ({ file }) => {
      if (file && sourceRef.current) {
        sourceRef.current.src = URL.createObjectURL(file);
      }
    };

    const progressHandler = ({ value }) => {
      setProgress(value);
    };

    emitter.once(WorkerEvents.READ_SOURCE_FILE_DONE, readFileDoneHandler);
    emitter.on(WorkerEvents.PROGRESS, progressHandler);
    return () => {
      emitter.removeListener(WorkerEvents.WRITE_SOURCE_FILE_DONE, readFileDoneHandler);
      emitter.removeListener(WorkerEvents.PROGRESS, progressHandler);
    };
  }, [emitter]);

  // Open edited file in new tab
  useEffect(() => {
    const handler = ({ file }) => {
      if (file) {
        uploadFile(file);
      }
    };
    emitter.once(WorkerEvents.READ_DEST_FILE_DONE, handler);
    return () => {
      emitter.removeListener(WorkerEvents.READ_DEST_FILE_DONE, handler);
    };
  }, [emitter]);

  // Initiate reading destination file after cutting
  useEffect(() => {
    emitter.once(WorkerEvents.CUT_SOURCE_FILE_DONE, readDestinationFile);
    return () => {
      emitter.removeListener(WorkerEvents.CUT_SOURCE_FILE_DONE, readDestinationFile);
    };
  }, [emitter, readDestinationFile]);

  const handleChangeDuration = useCallback(({ target }) => {
    setDuration(target.duration);
  }, []);

  const handleChangePosition = useCallback((start, end) => {
    setPosition({ start, end });
  }, []);

  const handleVideoTimeUpdate = useCallback(() => {
    if (!sourceRef.current || position.end === 0) return;
    const { currentTime } = sourceRef.current;
    if (currentTime >= position.end || currentTime < position.start) {
      sourceRef.current.currentTime = position.start;
    }
  }, [sourceRef, position]);

  const handleCreateGif = () => {
    setProgress(0);
    setIsLoading(true);
    cutSourceFile(position.start, position.end);
  };

  return (
    <>
      <LayoutHeader isLoading={isLoading} onClickCreate={handleCreateGif} onClickBack={onClickBack} />
      <div className="maker-editor">
        {isLoading && (
          <div className="maker-editor__loader">
            <LinearProgress value={Math.min(90, progress * 100)} />
          </div>
        )}
        <div className="maker-editor__video">
          <video
            className="w-full h-full"
            ref={sourceRef}
            onDurationChange={handleChangeDuration}
            onTimeUpdate={handleVideoTimeUpdate}
            autoPlay
            loop
            muted
          />
        </div>
        <EditorControls
          isLoading={working}
          duration={duration}
          onChangePosition={handleChangePosition}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  uploadFile: file => dispatch(startUpload([file])),
});

export default connect(null, mapDispatchToProps)(Editor);
