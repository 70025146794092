import * as actions from '../../actions/auth';

const initialState = {
  myId: null,
  loginError: null,
  signupError: null,
  emailChecks: {},
  error: null,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_AUTH_SUCCESS:
      return {
        ...state,
        isLoaded: true,
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case actions.SIGNUP_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        myId: action.id,
        error: null,
      };
    case actions.LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    case actions.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        emailChecks: {
          ...state.emailChecks,
          [action.email]: action.exists,
        },
      };
    case actions.CLEAR_AUTH_ERROR:
      return {
        ...state,
        error: null,
      };
    default: return state;
  }
};
