import PropTypes from 'prop-types';
import React from 'react';
import { Button, FontIcon } from '../../Common';
import './index.css';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClickOpenMenu = this.handleClickOpenMenu.bind(this);
  }

  handleFocus(e) {
    // @see https://stackoverflow.com/questions/35951771/react-autofocus-sets-curser-to-beginning-of-input-value
    const val = e.target.value;
    e.target.value = ''; // eslint-disable-line
    e.target.value = val; // eslint-disable-line
    setTimeout(this.props.onFocus, 50);
  }

  handleBlur() {
    this.props.onBlur();
  }

  handleChange(event) {
    const query = event.target.value;
    this.changeQuery(query);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.input.blur();
    if (!this.props.query) return;
    this.props.onSubmit();
  }

  handleClickOpenMenu(e) {
    e.preventDefault();
    this.props.openMenu();
  }

  changeQuery(query) {
    this.props.onChange(query);
  }

  render() {
    const classes = [
      'search-dropdown-input',
      this.props.isMobile ? 'mobile' : 'desktop',
      this.props.showMenuButton ? 'search-dropdown-input_show-menu-button' : '',
    ].join(' ');

    return (
      <form action="" className={classes} onSubmit={this.handleSubmit}>
        <button
          aria-label="Button"
          type="submit"
          onClick={this.handleSubmit}
          style={{ position: 'absolute', left: -100 }}
        />

        <div className="search-dropdown-input__menu">
          <Button
            onClick={this.handleClickOpenMenu}
            className="search-dropdown-input__menu-button"
            type="transparent"
            size="large"
            icon={<FontIcon size="27px" name="icon-menu" />}
          />
        </div>

        <input
          name="search"
          ref={(el) => {
            this.input = el;
            this.props.inputRef(el);
          }}
          onChange={this.handleChange}
          onTouchStart={this.props.onTouchStart}
          onBlur={this.props.onBlur}
          onFocus={this.handleFocus}
          onClick={this.props.onClick}
          value={this.props.query}
          placeholder={this.context.intl.formatMessage({ id: 'search.placeholder' })}
          aria-label={this.context.intl.formatMessage({ id: 'search.placeholder' })}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="off"
          className="search-dropdown-input__input"
        />

        <div className="search-dropdown-input__input-buttons">
          {this.props.query.length > 0 && (
            <Button
              type="transparent"
              icon={<FontIcon size="24px" name="icon-close" />}
              nativeProps={{
                onMouseDown: (e) => {
                  this.changeQuery('');
                  e.preventDefault();
                },
              }}
            />
          )}

          <Button
            type="gradient"
            size="large"
            icon={<FontIcon size="27px" name="icon-search" />}
            onClick={this.handleSubmit}
          />
        </div>
      </form>
    );
  }
}

Input.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  query: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onTouchStart: PropTypes.func,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
  showMenuButton: PropTypes.bool,
  openMenu: PropTypes.func,
  inputRef: PropTypes.func,
};

Input.defaultProps = {
  query: undefined,
  showMenuButton: false,
  openMenu: () => { },
  inputRef: () => { },
  onBlur: () => { },
  onChange: () => { },
  onFocus: () => { },
  onTouchStart: () => { },
  onClick: () => { },
  onSubmit: () => { },
};

Input.contextTypes = {
  intl: PropTypes.objectOf(PropTypes.any),
};

export default Input;
