import PropTypes from 'prop-types';
import React from 'react';
import Matches from '../Matches';
import './Mobile.css';

class Results extends React.Component {
  shouldComponentUpdate(nextProps) {
    const wasLoaded = this.props.isLoading === true && nextProps.isLoading === false;
    return wasLoaded;
  }

  render() {
    return (
      <div className="search-results mobile">
        <div className="search-results-panel">
          <Matches
            matches={this.props.matches}
            highlight={this.props.query}
          />
        </div>
      </div>
    );
  }
}

Results.propTypes = {
  matches: PropTypes.arrayOf(PropTypes.string).isRequired,
  query: PropTypes.string,
  isLoading: PropTypes.bool,
};

Results.defaultProps = {
  query: '',
  isLoading: false,
};

export default Results;
