import { takeEvery, call, put } from 'redux-saga/effects';
import * as actions from '../../actions/fullTags';
import { getTag } from '../../utils/api/tags';

function* fetchFullTag(action) {
  try {
    const data = yield call(getTag, action.slug);
    yield put(actions.fetchFullTagSuccess(data.entities));
  } catch (error) {
    yield put(actions.fetchFullTagFailure(action.slug, error.message));
  }
}

export function* watchTagRequest() {
  yield takeEvery(actions.FETCH_FULL_TAG_REQUEST, fetchFullTag);
}
