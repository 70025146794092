export default (filter) => {
  const opt = filter ? filter.split('/') : [];
  switch (opt[0]) {
    case 'search': return { query: opt[1], section: opt[0] };
    case 'tag': return { tag: decodeURIComponent(opt[1]), section: opt[0] };
    case 'media': return { media: opt[1], section: opt[0] };
    case 'user': return { user: opt[1], type: opt[2], section: opt[0] };
    case 'event': return { id: opt[1], slug: opt[2], section: opt[0], query: opt[3] };
    case 'smile': return { id: opt[1], slug: opt[2], section: opt[0], query: opt[3] };
    default: return { section: opt[0] };
  }
};
