import { connect } from 'react-redux';
import OtherMediaControls from '../../components/PageMedia/OtherMediaControls';
import parseMediaFilter from '../../utils/parseMediaFilter';
import smiles from '../../utils/smiles';

const mapStateToProps = (state) => {
  const filter = parseMediaFilter(state.media.lastFilter);

  let name;
  let image;
  if (filter.section === 'user') {
    const user = state.users.fullEntities[filter.user] || {};
    name = user.username;
  }

  if (filter.section === 'event') {
    const event = state.events.data.find(d => d.slug === filter.slug);
    ({ name } = event);
  }

  if (filter.section === 'smile') {
    const smile = state.smiles.data.find(d => d.slug === filter.slug);
    image = smiles[smile.cover];
  }

  return {
    filter,
    name,
    image,
  };
};

export default connect(mapStateToProps)(OtherMediaControls);
