import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { persistUserLocale } from '../../../utils/persistUserLocale';
import { FontIcon } from '../../Common';
import './index.css';

class PopupContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      showLanguages: false,
    };
    this.showLanguages = this.showLanguages.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  showLanguages(event) {
    event.stopPropagation();
    this.setState({ showLanguages: !this.state.showLanguages });
  }

  handleDocumentClick(event) {
    if (this.state.visible && !this.popupRef.contains(event.target)) {
      this.hidePopup();
    }
  }

  handleClick() {
    if (this.state.visible) this.hidePopup();
    else this.setState({ visible: true });
  }

  hidePopup() {
    this.setState({
      visible: false,
      showLanguages: false,
    });
  }

  renderLangMenu() {
    // page url without locale
    const path = typeof window !== 'undefined' ? window.location.pathname.replace(/\/\w{2}(\W|)/, '') : '';
    const display = (!this.props.isAuth || this.state.showLanguages) ? 'block' : 'none';

    return (
      <ul style={{ display }}>
        {this.props.locales.map(locale => (
          <li key={locale}>
            <a onClick={() => persistUserLocale(locale)} href={`/${locale}${path ? `/${path}` : ''}`}>
              <FormattedMessage id={`lang.${locale}`}>
                {text => (
                  <span>
                    <b>{locale.toUpperCase()}</b>
                    {' '}
                    –
                    {' '}
                    {text}
                  </span>
                )}
              </FormattedMessage>
            </a>
          </li>
        ))}
      </ul>
    );
  }

  renderMainMenu() {
    const display = this.state.showLanguages ? 'none' : 'block';

    return (
      <ul style={{ display }}>
        <li>
          <div onClick={this.showLanguages}>
            <FontIcon name="icon-globe" size={27} style={{ marginRight: 5 }} />
            <span style={{ flex: 1 }}>
              <FormattedMessage id={`lang.${this.props.locale}`}>
                {text => (
                  <span>
                    <b>{this.props.locale.toUpperCase()}</b>
                    {' '}
                    –
                    {' '}
                    {text}
                  </span>
                )}
              </FormattedMessage>
            </span>
            <FontIcon name="icon-arrow-left-2" size={21} />
          </div>
        </li>
        <li><div onClick={this.props.onLogout}><FormattedMessage id="menu.logout" /></div></li>
      </ul>
    );
  }

  render() {
    return (
      <div ref={el => (this.popupRef = el)} onClick={this.handleClick}>
        <div
          className={`menu-user-popup ${this.props.isMobile ? 'mobile' : ''}`}
          style={{ display: this.state.visible ? 'block' : 'none' }}
        >
          {this.renderLangMenu()}
          {this.props.isAuth && this.renderMainMenu()}
        </div>
        {this.props.children}
      </div>
    );
  }
}

PopupContent.propTypes = {
  isMobile: PropTypes.bool,
  isAuth: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
  onLogout: PropTypes.func.isRequired,
};

PopupContent.defaultProps = {
  isMobile: false,
};

export default PopupContent;
