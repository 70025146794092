import * as actions from '../../actions/events';

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: [],
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_EVENT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.data],
      };

    case actions.FETCH_EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.data,
      };
    case actions.FETCH_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
