export const FETCH_FULL_TAG_REQUEST = 'FETCH_FULL_TAG_REQUEST';
export const FETCH_FULL_TAG_SUCCESS = 'FETCH_FULL_TAG_SUCCESS';
export const FETCH_FULL_TAG_FAILURE = 'FETCH_FULL_TAG_FAILURE';

export const fetchFullTagRequest = slug => ({
  type: FETCH_FULL_TAG_REQUEST,
  slug,
});

export const fetchFullTagSuccess = entities => ({
  type: FETCH_FULL_TAG_SUCCESS,
  entities,
});

export const fetchFullTagFailure = (slug, error) => ({
  type: FETCH_FULL_TAG_FAILURE,
  slug,
  error,
});
