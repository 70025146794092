import { get } from 'lodash';

export const getTags = (ids, exclude, state) => {
  const scores = ids.reduce((acc, current) => {
    const item = state.media.entities[current];

    item.tags.forEach((tag) => {
      const name = tag.tag.id;
      const score = Math.log(tag.val + 1);

      acc[name] = {
        ...tag,
        score: get(acc, [name, 'score'], 0) + score,
      };
    });

    return acc;
  }, {});

  const result = Object
    .values(scores)
    .filter(item => exclude.includes(item.tag.id) === false)
    .sort((a, b) => a.score < b.score ? 1 : -1) // eslint-disable-line
    .slice(0, 10);

  return result;
};
