import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontIcon, Button } from '../../Common';
import logoCube from '../../../assets/logo-cube.svg';
import './index.css';

const Header = ({ onClickShare }) => (
  <div className="page-embed-header">
    <a className="page-embed-header__logo" href={`${process.env.REACT_APP_DOMAIN}/?utm_source=${encodeURIComponent(document.referrer)}&utm_medium=embed-main&utm_campaign=iFRame`} target="_blank" rel="noopener noreferrer">
      <img src={logoCube} alt="logo" className="page-embed-header__logo-image" />
      <FontIcon size="21px" color="#fff" name="icon-logo-text" />
    </a>

    <Button
      className="page-embed-header__share-button"
      type="transparent"
      icon={<FontIcon name="icon-share" color="#FFFFFF" />}
      size="small"
      onClick={onClickShare}
    >
      <FormattedMessage id="page-embed-header.share-button" />
    </Button>
  </div>
);

Header.propTypes = {
  onClickShare: PropTypes.func,
};

Header.defaultProps = {
  onClickShare: undefined,
};

export default Header;
