import PropTypes from 'prop-types';
import React from 'react';

const ActionButton = ({ className, icon, title, ...props }) => {
  const Elem = props.href ? 'a' : 'div';
  return (
    <Elem className={`action-button ${className}`} {...props}>
      {icon}
      <span className="text">{title}</span>
    </Elem>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.element,
  href: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
};

ActionButton.defaultProps = {
  icon: null,
  href: null,
  className: '',
  title: '',
};

export default ActionButton;
