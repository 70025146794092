import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Avatar from '../../../containers/User/Avatar';
import './index.css';

const UserPanel = props =>
  <div className="page-user-user-panel">
    <div className="page-user-user-panel__left-part">
      <Avatar
        size={props.isMobile ? 90 : 130}
        iconSize={props.isMobile ? 90 : 100}
        user={props.id}
      />
    </div>
    <div className="page-user-user-panel__right-part">
      <span className="page-user-user-panel__username">
        {props.username}
      </span>

      <div className="page-user-user-panel__counters">
        {props.isOwner &&
          <div className="page-user-user-panel__favorites">
            <span className="page-user-user-panel__favorites-value">
              {props.counters.likes}
            </span>
            <span className="page-user-user-panel__favorites-intl">
              <FormattedMessage id="page_user.link_to_favorites" />
            </span>
          </div>
        }
        <div className="page-user-user-panel__uploaded">
          <span className="page-user-user-panel__uploaded-value">
            {props.counters.gifs}
          </span>

          <span className="page-user-user-panel__uploaded-intl">
            <FormattedMessage id="page_user.link_to_uploaded" />
          </span>
        </div>
      </div>
    </div>
  </div>;

UserPanel.propTypes = {
  isMobile: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  username: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
  counters: PropTypes.shape({
    likes: PropTypes.number,
    gifs: PropTypes.number,
  }),
};

export default UserPanel;
