import React from 'react';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { getGalleryLdJson } from '../../utils/ldJson';
import { getMediaSrc } from '../../utils/url';
import Meta from '../../containers/PageEvent/Meta';
import SmartTags from '../../containers/SmartTags';
import ShareButtons from './ShareButtons';
import MasonryObserver from '../MasonryObserver';
import './index.css';
import AnalyticsPageView from '../AnalyticsPageView';

const PageEvent = ({
  listId, id, name, description, locale, isMobile, cover, server, statQuery,
}, { intl }) => {
  const url = `${process.env.REACT_APP_DOMAIN}/${locale}/events/${id}`;
  const messageValues = {
    name,
    description,
    Name: upperFirst(name),
  };
  const gifSrc = getMediaSrc(server, cover.file.path, 'gif');
  const mp4Src = getMediaSrc(server, cover.file.path, 'mp4');

  let ldJSON;
  if (cover && typeof window === 'undefined') {
    ldJSON = getGalleryLdJson({
      url,
      intl,
      media: cover,
      server,
      messageValues,
      seedKey: id,
      intlPrefix: 'page_event.article_',
    });
  }

  return (
    <>
      <AnalyticsPageView />
      <Helmet>
        {ldJSON && (
          <script type="application/ld+json">
            {JSON.stringify(ldJSON)}
          </script>
        )}
      </Helmet>

      <Meta
        id="page_event"
        url={`/events/${id}`}
        cover={cover}
        mp4Src={mp4Src}
        gifSrc={gifSrc}
        values={messageValues}
      />
      <main className="page-event" itemProp="mainEntity" itemScope="image" itemType="http://schema.org/ImageGallery">
        <article>
          <div className="page-event__photostatus">
            <div className="page-event__photostatus-image-container">
              <video
                key={mp4Src}
                className="page-event__photostatus-image"
                loop
                autoPlay
                muted
                playsInline
              >
                <source src={mp4Src} type="video/mp4" />
              </video>
            </div>

            <div className="page-event__photostatus-info">
              <link itemProp="url" content={url} />
              <link itemProp="mainEntityOfPage" content={url} />
              <meta itemProp="keywords" content={intl.formatMessage({ id: 'page_event.article_keywords' }, messageValues)} />

              <h1 itemProp="headline name" className="page-event__photostatus-title">
                <FormattedMessage
                  id="page_event.tag_name"
                  values={{
                    Name: upperFirst(name),
                    name,
                  }}
                />
              </h1>

              <h2 className="page-event__photostatus-description">
                {description}
              </h2>

              {isMobile === false && (
                <div className="page-event__photostatus-share_desktop">
                  <ShareButtons locale={locale} id={id} gifSrc={gifSrc} values={messageValues} />
                </div>
              )}
            </div>
          </div>

          {isMobile === true && (
            <div className="page-event__photostatus-share_mobile">
              <ShareButtons
                locale={locale}
                id={id}
                gifSrc={gifSrc}
                values={messageValues}
                isMobile
              />
            </div>
          )}

          <section className="page-event__smart-tags">
            <SmartTags showMore={false} listId={listId} />
          </section>

          {listId && (
            <section className="page-event__masonry">
              <MasonryObserver
                locale={locale}
                id={listId}
                save
                statPayload={{ tag: statQuery }}
                query={statQuery}
                contextMenuEvent={isMobile
                  ? 'mobile_right_button_gif_events'
                  : 'desktop_right_button_gif_events'}
              />
            </section>
          )}
        </article>
      </main>
    </>
  );
};

PageEvent.propTypes = {
  listId: PropTypes.string,
  id: PropTypes.string.isRequired,
  cover: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  statQuery: PropTypes.string,
  server: PropTypes.string,
  isMobile: PropTypes.bool,
};

PageEvent.defaultProps = {
  listId: undefined,
  cover: { file: {} },
  locale: 'en',
  name: ' ',
  description: '',
  statQuery: '',
  server: undefined,
  isMobile: true,
};

PageEvent.contextTypes = {
  intl: PropTypes.objectOf(PropTypes.any),
};

export default PageEvent;
