import PropTypes from 'prop-types';
import React from 'react';
import { Overlay, FontIcon } from '../Common';
import './index.css';

const Modal = props => (
  <div className="modal-container">
    <Overlay onClick={props.closeModal} />
    <div className="modal-close-button" onClick={props.closeModal}>
      <FontIcon name="icon-close" color="#fff" size={27} />
    </div>
    <div className={`modal ${props.className}`}>
      {props.children}
    </div>
  </div>
);

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
