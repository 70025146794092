import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const Header = (props) => {
  let El = props.element || 'div';
  El = props.href ? Link : El;

  return (
    <El to={props.href} className="page-header">
      {props.title}
      <span className="icon">{props.icon}</span>
    </El>
  );
};

Header.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.node,
  href: PropTypes.string,
  element: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default Header;
