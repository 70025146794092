import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import ShareButtons from '../../ShareButtons';

const ShareButtonsWrapper = ({ locale, id, isMobile, gifSrc, values, intl }) => {
  const pageUrl = `${process.env.REACT_APP_DOMAIN}/${locale}/events/${id}`;
  const textValues = { ...values, pageUrl };
  return (
    <ShareButtons
      locale={locale}
      pageUrl={pageUrl}
      gifSrc={gifSrc}
      pinterestDescription={intl.formatMessage({ id: 'page_event.share_pinterest_caption' }, textValues)}
      tumblrCaption={encodeURIComponent(intl.formatMessage({ id: 'page_event.share_tumblr_caption' }, textValues))}
      tumblrTags={intl.formatMessage({ id: 'page_event.share_tumblr_tags' }, textValues)}
      isMobile={isMobile}
    />
  );
};

ShareButtonsWrapper.propTypes = {
  intl: intlShape.isRequired,
  values: PropTypes.object.isRequired, // eslint-disable-line
  locale: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  gifSrc: PropTypes.string,
};

ShareButtonsWrapper.defaultProps = {
  isMobile: false,
  gifSrc: '',
};

export default injectIntl(ShareButtonsWrapper);
