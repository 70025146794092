import PropTypes, { string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import get from 'lodash/get';
import { fetchMediaRequest, incMediaStat } from '../../actions/media';
import { fetchMediaListRequest, saveMediaPageFilter, saveMediaListFilter } from '../../actions/mediaList';
import PageMedia from '../../components/PageMedia';
import parseMediaFilter from '../../utils/parseMediaFilter';
import mediaTextValues from '../../utils/mediaTextValues';
import { getMediaSrc } from '../../utils/url';
import gtm, { analyticsEvent } from '../../utils/gtm';
import { send } from '../../utils/ws';

class PageMediaContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleContextMenu = this.handleContextMenu.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!this.props.isLoaded) {
      this.props.fetchMediaRequest(this.props.id);
    }

    this.fetchPageList(this.props.pageFilter, this.props.isPageListLoaded);
  }

  componentDidUpdate(prevProps) {
    // if masonry list is empty - load popular list.
    // eslint-disable-next-line
    if (this.props.masonryList.isLoaded === true && prevProps.masonryList.isLoaded === false && this.props.masonryList.ids.length === 0) {
      this.props.saveMediaListFilter('popular');
    }

    if (prevProps.pageFilter !== this.props.pageFilter) {
      this.fetchPageList();
    }
  }

  fetchPageList() {
    this.props.saveMediaPageFilter(this.props.pageFilter);

    if (!this.props.isPageListLoaded) {
      // todo: set default filter somewhere
      const limit = 80;
      const skip = 0;
      this.props.fetchMediaListRequest(this.props.pageFilter, skip, limit);
    }
  }

  handleContextMenu() {
    const message = ['click', this.props.sourceTag || '-tsymbal-', this.props.id, this.props.locale].join('\n');
    send(message);

    if (this.props.sourceTag) {
      analyticsEvent('ab', 'Использовал гифку после поиска');
    }

    this.props.incStat({ type: 'other', media: this.props.id, tag: this.props.sourceTag });
    gtm({ event: this.props.isMobile ? 'mobile_copy_image_mp4' : 'desktop_right_button_mp4' });
  }

  render() {
    if (this.props.isLoaded && !this.props.ad) {
      analyticsEvent('Navigation', 'first_redirect');
    }

    // dan abramov sorry 😭
    if (typeof window !== 'undefined' && (this.props.safeStatus === 'danger' || (this.props.ad === false && this.props.kind === 'GExternal'))) {
      const tag = get(this.props, 'tags.0.tag.id', '');
      this.props.goTo(tag ? `/gifs/${tag}` : '');
      return null;
    }

    return (
      <PageMedia
        {...this.props}
        onContextMenu={this.handleContextMenu}
      />
    );
  }
}

PageMediaContainer.propTypes = {
  id: PropTypes.string,
  sourceTag: PropTypes.string,
  isLoaded: PropTypes.bool.isRequired,
  locale: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  pageFilter: PropTypes.string,
  fetchMediaRequest: PropTypes.func.isRequired,
  fetchMediaListRequest: PropTypes.func.isRequired,
  saveMediaPageFilter: PropTypes.func.isRequired,
  saveMediaListFilter: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  isPageListLoaded: PropTypes.bool.isRequired,
  incStat: PropTypes.func.isRequired,
  ad: PropTypes.bool,
  kind: PropTypes.string,
  safeStatus: string,
};

PageMediaContainer.defaultProps = {
  id: undefined,
  sourceTag: undefined,
  isMobile: false,
  pageFilter: undefined,
  ad: false,
  kind: undefined,
  safeStatus: undefined,
};

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;
  const { isMobile } = state.env;
  const isLoaded = !!state.media.entities[id];
  const media = isLoaded ? state.media.entities[id] : {};
  const creator = state.users.entities[media.creator];

  const thisMediaFilter = `media/${id}`;
  let pageFilter = state.media.lastFilter || state.media.pageFilter || thisMediaFilter;
  const { section } = parseMediaFilter(pageFilter) || {};
  const isFromThumb = !!(props.location.state && props.location.state.thumb);
  if (section === 'media' && isFromThumb) {
    pageFilter = thisMediaFilter;
  }

  const { ids } = state.media.lists[pageFilter] || {};
  const isPageListLoaded = !!ids;

  let nextMediaId;
  let prevMediaId;

  if (isPageListLoaded) {
    const currentMediaIndex = ids.indexOf(id);
    nextMediaId = ids[currentMediaIndex + 1] || ids[0];
    if (currentMediaIndex > 0) {
      prevMediaId = ids[currentMediaIndex - 1] || ids[ids.length - 1];
    }
  }

  // default other gifs is gifs by main gif tags
  // this is masonry list
  const mediaFilter = state.media.lastFilter || thisMediaFilter;
  const { tag, query, section: masonrySection } = parseMediaFilter(state.media.lastFilter);

  const server = media.file && state.uploadServers.data[media.file.server];
  let gifSrc = '';
  if (server) {
    gifSrc = getMediaSrc(server, media.file.path, 'gif');
  }

  const textValues = mediaTextValues({ media, creator });

  const lastChar = id.charAt(id.length - 1).toLowerCase();
  const textGroup = '12abcde'.includes(lastChar) ? '1' : ('34fghij'.includes(lastChar) ? '2' : ''); // eslint-disable-line
  const textKey = `page_media${textValues.user.length ? '' : '.wou'}${textGroup}`;
  const masonryList = state.media.lists[mediaFilter] || {};

  return {
    ...media,
    isLoaded,
    isMobile,
    nextMediaId,
    prevMediaId,
    mediaFilter,
    gifSrc,
    textValues,
    textKey,
    id: props.match.params.id,
    locale: state.intl.locale,
    sourceTag: tag || query,
    section: masonrySection,
    server,
    pageFilter,
    isPageListLoaded,
    masonryList,
  };
};

const mapDispatchToProps = {
  fetchMediaRequest,
  saveMediaPageFilter,
  saveMediaListFilter,
  fetchMediaListRequest,
  goTo: push,
  incStat: incMediaStat,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageMediaContainer);
