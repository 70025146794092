import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { categoryTagsRequest } from '../../actions/categories';
import TagThumbList from '../../components/Tags/TagThumbList';
import Stub from '../../components/MediaMasonry/Stub';

class CategoryTagsContainer extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.getCategoryTags(this.props.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.props.getCategoryTags(nextProps.id);
    }
  }

  render() {
    const gutter = this.props.isMobile ? 5 : 15;
    return (
      this.props.isLoaded ? (
        <TagThumbList
          isMobile={this.props.isMobile}
          category={this.props.id}
          gutter={gutter}
          tags={this.props.tags}
        />
      ) : (
        <Stub
          columnCount={this.props.isMobile ? 2 : 4}
          gutter={gutter}
          itemsCount={16}
          itemHeight={this.props.isMobile ? 106 : 157}
        />
      )
    );
  }
}

CategoryTagsContainer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  id: PropTypes.string,
  isLoaded: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  getCategoryTags: PropTypes.func,
};

CategoryTagsContainer.defaultProps = {
  id: undefined,
  isLoaded: false,
  tags: [],
  getCategoryTags: () => {},
};

const mapStateToProps = (state, props) => {
  const category = state.categories.entities[props.id];
  const tags = state.categories.tags[props.id] || {};

  return {
    ...category,
    isMobile: state.env.isMobile,
    isLoaded: tags.isLoaded,
    tags: tags.ids,
  };
};

const mapDispatchToProps = {
  getCategoryTags: categoryTagsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTagsContainer);
