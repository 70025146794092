import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../Common/Tooltip';
import { List, Item } from '../../Common/List';
import { FontIcon } from '../../Common';
import PopupReview from './PopupReview';
import PopupBlame from './PopupBlame';
import './index.css';

class TooltipBlame extends Component {
  constructor(props) {
    super(props);
    this.childrenEl = React.createRef();
    this.state = {
      showTooltip: false,
      showReview: false,
      showBlame: false,
      translate: 0,
    };
  }

  handleOpen = () => {
    this.setState({
      showTooltip: true,
      translate: this.childrenEl.current.clientWidth / 2,
    });

    this.props.onOpen();
  };

  handleClose = () => {
    this.setState({
      showTooltip: false,
      showReview: false,
      showBlame: false,
    });

    this.props.onClose();
  };

  render() {
    return (
      <div className="page-media-tooltip-blame" ref={this.childrenEl}>
        <div
          className="page-media-tooltip-blame__children"
          onClick={this.state.showTooltip ? this.handleClose : this.handleOpen}
        >
          {this.props.children}
        </div>

        {this.state.showTooltip && (
          <div
            className="page-media-tooltip-blame__tooltip"
            style={{ transform: `translateX(${this.state.translate}px)` }}
          >
            <Tooltip closeRequest={this.handleClose}>
              <List>
                {this.props.showDownload && (
                  <Item
                    href={this.props.downloadURL}
                    target="_blank"
                    onClick={() => {
                      this.props.onClickDownload();
                      this.setState({
                        showTooltip: false,
                      });
                    }}
                    icon={<FontIcon name="icon-download" color="#000000" size={27} />}
                  >
                    <FormattedMessage id="page_media.download_gif" />
                  </Item>
                )}
                <Item
                  onClick={() =>
                    this.setState({ showReview: true, showTooltip: false })}
                  icon={
                    <FontIcon name="icon-write-us" color="#1EC2BA" size={27} />
                  }
                >
                  <FormattedMessage id="page-media-blame.tooltip-leave-a-review" />
                </Item>
                <Item
                  onClick={() =>
                    this.setState({ showBlame: true, showTooltip: false })}
                  icon={
                    <FontIcon name="icon-Wrong" color="#C65656" size={27} />
                  }
                >
                  <FormattedMessage id="page-media-blame.tooltip-blame" />
                </Item>
                {this.props.isMobile === true && (
                  <Item
                    onClick={this.props.onClickEmbed}
                    icon={<FontIcon name="icon-embed" size={27} />}
                  >
                    <FormattedMessage id="page_media.embed" />
                  </Item>
                )}
              </List>
            </Tooltip>
          </div>
        )}

        {this.state.showReview && (
          <PopupReview
            closeModal={this.handleClose}
            mediaId={this.props.mediaId}
          />
        )}

        {this.state.showBlame && (
          <PopupBlame
            closeModal={this.handleClose}
            mediaId={this.props.mediaId}
          />
        )}
      </div>
    );
  }
}

TooltipBlame.propTypes = {
  children: PropTypes.node.isRequired,
  mediaId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  showDownload: PropTypes.bool,
  downloadURL: PropTypes.string,
  onClickEmbed: PropTypes.func,
  onClickDownload: PropTypes.func,
};

TooltipBlame.defaultProps = {
  isMobile: false,
  onOpen: () => {},
  onClose: () => {},
  onClickEmbed: () => {},
  onClickDownload: () => {},
  showDownload: false,
  downloadURL: undefined,
};

export default TooltipBlame;
