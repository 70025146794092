/**
 * Convert seconds to human readable time
 * @param seconds Time in seconds
 * @returns hh:mm:ss or mm:ss
 */
export const convertSecondsToTime = (seconds) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  // respect fractional seconds
  const ss = Math.round(date.getSeconds() + date.getUTCMilliseconds() / 1000);

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss.toString().padStart(2, '0')}`;
  }
  return `${mm}:${ss.toString().padStart(2, '0')}`;
};
