import PropTypes from 'prop-types';
import React from 'react';
import '../../../assets/iconfont/icons.css';
import './index.css';

const FontIcon = props => (
  <span
    className={`${props.name} ${props.className} icon`}
    style={{
      ...props.style,
      color: props.color,
      fontSize: props.size,
    }}
  />
);

FontIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object, // eslint-disable-line
  className: PropTypes.string,
};

FontIcon.defaultProps = {
  color: undefined,
  size: undefined,
  style: {},
  className: '',
};

export default FontIcon;
