function lsTest() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

const storage = lsTest() ? window.localStorage : {
  getItem: () => null,
  setItem: () => { },
  removeItem: () => { },
};

export default storage;
