import request from '../index';
import CategorySchema from '../../../schemas/category';
import TagSchema from '../../../schemas/fullTag';

export const getCategories = locale => (
  request('api/categories', {
    locale,
    normalize: [CategorySchema],
  })
);

export const getCategoryTags = (categoryId, locale) => (
  request(`api/categories/${encodeURIComponent(categoryId)}/tags?include=cover`, {
    locale,
    normalize: [TagSchema],
  })
);
