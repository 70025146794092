import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchUserRequest } from '../../actions/users';
import UserPanel from '../../components/PageUser/UserPanel';

class UserPanelContainer extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.fetchUserRequest(this.props.user);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.user !== nextProps.user) {
      this.props.fetchUserRequest(nextProps.user);
    }
  }

  render() {
    return (
      this.props.isLoaded
        ? <UserPanel {...this.props} />
        : null
    );
  }
}

UserPanelContainer.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  fetchUserRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const user = state.users.fullEntities[props.user];
  return {
    isLoaded: !!user,
    isMobile: state.env.isMobile,
    ...user,
  };
};

const mapDispatchToProps = {
  fetchUserRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPanelContainer);
