import { LOCATION_CHANGE } from 'react-router-redux';
import * as actions from '../../actions/modal';

const initialState = {
  component: null,
  props: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return {
        ...state,
        component: action.component,
        props: action.props,
      };
    case LOCATION_CHANGE:
    case actions.CLOSE_MODAL:
      return {
        ...state,
        component: null,
      };
    default: return state;
  }
};
