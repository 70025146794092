import PropTypes from 'prop-types';
import React from 'react';
import { FontIcon, Button } from '../../Common';
import './index.css';

const BackButton = props => (
  <Button
    type="transparent"
    className="auth-back-button"
    onClick={props.onClick}
    icon={<FontIcon name="icon-arrow-left" color="#fff" size={22} />}
  />
);

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
