import { takeEvery, call, put, select } from 'redux-saga/effects';
import { getLocale } from '../selectors';
import * as actions from '../../actions/smiles';
import { getSmiles, getSmile } from '../../utils/api/smiles';

function* fetchSmiles() {
  try {
    const locale = yield select(getLocale);
    const data = yield call(getSmiles, locale);
    yield put(actions.fetchSmilesSuccess(data));
  } catch (error) {
    yield put(actions.fetchSmilesFailure(error.message));
  }
}

function* fetchSmile(action) {
  try {
    const data = yield call(getSmile, action.slug);
    yield put(actions.fetchSmileSuccess(data));
  } catch (error) {
    yield put(actions.fetchSmileFailure(error.message));
  }
}

export function* watchSmilesRequest() {
  yield takeEvery(actions.FETCH_SMILES_REQUEST, fetchSmiles);
}

export function* watchSmileRequest() {
  yield takeEvery(actions.FETCH_SMILE_REQUEST, fetchSmile);
}
