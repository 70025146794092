import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontIcon } from '../../../Common';
import logoCube from '../../../../assets/logo-cube.svg';

const LayoutHeader = ({ intl, onClickCreate, onClickBack }) => (
  <div className="page-marker__header">
    {onClickBack ? (
      <button className="page-marker__header__back" onClick={onClickBack}>
        <FontIcon name="icon-arrow-left-2" color="#7F7F7F" size={22} />
        <span><FormattedMessage id="common.back" /></span>
      </button>
    ) : (
      <Link className="logo" to="/">
        <img src={logoCube} alt={intl.formatMessage({ id: 'logo_alt' })} className="hor-menu__logo" />
        <FontIcon size="21px" name="icon-logo-text" color="white" />
      </Link>
    )}
    {onClickCreate && (
      <button
        className="page-marker__header__create"
        onClick={onClickCreate}
      >
        <FormattedMessage id="common.create" />
      </button>
    )}
  </div>
);

export default injectIntl(LayoutHeader);