import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../../actions/uploadServers';
import { getUploadServers } from '../../utils/api/upload_servers';

function* fetchUploadServers() {
  try {
    const data = yield call(getUploadServers);
    yield put(actions.fetchUploadServersSuccess(data));
  } catch (error) {
    yield put(actions.fetchUploadServersFailure(error.message));
  }
}

export function* watchFetchServersRequest() {
  yield takeEvery(actions.FETCH_UPLOAD_SERVERS_REQUEST, fetchUploadServers);
}
