import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PartFavorites from '../../components/PageUser/PartFavorites';

const PartFavoritesContainer = (props) => {
  if (props.doRedirect) {
    return <Redirect to="/" />;
  }

  return <PartFavorites {...props} />;
};

PartFavoritesContainer.propTypes = {
  doRedirect: PropTypes.bool.isRequired,
};


const mapStateToProps = (state, props) => {
  const isOwner = props.match.params.id === state.auth.myId;
  const doRedirect = state.auth.isLoaded && !isOwner;
  const user = state.users.fullEntities[props.match.params.id];
  return {
    doRedirect,
    ...user,
  };
};

export default connect(mapStateToProps)(PartFavoritesContainer);
