import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontIcon } from '../../Common';
import BackButton from '../BackButton';
import './index.css';

const SocialButton = props => (
  <div className="social-button" style={{ backgroundColor: props.color }} onClick={props.onClick}>
    <div className="icon-block">{props.icon}</div>
    <div className="title">{props.title}</div>
  </div>
);

SocialButton.propTypes = {
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const PartSocial = props => (
  <div className="auth-part-social content">
    <div className="sub-header">
      <BackButton onClick={props.onClickBack} />
      <FormattedMessage id="auth.header_social_auth" />
    </div>
    <div className="buttons">
      <SocialButton
        title="Facebook"
        icon={<FontIcon name="icon-facebook" color="#fff" size={27} />}
        color="#3163a2"
        onClick={() => props.socialLogin('facebook')}
      />
      <SocialButton
        title="Vkontakte"
        icon={<FontIcon name="icon-vkontakte" color="#fff" size={27} />}
        color="#44688a"
        onClick={() => props.socialLogin('vk')}
      />
      <SocialButton
        title="Google"
        color="#dc3534"
        icon={<FontIcon name="icon-google" size={27} />}
        onClick={() => props.socialLogin('google')}
      />
      <SocialButton
        title="E-mail"
        icon={<FontIcon name="icon-mail" color="#fff" size={27} />}
        color="#3d3e42"
        onClick={props.onClickEmail}
      />
    </div>
  </div>
);

PartSocial.propTypes = {
  socialLogin: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickEmail: PropTypes.func.isRequired,
};

export default PartSocial;
