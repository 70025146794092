import { fork } from 'redux-saga/effects';
import * as intl from './intl';
import * as categories from './categories';
import * as media from './media';
import * as upload from './upload';
import * as auth from './auth';
import * as search from './search';
import * as searchHints from './searchHints';
import * as events from './events';
import { watchSmilesRequest, watchSmileRequest } from './smiles';
import { watchTagRequest } from './fullTags';
import { watchFetchServersRequest } from './uploadServers';
import { watchChangeRoute } from './router';
import { watchFetchUserRequest } from './users';
import { watchInitEnv } from './env';
import { watchLikeMediaRequest } from './likes';

export default function* rootSaga() {
  yield [
    fork(watchInitEnv),
    fork(watchChangeRoute),
    fork(watchFetchServersRequest),
    fork(watchFetchUserRequest),
    fork(watchLikeMediaRequest),
    fork(events.watchEventsRequest),
    fork(events.watchEventRequest),
    fork(watchSmilesRequest),
    fork(watchSmileRequest),
    fork(watchTagRequest),
    fork(search.watchSearchRequest),
    fork(searchHints.watchFetchSearchHintsRequest),
    fork(upload.watchStartUpload),
    fork(upload.watchFileUploadRequest),
    fork(media.watchFetchMediaListRequest),
    fork(media.watchFetchMediaRequest),
    fork(media.watchCreateMediaRequest),
    fork(media.watchIncMediaStat),
    fork(intl.watchInitIntl),
    fork(intl.watchFetchMessagesRequest),
    fork(categories.watchFetchCategoriesRequest),
    fork(categories.watchFetchCategoryTagsRequest),
    fork(auth.watchInitAuth),
    fork(auth.watchLoginRequest),
    fork(auth.watchSignupRequest),
    fork(auth.watchLogoutRequest),
    fork(auth.watchSocialLoginRequest),
    fork(auth.watchCheckEmailRequest),
  ];
}
