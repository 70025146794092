import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';
import ActionButton from './ActionButton';
import TooltipBlame from '../TooltipBlame';
import { FontIcon } from '../../Common';
import './index.css';

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.state = { URLCopied: false };
    this.handleCopyUrl = this.handleCopyUrl.bind(this);
    this.handleLikeClick = this.handleLikeClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.setState({ URLCopied: false });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.URLCopied && nextState.URLCopied !== this.state.URLCopied) {
      this.props.showAlert('alert.url_copied');
    }
  }

  handleLikeClick() {
    if (this.props.isAuth) {
      this.props.likeMedia(this.props.id, !this.props.liked);
    } else {
      this.props.openAuthPopup();
    }

    if (!this.props.liked) this.props.onLike();
  }

  handleCopyUrl() {
    this.setState({ URLCopied: true });
    this.props.onCopyUrl();

    setTimeout(() => {
      this.setState({ URLCopied: false });
    }, 2000);
  }

  render() {
    const classes = [
      'page-media-controls',
      this.props.isMobile ? 'mobile' : 'desktop',
    ];

    return (
      <div className={classes.join(' ')}>
        <ul className="buttons">
          <li className="page-media-controls__button">
            <ActionButton
              className={`like-button ${this.props.liked ? 'active' : ''}`}
              onClick={this.handleLikeClick}
              icon={(
                <FontIcon
                  name="icon-like"
                  color={this.props.liked ? '#ef4a4a' : undefined}
                  size={24}
                />
              )}
              title={
                this.props.isMobile ? undefined : (
                  <FormattedMessage
                    id={
                      this.props.liked
                        ? 'page_media.already_in_fav'
                        : 'page_media.add_to_fav'
                    }
                  />
                )
              }
            />
          </li>
          {!this.props.isMobile && (
            <li className="page-media-controls__button">
              <ActionButton
                download
                onClick={this.props.onClickDownload}
                href={this.props.downloadURL}
                rel="noopener noreferrer"
                target="_blank"
                className="download"
                icon={<FontIcon name="icon-download" size={24} />}
                title={<FormattedMessage id="page_media.download_gif" />}
              />
            </li>
          )}
          <CopyToClipboard text={this.props.mediaURL}>
            <li className="page-media-controls__button">
              <ActionButton
                className={`copy-url ${this.state.URLCopied ? 'active' : ''}`}
                title={
                  this.props.isMobile ? undefined : (
                    <FormattedMessage
                      id={
                        this.state.URLCopied
                          ? 'page_media.copy_url_done'
                          : 'page_media.copy_url'
                      }
                    />
                  )
                }
                onClick={this.handleCopyUrl}
                icon={<FontIcon name="icon-copy-url" size={24} />}
              />
            </li>
          </CopyToClipboard>

          {this.props.isMobile === false && (
            <li className="page-media-controls__button">
              <ActionButton
                onClick={this.props.onClickEmbed}
                icon={<FontIcon name="icon-embed" size={24} />}
                title={<FormattedMessage id="page_media.embed" />}
              />
            </li>
          )}

          {this.props.isMobile === true && (
            <li className="page-media-controls__button">
              <TooltipBlame
                isMobile={this.props.isMobile}
                onOpen={this.props.onBlameTooltipOpen}
                onClose={this.props.onBlameTooltipClose}
                showDownload={this.props.isMobile}
                downloadURL={this.props.downloadURL}
                mediaId={this.props.id}
                onClickEmbed={this.props.onClickEmbed}
                onClickDownload={this.props.onClickDownload}
              >
                <ActionButton icon={<FontIcon name="icon-point" size={24} />} />
              </TooltipBlame>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

Controls.propTypes = {
  isMobile: PropTypes.bool,
  isAuth: PropTypes.bool.isRequired,
  downloadURL: PropTypes.string,
  mediaURL: PropTypes.string,
  id: PropTypes.string.isRequired,
  liked: PropTypes.bool,
  openAuthPopup: PropTypes.func.isRequired,
  likeMedia: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  onLike: PropTypes.func.isRequired,
  onCopyUrl: PropTypes.func.isRequired,
  onClickDownload: PropTypes.func.isRequired,
  onClickEmbed: PropTypes.func,
  onBlameTooltipOpen: PropTypes.func,
  onBlameTooltipClose: PropTypes.func,
};

Controls.defaultProps = {
  liked: false,
  isMobile: false,
  downloadURL: undefined,
  mediaURL: undefined,
  onBlameTooltipOpen: undefined,
  onBlameTooltipClose: undefined,
  onClickEmbed: undefined,
};

export default Controls;
