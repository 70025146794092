import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const Stub = ({ isMobile, itemWidth, itemsCount }) => (
  <div className="events-panel-stub">
    {[...Array(itemsCount)].map((el, index) => (
      <div
        className={`events-panel-stub__item ${isMobile ? 'events-panel-stub__item_mobile' : ''}`}
        style={{ width: `${(itemWidth * 100) / itemsCount}%` }}
        key={index} //eslint-disable-line
      />
    ))}
  </div>
);

Stub.propTypes = {
  isMobile: PropTypes.bool,
  itemWidth: PropTypes.number,
  itemsCount: PropTypes.number,
};

Stub.defaultProps = {
  isMobile: false,
  itemWidth: 1,
  itemsCount: 1,
};

export default Stub;
