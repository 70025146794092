import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../Common/Button';
import './index.css';

const Success = ({ handleClickClose }) => (
  <div className="popup-review-success">
    <h1 className="popup-review-success__title">
      <FormattedMessage id="page-media.popup-review__success-title" />
    </h1>

    <span className="popup-review-success__description">
      <FormattedMessage id="page-media.popup-review__success-description" />
    </span>

    <div className="popup-review-success__action">
      <Button type="gradient" onClick={handleClickClose} block>
        <FormattedMessage id="page-media.popup-review__success-done" />
      </Button>
    </div>
  </div>
);

Success.propTypes = {
  handleClickClose: PropTypes.func,
};

Success.defaultProps = {
  handleClickClose: undefined,
};

export default Success;
