import React, { useEffect, useState } from "react";
import { WorkerEvents } from "../helpers/ffmpegEvents";
import { useFFmpeg } from "../contexts/ffmpegContext";
import FileUploader from "./fileUploader";
import Editor from "./editor";

/**
 * Root component for the gif maker page that render uploader or editor depending on the state.
 */
const MakerRoot = () => {
  const [state, setState] = useState('uploader');
  const { emitter } = useFFmpeg();

  // todo: Process emitter errors

  useEffect(() => {
    const handler = () => setState('editor');
    emitter.once(WorkerEvents.WRITE_SOURCE_FILE_DONE, handler);
    return () => {
      emitter.removeListener(WorkerEvents.WRITE_SOURCE_FILE_DONE, handler);
    };
  }, [emitter]);

  return (
    <>
      {state === 'uploader' && <FileUploader />}
      {state === 'editor' && <Editor onClickBack={() => setState('uploader')} />}
    </>
  );

};

export default MakerRoot;