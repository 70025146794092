import React from 'react';
import PropTypes from 'prop-types';
import { analyticsPageView } from '../../utils/gtm';

class AnalyticsPageView extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !!nextProps.dep && nextProps.dep !== this.props.dep;
  }

  render() {
    analyticsPageView();
    return null;
  }
}

AnalyticsPageView.propTypes = {
  dep: PropTypes.string,
};

AnalyticsPageView.defaultProps = {
  dep: undefined,
};

export default AnalyticsPageView;
