import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import { Button, FontIcon } from '../Common';
import storage from '../../utils/localStorage';
import './index.css';

class ShareButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: this.getButtons(),
    };
  }

  getButtons() {
    let buttons = [
      { key: 'facebook', icon: 'icon-facebook', color: '#3b5998' },
      { key: 'twitter', icon: 'icon-twitter', color: '#00ccff' },
      { key: 'tumblr', icon: 'icon-tumblr', color: '#395976' },
      { key: 'pinterest', icon: 'icon-pinterest', color: '#bd081c' },
      { key: 'reddit', icon: 'icon-reddit', color: '#FF4500' },
      { key: 'viber', icon: 'icon-viber', color: '#834995' },
      { key: 'telegram', icon: 'icon-telegram', color: '#009cd3' },
      { key: 'whatsapp', icon: 'icon-whatsup', color: '#00c450' },
    ];

    if (this.props.locale === 'ru') {
      buttons.push({ key: 'ok', icon: 'icon-odno', color: '#f7931f' });
      buttons.push({ key: 'vk', icon: 'icon-vkontakte', color: '#2c4762' });
    }

    if (this.props.isMobile) {
      buttons.push(
        { key: 'fb', icon: 'icon-mesenger', color: '#008bff' },
        { key: 'sms', icon: 'icon-sms', color: '#17c077' },
      );
    }

    buttons = this.sortButtons(buttons);
    return buttons;
  }

  setButtonOrder(key) {
    let storageOrders = JSON.parse(storage.getItem('social-buttons-order')) || [];
    const index = storageOrders.indexOf(key);
    if (index > -1) storageOrders = storageOrders.slice(index, 1);
    storageOrders = [key, ...storageOrders];
    storage.setItem('social-buttons-order', JSON.stringify(storageOrders));
  }

  sortButtons(buttons) {
    const storageOrders = JSON.parse(storage.getItem('social-buttons-order')) || [];
    let orders;
    if (this.props.isMobile) {
      orders = this.props.locale !== 'ru'
        ? ['sms', 'facebook', 'twitter', 'pinterest', 'reddit', 'tumblr', 'viber', 'telegram', 'whatsapp', 'fb']
        : ['sms', 'vk', 'facebook', 'twitter', 'pinterest', 'ok', 'reddit', 'tumblr', 'viber', 'telegram', 'whatsapp', 'fb'];
    } else {
      orders = this.props.locale !== 'ru'
        ? ['facebook', 'twitter', 'tumblr', 'pinterest', 'reddit', 'whatsapp', 'viber', 'telegram']
        : ['vk', 'facebook', 'twitter', 'tumblr', 'ok', 'pinterest', 'reddit', 'viber', 'telegram', 'whatsapp'];
    }

    return sortBy(buttons, ({ key }) => {
      const storageOrderIndex = storageOrders.indexOf(key);
      const defaultOrderIndex = orders.indexOf(key);
      return storageOrderIndex > -1
        ? storageOrderIndex
        : (defaultOrderIndex + buttons.length);
    });
  }

  handleClick(type) {
    const encodedPageUrl = encodeURIComponent(this.props.pageUrl);
    const encodedMediaUrl = encodeURIComponent(this.props.mediaUrl);

    let url;
    switch (type) {
      case 'facebook': url = `http://www.facebook.com/sharer/sharer.php?u=${encodedPageUrl}`; break;
      case 'twitter': url = `https://twitter.com/intent/tweet?url=${encodedPageUrl}`; break;
      case 'reddit': url = `http://www.reddit.com/submit?url=${encodedPageUrl}`; break;
      case 'vk': url = `https://vk.com/share.php?url=${encodedPageUrl}`; break;
      case 'pinterest': url = `http://www.pinterest.com/pin/create/link/?url=${encodedPageUrl}&media=${this.props.gifSrc}&description=${this.props.pinterestDescription}`; break;
      case 'tumblr': url = `https://www.tumblr.com/widgets/share/tool/preview?canonicalUrl=${encodedPageUrl}&content=${this.props.gifSrc}&caption=${this.props.tumblrCaption}&posttype=photo&tags=${this.props.tumblrTags}`; break;
      case 'whatsapp':
        url = this.props.isMobile
          ? `whatsapp://send?text=${encodedPageUrl}`
          : `https://api.whatsapp.com/send?text=${encodedPageUrl}`;
        break;
      case 'viber': url = `viber://forward?text=${encodedMediaUrl || encodedPageUrl}`; break;
      case 'telegram':
        url = this.props.isMobile
          ? `tg://msg?text=${encodedPageUrl}`
          : `https://telegram.me/share/url?url=${encodedPageUrl}`;
        break;
      case 'fb': url = `fb-messenger://share?link=${encodedPageUrl}`; break;
      case 'sms': url = `sms:?&body=${encodedPageUrl}`; break;
      case 'ok': url = `https://connect.ok.ru/offer?url=${encodedPageUrl}`; break;
      default: throw new Error(`Unknown social <${type}>`);
    }

    window.open(url, 'share', 'width=650, height=490');
    this.props.onClick(type);
    this.setButtonOrder(type);
  }

  render() {
    return (
      <div className={`share-buttons ${this.props.isMobile ? 'share-buttons_mobile' : ''}`}>
        <ul className="share-buttons__list">
          {this.state.buttons.map(({ icon, key, color }) => (
            <li className="share-buttons__list-item" key={key}>
              <Button
                animateIcon={!this.props.isMobile}
                className={key}
                color={color}
                size="s50"
                onClick={() => this.handleClick(key)}
                icon={<FontIcon name={icon} size="27px" />}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

ShareButtons.propTypes = {
  pageUrl: PropTypes.string,
  mediaUrl: PropTypes.string,
  gifSrc: PropTypes.string,
  pinterestDescription: PropTypes.string,
  tumblrCaption: PropTypes.string,
  tumblrTags: PropTypes.string,
  locale: PropTypes.string,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
};

ShareButtons.defaultProps = {
  pageUrl: '',
  mediaUrl: '',
  gifSrc: '',
  pinterestDescription: '',
  tumblrCaption: '',
  tumblrTags: '',
  locale: 'en',
  isMobile: false,
  onClick: () => { },
};

export default ShareButtons;
