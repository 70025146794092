import { connect } from 'react-redux';
import { closeAlert } from '../../actions/alertMessage';
import AlertProvider from '../../components/AlertProvider';

const mapStateToProps = state => ({
  message: state.alertMessage.message,
  values: state.alertMessage.values,
  type: state.alertMessage.type,
  isOpen: state.alertMessage.isOpen,
});

const mapDispatchToProps = { closeAlert };

export default connect(mapStateToProps, mapDispatchToProps)(AlertProvider);
