import PropTypes from 'prop-types';
import React from 'react';
import { getMediaSrc } from '../../utils/url';
import speed from '../../utils/detectspeed';
import './index.scss';

class Media extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewLoaded: false,
      touchStart: false,
      touchEnd: false,
    };

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.setState({ touchStart: false, touchEnd: false });
    }

    // cancel request for animated preview when "autoPlay" is false (erase src property)
    if (this.props.autoPlay !== nextProps.autoPlay && !nextProps.autoPlay) {
      if (this.animatedPreviewRef) this.animatedPreviewRef.setAttribute('src', '');
      if (this.animatedWebp) this.animatedWebp.setAttribute('srcSet', '');
      if (this.animatedGif) this.animatedGif.setAttribute('srcSet', '');
    }
  }

  componentWillUnmount() {
    // disable all http request for images (erase src property)
    if (this.mediaRef) this.mediaRef.setAttribute('src', '');
    if (this.previewRef) this.previewRef.setAttribute('src', '');
    if (this.animatedPreviewRef) this.animatedPreviewRef.setAttribute('src', '');
    if (this.animatedWebp) this.animatedWebp.setAttribute('srcSet', '');
    if (this.animatedGif) this.animatedGif.setAttribute('srcSet', '');
  }

  getPreviewImage() {
    if (!this.props.file) return null;
    const alt = this.props.alt || this.context.intl.formatMessage(
      { id: this.props.imagePreviewAltId },
      this.props.textValues,
    );
    const options = {};
    if (this.props.main && this.props.clientType === 'google') options.preset = 'vk_play';
    else options.width = 300;

    return (
      <img
        alt={alt}
        itemProp="thumbnail"
        ref={el => (this.previewRef = el)}
        src={getMediaSrc(this.props.server, this.props.file.path, 'gif', options)}
        role="presentation"
      />
    );
  }

  getFullMediaSrc(ext = 'gif') {
    return `${this.props.server}/${this.props.id}.${ext}`;
  }

  getFullMedia() {
    const gifSrc = this.getFullMediaSrc('gif');
    const alt = this.props.alt || this.context.intl.formatMessage({ id: 'imageAlt' }, this.props.textValues);
    const formats = this.props.file.formats || [];

    // for search engines show GIF
    if (typeof window === 'undefined' || !formats.includes('mp4')) {
      return (
        <picture>
          {formats.includes('webp') && <source srcSet={this.getFullMediaSrc('webp')} type="image/webp" />}
          <source srcSet={gifSrc} type="image/gif" />
          <img
            key={gifSrc}
            className="full-media"
            itemProp="contentUrl"
            alt={alt}
            src={gifSrc}
            onLoad={this.props.onLoad}
          />
        </picture>
      );
    }

    // for clients show mp4
    const mp4Src = `${this.props.server}/${this.props.id}.mp4`;
    const poster = getMediaSrc(this.props.server, this.props.file.path, 'gif', { width: 300 });

    return (
      <div>
        <video
          key={mp4Src}
          poster={poster}
          className="full-media"
          loop
          autoPlay
          muted
          playsInline
          onError={this.props.onError}
          onLoadedData={this.props.onLoad}
        >
          <source src={mp4Src} type="video/mp4" />
        </video>
      </div>
    );
  }

  getPreviewMedia() {
    const { formats = [], path } = this.props.file;

    const gifType = this.props.isMobile && formats.includes('mgif') && speed < 8
      ? 'mob'
      : 'w200';

    // show special slow fps gif for mobile devices
    const src = getMediaSrc(this.props.server, `${path}_${gifType}`, 'gif');
    const webpSrc = formats.includes('webp200') ? getMediaSrc(this.props.server, `${path}_w200`, 'webp') : null;
    const alt = this.props.alt || this.context.intl.formatMessage(
      { id: this.props.imagePreviewAltId },
      this.props.textValues,
    );

    return (
      <picture key={src}>
        {webpSrc
          && (
            <source
              srcSet={webpSrc}
              type="image/webp"
              ref={el => (this.animatedWebp = el)}
            />
          )}
        <source
          srcSet={src}
          type="image/gif"
          ref={el => (this.animatedGif = el)}
        />
        <img
          alt={alt}
          className={`preview-media ${this.state.previewLoaded ? 'preview-media_loaded' : ''}`}
          ref={el => (this.animatedPreviewRef = el)}
          onLoad={() => this.setState({ previewLoaded: true })}
          src={src}
          role="presentation"
        />
      </picture>
    );
  }

  handleTouchStart() {
    if (this.timeout) clearTimeout(this.timeout);

    if (this.props.main) {
      this.setState({ touchStart: true, touchEnd: false });
      return;
    }

    this.timeout = setTimeout(() => {
      this.setState({ touchStart: true, touchEnd: false });
    }, 500);
  }

  handleTouchEnd() {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.props.main) {
      this.setState({ touchEnd: true });
    }
  }

  render() {
    if (!this.props.server) return null;

    const biggestSide = this.props.width > this.props.height ? 'width' : 'height';
    const scale = Math.min(
      Math.max(450, this.props[biggestSide]),
      this.props.maxHeight,
    ) / this.props[biggestSide];
    const width = this.props.width * scale;
    const height = this.props.height * scale;

    const style = {
      paddingTop: this.props.paddingTop || `${(height / width) * 100}%`,
    };

    const mediaStyle = {};
    if (this.props.main) mediaStyle.width = width;

    // media list on media page & list page should be different (only in SSR)
    const isSSR = typeof window === 'undefined';
    let showPreviewImage = false;
    let showPreviewMedia = false;
    if (!this.props.main) {
      const isSSROnTagPage = isSSR && !this.props.mainMedia;
      showPreviewMedia = this.props.autoPlay || isSSROnTagPage;
      showPreviewImage = !isSSROnTagPage && !(showPreviewMedia && this.state.previewLoaded);
    }

    return (
      <div
        className={`media ${this.props.isMobile ? 'mobile' : 'desktop'}`}
        style={mediaStyle}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onTouchCancel={this.handleTouchEnd}
        onTouchMove={this.handleTouchEnd}
        onContextMenu={() => this.props.onContextMenu(this.props.id)}
      >
        <div style={style}>
          {this.state.touchStart
            && (
              <img
                className="media-op-image"
                src={`${this.props.server}/${this.props.id}.gif`}
                role="presentation"
                style={{ zIndex: this.state.touchEnd ? 1 : 5 }}
                alt={this.props.alt}
              />
            )}

          <div className="media-container1">
            <div className="media-container2" style={mediaStyle}>
              {showPreviewImage && this.getPreviewImage()}
              {showPreviewMedia && this.getPreviewMedia()}
              {this.props.main && this.getFullMedia()}
              {!showPreviewMedia && this.props.addMetaContentUrl
                && <link itemProp="contentUrl" content={this.getFullMediaSrc('gif')} />}
              {!showPreviewMedia && this.props.dateCreated
                && <meta itemProp="dateCreated" content={this.props.dateCreated} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Media.propTypes = {
  id: PropTypes.string,
  alt: PropTypes.string,
  meta: PropTypes.object, // eslint-disable-line
  textValues: PropTypes.object, // eslint-disable-line
  addMetaContentUrl: PropTypes.bool,
  autoPlay: PropTypes.bool,
  isMobile: PropTypes.bool,
  main: PropTypes.bool, // is main media on page
  mainMedia: PropTypes.string, // id => is relative media to
  server: PropTypes.string,
  file: PropTypes.shape({
    path: PropTypes.string,
    formats: PropTypes.arrayOf(PropTypes.string),
  }),
  width: PropTypes.number,
  height: PropTypes.number,
  dateCreated: PropTypes.string,
  paddingTop: PropTypes.string,
  clientType: PropTypes.string,
  onContextMenu: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  maxHeight: PropTypes.number,
  imagePreviewAltId: PropTypes.string,
};

Media.defaultProps = {
  id: undefined,
  alt: undefined,
  meta: {},
  textValues: {},
  addMetaContentUrl: false,
  autoPlay: false,
  isMobile: false,
  main: false,
  mainMedia: undefined,
  server: undefined,
  file: {},
  width: 100,
  height: 100,
  dateCreated: '',
  paddingTop: undefined,
  clientType: '',
  onContextMenu: () => { },
  onError: () => { },
  onLoad: () => { },
  maxHeight: 500,
  imagePreviewAltId: 'imagePreviewAlt',
};

Media.contextTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line
};

export default Media;
