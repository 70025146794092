import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Meta from '../Meta';
import { getMediaSrc } from '../../utils/url';

const PageEventMeta = (props) => {
  const meta = [];
  const { clientType, server, cover, mp4Src, gifSrc } = props;
  const { file } = cover;

  // generate meta tags for crawlers
  const previewImage = getMediaSrc(server, file.path, 'gif', { width: 300 });

  if (['facebook', 'vk'].includes(clientType)) {
    meta.push({
      property: 'og:image',
      content: getMediaSrc(
        server,
        file.path,
        'gif',
        { preset: `${clientType}_play` },
      ),
    });
  } else if (clientType === 'ok') {
    meta.push({ property: 'og:type', content: 'video.other' });
    meta.push({
      property: 'og:image',
      content: getMediaSrc(
        server,
        file.path,
        'jpeg',
        { preset: 'ok_play' },
      ),
    });
  } else if (clientType === 'telegram') {
    meta.push(
      { property: 'og:image', content: previewImage },
      { property: 'og:type', content: 'video.other' },
      { property: 'og:video', content: mp4Src },
      { property: 'og:video:type', content: 'video/mp4' },
      { property: 'og:video:width', content: file.width },
      { property: 'og:video:height', content: file.height },
      { name: 'twitter:card', content: 'player' },
      { name: 'twitter:player', content: `${server}/embedded/media/${cover.id}` },
      { name: 'twitter:player:stream', content: mp4Src },
      { name: 'twitter:player:stream:content_type', content: 'video/mp4' },
      { name: 'twitter:player:width', content: file.width },
      { name: 'twitter:player:height', content: file.height },
      { name: 'twitter:image', content: previewImage },
    );
  } else if (clientType === 'twitter') {
    const twitterPreviewImage = getMediaSrc(
      server,
      file.path,
      'gif',
      { preset: 'twitter_play' },
    );

    meta.push(
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:image', content: twitterPreviewImage },
    );
  } else {
    meta.push(
      { property: 'og:type', content: 'video.other' },
      { property: 'og:image', content: gifSrc },
      { property: 'og:image:type', content: 'image/gif' },
      { property: 'og:image:width', content: file.width },
      { property: 'og:image:height', content: file.height },
      { property: 'og:video', content: mp4Src },
      { property: 'og:video:secure_url', content: mp4Src },
      { property: 'og:video:type', content: 'video/mp4' },
      { property: 'og:video:width', content: file.width },
      { property: 'og:video:height', content: file.height },
    );
  }

  return <Meta {...props} meta={meta} />;
};

PageEventMeta.propTypes = {
  clientType: PropTypes.string.isRequired,
  cover: PropTypes.objectOf(PropTypes.any),
  server: PropTypes.string,
  mp4Src: PropTypes.string,
  gifSrc: PropTypes.string,
};

PageEventMeta.defaultProps = {
  cover: { file: {} },
  server: '',
  mp4Src: '',
  gifSrc: '',
};

const mapStateToProps = (state, props) => ({
  pageUrl: state.env.url,
  clientType: state.env.clientType,
  server: state.uploadServers.data[props.cover.file.server],
});

export default connect(mapStateToProps)(PageEventMeta);
