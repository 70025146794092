import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { searchRequest } from '../../actions/search';
import { openMenu } from '../../actions/menu';
import SearchDropdown from '../../components/SearchDropdown';

const mapStateToProps = state => ({
  query: state.search.query,
});

const mapDispatchToProps = {
  openMenu,
  searchRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchDropdown));
