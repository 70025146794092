import { connect } from 'react-redux';
import { checkEmailRequest, clearAuthError, socialLoginRequest } from '../../actions/auth';
import PartHome from '../../components/AuthModal/PartHome';

const mapStateToProps = state => ({
  emailChecks: state.auth.emailChecks,
});

const mapDispatchToProps = {
  clearAuthError,
  checkEmail: checkEmailRequest,
  socialLogin: socialLoginRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartHome);
