/*eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom'
import './index.css';

export default () => (
  <div className="page-tos">
    <title>Terms of Service</title>
    <h1>Terms of Service</h1>
    These Terms of Service agreement (collectively with <Link to="/p/privacy">Privacy Policy</Link> , the "Terms") govern your access to and use of gifer.com(the "Site") and all other services, features, content or applications that link to these Terms(collectively with the Site, the "Services").<br />
    Using Services to do anything other than access the links to this Terms page constitutes your consent to these Terms. If you do not consent, do not use our Site.<br />
    <h2>1. Who May Use the Services</h2>
    You must be at least 13 years old to use the Services. If you are under 13, you may not, under any circumstances or for any reason, use the Services.<br />
    <h2>2. Your Account</h2>
    You may need to create an account to use some of our Services. We reserve the right to require that you change your account username.<br />
    You are responsible for safeguarding your account, so use a strong password and limit its use to this account. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above.<br />
    We may need to provide you with certain communications, such as service announcements and administrative messages. These communications are considered part of the Services and your account, and you may not be able to opt-out from receiving them.<br />
    You can choose to delete your account contacting us by clicking "Report" button on any Site page.<br />
    <h2>3. Content You Contribute</h2>
    You retain your rights and you are solely responsible for all of content you contribute to the Services. By uploading a file or other content or by making a comment, you represent and warrant to us that you don't violate or infringe anyone else's rights and you created the file or other content you are uploading, or otherwise have sufficient intellectual property rights to upload the material consistent with these Terms. With regard to any file or content you upload to the public portions of our site, you grant us a non-exclusive, royalty-free, perpetual, irrevocable worldwide license (with sublicense and assignment rights) to use, to display online and in any present or future media, to create derivative works of, to allow downloads of, and/or distribute any such file or content. If you contribute your content through a public part of the Services, you acknowledge that such User Content will be accessible to other users.<br />
    <h2>4. Your License to Use the Services</h2>
    Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to use the Services. The Services may contain copyrighted material (such as text, graphics, photographs, images, and illustrations), trademarks, and other proprietary information and materials of us and our licensors. Except for content that is in the public domain or content that you have permission to use in connection with your use of the Services and in compliance with these Terms, you shall not copy, modify, publish, transmit, distribute, perform, or display any content, not shall you sell, license, rent, or otherwise use or exploit any content for commercial use or in any way that violates any third party right.<br />
    <h2>5. Acceptable Use</h2>
    You may use the Services only in compliance with these Terms and all applicable laws, rules and regulations. We reserve the right to terminate user account that violates these Terms. Keep in mind that we reserve the right to remove any content at any time whether or not it infringes or violates any of the Terms.<br />
    If someone else might own the copyright to any content, don't upload it. Don't upload gore, "hate speech" (i.e. demeaning race, gender, age, religious or sexual orientation, etc.), or material that is threatening, harassing, defamatory, or that encourages violence or crime. Don't upload illegal content such as child porn or nonconsensual ("revenge") porn. Don't hotlink to adult content or to file-sharing, gambling, torrent, warez, or Gifer rip-off sites. Don't impersonate someone else. Also, don't use Gifer to host image libraries you link to from elsewhere, content for your website, advertising, avatars, or anything else that turns us into your content delivery network.<br />
    If you see anything on our site that shouldn't be there because it violates our policies, is illegal, or for some other reason, please let us know by clicking "Report" button on the page contains the violation.<br />
    <h2>6. Disclaimer of Warranties, Limitations of Remedies, Indemnity</h2>
    The Services are provided on an AS IS – WITH ALL FAULTS basis. Your use of the Services is entirely at your own risk. We do not guarantee the availability of our Services at any given time, or the reliability of our Services when it is running. We do not guarantee the integrity of, or the continued availability of, files on our servers. WE DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF FITNESS AND MERCHANTABILITY. NOTWITHSTANDING ANYTHING ELSE STATED IN THESE TERMS, AND IRRESPECTIVE OF WHETHER GIFER TAKES OR DOES NOT TAKE MEASURES TO REMOVE INAPPROPRIATE OR HARMFUL CONTENT FROM THE SITE, WE HAVE NO DUTY TO MONITOR ANY CONTENT ON ITS SITE. WE DO NOT ASSUME RESPONSIBILITY FOR THE ACCURACY, APPROPRIATENESS, OR HARMLESSNESS OF ANY CONTENT APPEARING ON THE SITE THAT IS NOT PRODUCED BY GIFER, INCLUDING BUT NOT LIMITED TO USER CONTENT, ADVERTISING CONTENT, OR OTHERWISE.<br />
    Your sole remedy for the loss of the Services is to discontinue your use of the Services. WE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF YOUR USE OF, OR INABILITY TO USE, SERVICES.<br />
    YOU WILL INDEMNIFY AND HOLD THE SITE AND ALL OF ITS PERSONNEL HARMLESS FROM ALL LOSS, LIABILITY, CLAIMS, DAMAGES AND EXPENSES, INCLUDING REASONABLE ATTORNEY FEES, ARISING OUT OF OR RELATED TO YOUR VIOLATION OF THESE TERMS, YOUR INFRINGEMENT OF ANY THIRD PARTY'S RIGHTS, AND ANY HARM CAUSED TO ANY THIRD PARTY AS A RESULT OF YOUR UPLOADING OF FILES, COMMENTS, OR ANYTHING ELSE TO OUR SERVERS.<br />
    <h2>7. Miscellaneous</h2>
    These Terms contain the entire agreement between you and us regarding the use of the Services. If any part of these Terms is invalid, the remaining provisions will be unaffected. These Terms constitute the entire agreement among the parties relating to this subject matter, and they will continue to govern any issues that arise out of your use of the Services even after you discontinue using them. We may revise these terms from time to time without notice.<br />
    <br />
  </div>
);
