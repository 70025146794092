export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_LOCALE = 'SET_LOCALE';
export const INIT_INTL = 'INIT_INTL';
export const START_FETCH_MESSAGES = 'START_FETCH_MESSAGES';

export const setLocale = locale => ({
  type: SET_LOCALE,
  locale,
});

export const setMessages = (locale, messages) => ({
  type: SET_MESSAGES,
  locale,
  messages,
});

export const startFetchMessages = locale => ({
  type: START_FETCH_MESSAGES,
  locale,
});


export const initIntl = () => ({ type: INIT_INTL });
