import { connect } from 'react-redux';
import UserLinks from '../../components/VerticalMenuMobile/UserLinks';
import AuthModal from '../AuthModal';
import { openModal } from '../../actions/modal';

const mapStateToProps = state => ({
  user: state.auth.myId,
});

const mapDispatchToProps = dispatch => ({
  onLoginClick: () => dispatch(openModal(AuthModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLinks);
