import { takeEvery, call, put, select } from 'redux-saga/effects';
import { getLocale } from '../selectors';
import * as actions from '../../actions/categories';
import { getCategories, getCategoryTags } from '../../utils/api/categories';

function* fetchCategories() {
  try {
    const locale = yield select(getLocale);
    const { result, entities } = yield call(getCategories, locale);
    yield put(actions.fetchCategoriesSuccess(result, entities));
  } catch (err) {
    yield put(actions.fetchCategoriesFailure(err.message));
  }
}

function* fetchCategoryTags(action) {
  try {
    const locale = yield select(getLocale);
    const { result, entities } = yield call(getCategoryTags, action.categoryId, locale);
    yield put(actions.categoryTagsSuccess(action.categoryId, result, entities));
  } catch (err) {
    yield put(actions.categoryTagsFailure(action.categoryId, err));
  }
}

export function* watchFetchCategoriesRequest() {
  yield takeEvery(actions.FETCH_CATEGORIES_REQUEST, fetchCategories);
}

export function* watchFetchCategoryTagsRequest() {
  yield takeEvery(actions.CATEGORY_TAGS_REQUEST, fetchCategoryTags);
}

