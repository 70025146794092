import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, FontIcon } from '../../Common';
import './index.css';

const UserLinks = ({ user, onLoginClick }) => {
  const onClick = user ? undefined : onLoginClick;

  return (
    <div className="vertical-menu-mobile-user-links">
      <Button
        type="default"
        size="s50"
        className="vertical-menu-mobile-user-links__item"
        onClick={onClick}
        href={user ? `/user/${user}/favorites` : undefined}
        icon={(
          <FontIcon
            name="icon-like"
            size="26px"
            color="#EF4A4A"
          />
        )}
        block
      >
        <FormattedMessage id="menu.favorites" />
      </Button>
      <Button
        type="default"
        size="s50"
        className="vertical-menu-mobile-user-links__item"
        onClick={onClick}
        href={user ? `/user/${user}/touches` : undefined}
        icon={(
          <FontIcon
            name="icon-history"
            size="26px"
            color="#6DB5AC"
          />
        )}
        block
      >
        <FormattedMessage id="menu.history" />
      </Button>
    </div>
  );
};

UserLinks.propTypes = {
  user: PropTypes.string,
  onLoginClick: PropTypes.func.isRequired,
};

UserLinks.defaultProps = {
  user: undefined,
};

export default UserLinks;
