import { takeEvery, call, put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getLocale } from '../selectors';
import * as mediaListActions from '../../actions/mediaList';
import * as mediaActions from '../../actions/media';
import * as api from '../../utils/api/media';

function* fetchMediaList(action) {
  try {
    const locale = yield select(getLocale);
    const { result, entities, count } = yield call(
      api.getMediaList,
      { id: action.list, skip: action.skip, limit: action.limit, locale }
    );

    yield put(mediaListActions.fetchMediaListSuccess(
      action.list,
      result,
      entities,
      !!action.skip,
      count
    ));
  } catch (err) {
    yield put(mediaListActions.fetchMediaListFailure(err.message));
  }
}

function* fetchMedia(action) {
  try {
    const locale = yield select(getLocale);
    const { result, entities } = yield call(api.getMedia, action.id, locale);
    yield put(mediaActions.fetchMediaSuccess(result, entities));
  } catch (err) {
    yield put(mediaActions.fetchMediaFailure(err.message));
    yield put(push('/'));
  }
}

function* createMedia(action) {
  try {
    const { result, entities } = yield call(api.createMedia, action.media);
    yield put(mediaActions.createMediaSuccess(result, entities));
    yield put(mediaActions.addMediaToList(
      `user/${entities.media[result].creator || 0}/uploaded`,
      result
    ));
    yield put(push(`/${result}`));
  } catch (error) {
    yield put(mediaActions.createMediaFailure(error.message));
  }
}

function* incStat(action) {
  try {
    const locale = yield select(getLocale);
    yield call(api.incStat, action.data, locale);
  } catch (error) {
    yield put(mediaActions.incMediaStatFailure(error));
  }
}

export function* watchFetchMediaListRequest() {
  yield takeEvery(mediaListActions.FETCH_MEDIA_LIST_REQUEST, fetchMediaList);
}

export function* watchFetchMediaRequest() {
  yield takeEvery(mediaActions.FETCH_MEDIA_REQUEST, fetchMedia);
}

export function* watchCreateMediaRequest() {
  yield takeEvery(mediaActions.CREATE_MEDIA_REQUEST, createMedia);
}

export function* watchIncMediaStat() {
  yield takeEvery(mediaActions.INC_MEDIA_STAT, incStat);
}
