import { normalize } from 'normalizr';

const BASE_URL = process.env.REACT_APP_GATEWAY_API;

async function callApi(endpoint, opts = {}) {
  const fullUrl = `${BASE_URL}/${endpoint}`;
  const reqOptions = {
    credentials: 'include',
    headers: {
      ab: 'b',
      'Content-Type': 'application/json',
      'Accept-Language': opts.locale || '*', // send user locale
    },
    ...opts,
  };
  if (opts.data) {
    reqOptions.body = JSON.stringify(opts.data);
  }
  const response = await fetch(fullUrl, reqOptions);

  let json = (response.status === 204)
    ? {}
    : await response.json();

  if (response.ok === false) throw json.message;

  if (opts.normalize && json !== null) json = normalize(json, opts.normalize);

  // push count header to result
  const count = response.headers.get('X-Count');
  if (count) json.count = Number(count);

  return json;
}

export default callApi;
