export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';

export const fetchEventsRequest = () => ({
  type: FETCH_EVENTS_REQUEST,
});

export const fetchEventsSuccess = data => ({
  type: FETCH_EVENTS_SUCCESS,
  data,
});

export const fetchEventsFailure = error => ({
  type: FETCH_EVENTS_FAILURE,
  error,
});

export const fetchEventRequest = slug => ({
  type: FETCH_EVENT_REQUEST,
  slug,
});

export const fetchEventSuccess = data => ({
  type: FETCH_EVENT_SUCCESS,
  data,
});

export const fetchEventFailure = error => ({
  type: FETCH_EVENT_FAILURE,
  error,
});
