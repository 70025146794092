import PropTypes from 'prop-types';
import React from 'react';
import { Masonry } from '../../Common';
import MediaThumb from '../../../containers/Media/MediaThumb';

class MediaMasonry extends React.Component {
  handleRenderItem = (index) => {
    const id = this.props.mediaList[index];
    return (
      <MediaThumb
        imagePreviewAltId={this.props.imagePreviewAltId}
        mainTag={this.props.query}
        itemProp={this.props.itemProp}
        mainMedia={this.props.mainMedia}
        id={id}
        onClick={this.props.onClick}
        onContextMenu={this.props.onContextMenu}
      />
    );
  }

  handleCellHeight = (index, cellWidth) => {
    const id = this.props.mediaList[index];
    const media = this.props.mediaEntities[id];
    const height = (cellWidth / media.width) * media.height;
    return Math.min(500, height);
  }

  render() {
    return (
      <Masonry
        gutter={this.props.gutter}
        offset={this.props.offset}
        columnCount={this.props.columnCount}
        data={this.props.mediaList}
        render={this.handleRenderItem}
        cellHeight={this.handleCellHeight}
        onInfiniteLoad={this.props.onInfiniteLoad}
        isInfiniteLoading={this.props.isLoading}
        onVisibleChange={this.props.onVisibleChange}
      />
    );
  }
}

MediaMasonry.propTypes = {
  columnCount: PropTypes.number,
  query: PropTypes.string,
  itemProp: PropTypes.string,
  mainMedia: PropTypes.string,
  gutter: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  mediaList: PropTypes.arrayOf(PropTypes.string).isRequired,
  mediaEntities: PropTypes.object.isRequired, // eslint-disable-line
  onInfiniteLoad: PropTypes.func,
  onVisibleChange: PropTypes.func,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  imagePreviewAltId: PropTypes.string,
  offset: PropTypes.number,
};

MediaMasonry.defaultProps = {
  imagePreviewAltId: undefined,
  offset: 1000,
  columnCount: 4,
  query: undefined,
  itemProp: undefined,
  mainMedia: undefined,
  onInfiniteLoad: undefined,
  onVisibleChange: undefined,
  onClick: undefined,
  onContextMenu: undefined,
};

export default MediaMasonry;
