/* eslint-disable import/extensions */
/* eslint-disable import/no-webpack-loader-syntax */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import EventEmitter from 'eventemitter3';
import { WorkerCommands, WorkerEvents } from '../helpers/ffmpegEvents';

// interface FFmpegContextType {
//   /**
//    * Flag that indicates whether ffmpeg is loaded
//    */
//   isLoaded: boolean;
//   /**
//    * Flag that indicates whether ffmpeg is doing some work right now
//    */
//   working: boolean;
//   /**
//    * Event emitter to subscribe to ffmpeg events
//    */
//   emitter: EventEmitter<WorkerEvents>;
//   /**
//    * Write source file to ffmpeg FS
//    */
//   writeSourceFile: (file: File) => void;
//   /**
//    * Read source file from ffmpeg FS
//    */
//   readSourceFile: () => void;
//   /**
//    * Read destination file from ffmpeg FS
//    */
//   readDestinationFile: () => void;
//   /**
//    * Cut video file from/to specific position
//    */
//   cutSourceFile: (startPosition: number, endPosition: number) => void;
// }

export const FFmpegContext = createContext({
  working: false,
  isLoaded: false,
  emitter: new EventEmitter(),
  writeSourceFile: () => {},
  readSourceFile: () => {},
  readDestinationFile: () => {},
  cutSourceFile: () => {},
});

// Tricky worker loader to avoid loading in server side rendering
let worker;
(async () => {
  if (typeof window !== 'undefined') {
    // Hack to load web worker in production from cdn domain (because of restrictions browser can't load webworker directly from another domain)
    import('worker-loader?inline=fallback!../webWorker/index.js').then((result) => {
      worker = new result.default();
    });
    // if (process.env.NODE_ENV === 'production') {
    //   const files = await fetch(`/asset-manifest.json`)
    //     .then(res => res.json())
    //     .then(manifest => manifest.files);
    //   const workerPath = Object.entries(files).find(([key]) => /\.worker\.js/.test(key));
    //   console.log(workerPath);
    //   if (workerPath) {
    //     const workerUrl = workerPath[1];
    //     const workerBlob = new Blob([
    //       'importScripts(' + JSON.stringify(workerUrl) + ')',
    //     ], {type: 'application/javascript'});
    //     const blobUrl = window.URL.createObjectURL(workerBlob);
    //     worker = new Worker(blobUrl);
    //   }
    // } else {
    //   // Loading normally in dev mode
    //   // worker = new Worker(new URL('../webWorker/index.js', import.meta.url));
    // }
  }
})();

export const FFmpegProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [working, setWorking] = useState(false);
  const emitter = useMemo(() => new EventEmitter(), []);

  useEffect(() => {
    if (worker) {
      worker.addEventListener('message', ({ data }) => {
        console.log(data, 'main');
        // Emit incoming events to subscribers
        emitter.emit(data.event, data);

        // Process incoming events for internal use
        switch (data.event) {
          case WorkerEvents.LOADED: {
            setIsLoaded(true);
            break;
          }
          case WorkerEvents.WORKING: {
            setWorking(true);
            break;
          }
          case WorkerEvents.IDLE: {
            setWorking(false);
            break;
          }
          default: console.log('unknown event');
        }
      });
    }

    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, [emitter]);

  const writeSourceFile = useCallback(
    (file) => {
      if (!worker) return;
      worker.postMessage({ event: WorkerCommands.WRITE_SOURCE_FILE, file });
    },
    [],
  );
  // Read source file from ffmpeg FS
  const readSourceFile = useCallback(() => {
    if (!worker) return;
    worker.postMessage({ event: WorkerCommands.READ_SOURCE_FILE });
  }, []);

  // Read dest file from ffmpeg FS
  const readDestinationFile = useCallback(() => {
    if (!worker) return;
    worker.postMessage({ event: WorkerCommands.READ_DEST_FILE });
  }, []);

  // Cut video file from/to specific position
  const cutSourceFile = useCallback(
    (start, end) => {
      if (!worker) return;
      worker.postMessage({ event: WorkerCommands.CUT_SOURCE_FILE, start, end });
    },
    [],
  );

  return (
    <FFmpegContext.Provider
      value={{
        isLoaded,
        working,
        emitter,
        writeSourceFile,
        readSourceFile,
        readDestinationFile,
        cutSourceFile,
      }}
    >
      {children}
    </FFmpegContext.Provider>
  );
};

export const useFFmpeg = () => useContext(FFmpegContext);
