import { getTags } from './get-tags';

const cache = {
  listId: undefined,
  isLoaded: false,
  result: [],
};

export const getSmartTags = (listId, exclude, state) => {
  if (cache.id === listId && cache.isLoaded) {
    return cache.result;
  }

  const { ids = [], isLoaded } = state.media.lists[listId] || {};

  const tags = getTags(ids, exclude, state);
  const result = {
    isMobile: state.env.isMobile,
    tags,
    listId,
    isLoaded,
  };

  cache.id = listId;
  cache.isLoaded = isLoaded;
  cache.result = result;

  return result;
};
