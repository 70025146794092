export default (state = {}, action) => {
  if (action.entities && action.entities.users) {
    // eslint-disable-next-line
    return {
      ...state,
      ...action.entities.users,
    };
  }
  return state;
};
