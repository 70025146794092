import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../Modal';
import Button from '../../../Common/Button';
import Success from './Success';
import fetch from '../../../../utils/api';
import './index.css';

class PopupBlame extends React.Component {
  constructor(props) {
    super(props);
    this.formEl = React.createRef();
    this.state = { isSent: false };
  }

  handleClickSend = (event) => {
    const isFormValid = this.formEl.current.checkValidity();
    if (isFormValid) {
      event.preventDefault();
      this.setState({ isSent: true });
      const { name, phone, address, email, text } = this.formEl.current.elements;

      fetch('api/feedbacks', {
        method: 'POST',
        body: JSON.stringify({
          type: 'ABUSE',
          name: name.value,
          phone: phone.value,
          address: address.value,
          email: email.value,
          media: this.props.mediaId,
          ref: window.location.href,
          text: text.value,
        }),
      });
    }
  };

  renderForm() {
    return (
      <form
        className="page-media-popup-blame"
        onSubmit={this.handleClickSend}
        ref={this.formEl}
      >
        <h1 className="page-media-popup-blame__h1">
          <FormattedMessage id="page-media.popup-blame__title" />
        </h1>

        <div className="page-media-popup-blame__row">
          <input
            required
            name="name"
            type="text"
            className="page-media-popup-blame__input"
            placeholder={this.context.intl.formatMessage({ id: 'page-media.popup-blame__name' })}
          />
        </div>

        <div className="page-media-popup-blame__row">
          <input
            required
            name="phone"
            type="phone"
            className="page-media-popup-blame__input"
            placeholder={this.context.intl.formatMessage({ id: 'page-media.popup-blame__phone' })}
          />
        </div>

        <div className="page-media-popup-blame__row">
          <input
            required
            name="address"
            type="text"
            className="page-media-popup-blame__input"
            placeholder={this.context.intl.formatMessage({ id: 'page-media.popup-blame__address' })}
          />
        </div>

        <div className="page-media-popup-blame__row">
          <input
            required
            name="email"
            type="email"
            className="page-media-popup-blame__input"
            placeholder={this.context.intl.formatMessage({ id: 'page-media.popup-blame__email' })}
          />
        </div>

        <div className="page-media-popup-blame__row page-media-popup-blame__row_2x">
          <textarea
            required
            name="text"
            className="page-media-popup-blame__textarea"
            placeholder={this.context.intl.formatMessage({ id: 'page-media.popup-blame__message' })}
          />
        </div>

        <div className="page-media-popup-blame__row page-media-popup-blame__row_2x">
          <div className="page-media-popup-blame__checkbox-container">
            <input className="page-media-popup-blame__checkbox" type="checkbox" name="foo" value="baz" id="confirmation" required />
            <label htmlFor="confirmation"> {/*eslint-disable-line*/}
              <FormattedMessage id="page-media.popup-blame__confirmation" />
            </label>
          </div>
        </div>

        <div className="page-media-popup-blame__row">
          <Button type="gradient" onClick={this.handleClickSend} block>
            <FormattedMessage id="page-media.popup-blame__send" />
          </Button>
        </div>
      </form>
    );
  }

  renderSuccess() {
    return (
      <Success handleClickClose={this.props.closeModal} />
    );
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal}>
        {this.state.isSent
          ? this.renderSuccess()
          : this.renderForm()
        }
      </Modal>
    );
  }
}

PopupBlame.contextTypes = {
  intl: PropTypes.objectOf(PropTypes.any),
};

PopupBlame.propTypes = {
  mediaId: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
};

PopupBlame.defaultProps = {
  closeModal: () => { },
};

export default PopupBlame;
