import PropTypes from 'prop-types';
import React from 'react';
import ShareButtons from '../../ShareButtons';
import './index.css';

const SocialButtons = (props, context) => {
  const pageUrl = `${process.env.REACT_APP_DOMAIN}/${props.locale}/${props.id}`;
  const mediaUrl = `https://i.gifer.com/${props.id}.gif`;
  const textValues = {
    ...props.textValues,
    url: pageUrl,
  };

  return (
    <div className={`page-media-social-block ${props.isMobile ? 'page-media-social-block_mobile' : ''} `}>
      <ShareButtons
        pageUrl={pageUrl}
        mediaUrl={mediaUrl}
        pinterestDescription={context.intl.formatMessage({ id: 'page_media.share_pinterest_caption' }, textValues)}
        tumblrCaption={encodeURIComponent(context.intl.formatMessage({ id: 'page_media.share_tumblr_caption' }, textValues))}
        tumblrTags={context.intl.formatMessage({ id: 'page_media.share_tags' }, textValues)}
        {...props}
      />
    </div>
  );
};

SocialButtons.contextTypes = {
  intl: PropTypes.objectOf(PropTypes.any),
};

SocialButtons.propTypes = {
  id: PropTypes.string,
  locale: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

SocialButtons.defaultProps = {
  id: '',
  isMobile: false,
};

export default SocialButtons;
