import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventsPanel from '../../components/EventsPanel';
import { fetchEventsRequest } from '../../actions/events';

class EventsPanelContainer extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.isLoaded === false) {
      this.props.fetchEventsRequest();
    }
  }

  render() {
    return <EventsPanel {...this.props} />;
  }
}

EventsPanelContainer.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  fetchEventsRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ...state.events,
});

const mapDispatchToProps = {
  fetchEventsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsPanelContainer);
