import { connect } from 'react-redux';
import PartUploaded from '../../components/PageUser/PartUploaded';

const mapStateToProps = (state, props) => {
  const showProgress = props.match.params.id === state.auth.myId
    && state.upload.count > 0;

  const progressData = {};
  if (showProgress) {
    progressData.value = Object.values(state.upload.progress)
      .reduce((res, val) => res + val, 0);
    progressData.value /= state.upload.count;
    progressData.parts = state.upload.count;
    progressData.done = Object.values(state.upload.progress)
      .reduce((res, val) => (val === 100 ? res + 1 : res), 0);
  }

  return {
    showProgress: showProgress && (progressData.value < 100),
    ...state.users.fullEntities[props.match.params.id],
    ...progressData,
  };
};

export default connect(mapStateToProps)(PartUploaded);
