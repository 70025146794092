import { LOCATION_CHANGE } from 'react-router-redux';
import * as actions from '../../actions/search';
import { tagFromURI } from '../../utils/tagToURI';

const initialState = {
  matches: [],
  error: null,
  query: '',
  isLoading: false,
  isLoaded: false,
  isOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      let query = '';
      let matches = [];

      const [, tag] = action.payload.pathname.match(/\/(?:gifs|s)\/(.*)/) || [];
      if (tag) {
        query = tagFromURI(tag);
        const doSaveMatches = tagFromURI(state.query) === query;
        matches = doSaveMatches ? state.matches : [];
      }

      const isMediaPage = action.payload.pathname.search(/^\/(\w+)$/) === 0;
      if (isMediaPage) {
        query = state.query; // eslint-disable-line
        matches = state.matches; // eslint-disable-line
      }

      return {
        ...state,
        isOpen: false,
        matches,
        query,
      };
    }
    case actions.CLEAR_SEARCH:
      return {
        ...state,
        query: '',
        isOpen: false,
      };
    case actions.OPEN_SEARCH:
      return {
        ...state,
        isOpen: true,
      };
    case actions.CLOSE_SEARCH:
      return {
        ...state,
        isOpen: false,
      };
    case actions.SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        isLoaded: false,
        isOpen: true,
        query: action.query,
      };
    case actions.SEARCH_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        matches: [],
      };
    case actions.SEARCH_SUCCESS:
      return {
        ...state,
        matches: action.matches,
        error: null,
        isLoading: false,
        isLoaded: true,
      };
    default: return state;
  }
};
