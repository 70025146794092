import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Input from './Input';
import SearchResults from '../../containers/SearchResults';
import { tagToURI } from '../../utils/tagToURI';
import gtm from '../../utils/gtm';
import './index.css';

const initialState = {
  query: undefined,
  queryKeyboard: undefined,
  focus: false,
};

class SearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleClickOverlay = this.handleClickOverlay.bind(this);
    this.state = initialState;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) { // eslint-disable-line
      this.setState(initialState);
    }
  }

  handleClick(event) {
    const inside = this.container.contains(event.target);
    if (inside === false) {
      this.closeDropdown();
    }
  }

  handleChange(query) {
    this.props.searchRequest(query);
    this.setState({
      query,
      queryKeyboard: undefined,
      focus: true,
    });
  }

  handleFocus() {
    this.openDropdown();
  }

  handleSubmit() {
    const query = this.state.queryKeyboard || this.state.query;
    if (!query) return;
    gtm({ event: 'enter-search' });
    this.props.history.push(`/gifs/${tagToURI(query)}`);
  }

  openDropdown() {
    this.setState({ focus: true });
    if (typeof window === 'undefined') return;
    window.addEventListener('click', this.handleClick);
  }

  closeDropdown() {
    this.setState({ focus: false });
    if (typeof window === 'undefined') return;
    window.removeEventListener('click', this.handleClick);
  }

  handleClickOverlay(event) {
    this.input.blur();
    this.closeDropdown();
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  render() {
    const query = this.state.queryKeyboard || this.state.query || this.props.query || '';

    const classes = [
      'search-dropdown',
      [this.state.focus ? 'search-dropdown_focus' : ''],
      [this.props.isMobile ? 'search-dropdown_mobile' : 'search-dropdown_desktop'],
    ].join(' ');

    return (
      <div className={classes} ref={el => (this.container = el)}>
        {this.props.showOverlay && this.state.focus && (
          <div
            className="search-dropdown__overlay"
            onTouchMove={this.handleClickOverlay}
            onClick={this.handleClickOverlay}
          />
        )}

        <Input
          {...this.props}
          inputRef={el => (this.input = el)}
          key={`search-dropdown-input-${this.props.location.pathname}`} // eslint-disable-line
          isMobile={this.props.isMobile}
          query={query}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onSubmit={this.handleSubmit}
          showMenuButton={this.props.isMobile && !this.state.focus && this.props.showMenuButton}
        />

        {query && this.state.focus && (
          <div className="search-dropdown__results">
            <SearchResults
              onKeyboardSelect={(i, item) => this.setState({ queryKeyboard: item })}
            />
          </div>
        )}
      </div>
    );
  }
}

SearchDropdown.propTypes = {
  isMobile: PropTypes.bool,
  showOverlay: PropTypes.bool,
  searchRequest: PropTypes.func.isRequired,
  showMenuButton: PropTypes.bool,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  query: PropTypes.string,
};

SearchDropdown.defaultProps = {
  isMobile: false,
  showOverlay: false,
  showMenuButton: false,
  query: undefined,
};

export default SearchDropdown;
