import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.css';

// todo: apply the tooltip throughout the project.
class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.tooltip = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    if (!this.tooltip.current.contains(event.target)) {
      this.props.closeRequest();
    }
  }

  render() {
    const { closeRequest, ...rest } = this.props;
    return (
      <div className="tooltip" ref={this.tooltip} {...rest} />
    );
  }
}

Tooltip.propTypes = {
  closeRequest: PropTypes.func,
};

Tooltip.defaultProps = {
  closeRequest: () => { },
};

export default Tooltip;
