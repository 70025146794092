import React from 'react';
import DeviceLayout from '../../containers/DeviceLayout';
import Mobile from './Layouts/Mobile';
import Desktop from './Layouts/Desktop';

const HorizontalMenu = props =>
  <DeviceLayout
    mobile={<Mobile {...props} />}
    desktop={<Desktop {...props} />}
  />;

export default HorizontalMenu;
