import PropTypes from 'prop-types';

const DeviceLayout = props => (props.isMobile ? props.mobile : props.desktop);

DeviceLayout.propTypes = {
  isMobile: PropTypes.bool,
  mobile: PropTypes.node.isRequired,
  desktop: PropTypes.node.isRequired,
};

DeviceLayout.defaultProps = {
  isMobile: false,
};

export default DeviceLayout;
