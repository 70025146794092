import * as actions from '../../actions/mediaList';
import { ADD_MEDIA_TO_LIST } from '../../actions/media';

export default (state = {}, action) => {
  switch (action.type) {
    case actions.FETCH_MEDIA_LIST_REQUEST:
      return {
        ...state,
        [action.list]: {
          isLoading: true,
          isLoaded: action.skip && state[action.list] ? state[action.list].isLoaded : false,
          ids: action.skip && state[action.list] ? state[action.list].ids : [],
        },
      };
    case actions.FETCH_MEDIA_LIST_SUCCESS:
      return {
        ...state,
        [action.list]: {
          isLoading: false,
          isLoaded: true,
          count: action.count,
          ids: action.append ? [...state[action.list].ids, ...action.ids] : action.ids,
        },
      };
    case actions.FETCH_MEDIA_LIST_FAILURE:
      return {
        ...state,
        [action.list]: {
          isLoading: false,
        },
      };
    case ADD_MEDIA_TO_LIST:
      return {
        ...state,
        [action.list]: {
          ...state[action.list],
          ids: state[action.list] ? [
            action.id,
            ...state[action.list].ids,
          ] : [action.id],
        },
      };
    default: return state;
  }
};
