import request from 'superagent';
import store from '../../../store';
import { getMediaSrc } from '../../url';
import { fileUploadProgress } from '../../../actions/upload';

const BASE_URL = process.env.REACT_APP_MAIN_UPLOAD_SERVER;

async function isImageExists(url) {
  return new Promise((resolve) => {
    const im = new Image();
    im.src = url;
    im.onload = () => resolve(true);
    im.onerror = () => resolve(false);
  });
}

async function sleep(timeMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs);
  });
}

async function waitFileAppear(url) {
  let retries = 0;
  while (true) {
    retries += 1;
    // eslint-disable-next-line no-await-in-loop
    const isExist = await isImageExists(url);
    if (isExist || retries > 12) {
      return true;
    }

    // eslint-disable-next-line no-await-in-loop
    await sleep(4000);
  }
}

export async function uploadFile(id, file, uploadServers) {
  const req = request.post(`${BASE_URL}/upload`);
  req.attach('files', file);
  req.on('progress', (event) => {
    if (event.direction === 'upload') {
      store.dispatch(fileUploadProgress(id, event.percent));
    }
  });

  const result = await req.then();
  const uploadedFile = result.body[0];
  return uploadedFile;
}

export async function uploadByUrl(id, url, uploadServers) {
  const req = request.post(`${BASE_URL}/upload`);
  req.field('url', url);
  const result = await req.then();
  const uploadedFile = result.body[0];
  const server = uploadServers[uploadedFile.server];
  await waitFileAppear(getMediaSrc(server, `${uploadedFile.path}_w200`, 'gif'));
  return uploadedFile;
}
