import React from 'react';

const Layout = ({ children }) => (
  <div className="page-marker">
    <div className="page-wrap">
      {children}
    </div>
  </div>
);

export default Layout;
