import { getUserLocale } from './persistUserLocale';

const locales = process.env.REACT_APP_LOCALES.split(';');

const getBrowserLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  }
  if (navigator.userLanguage) return navigator.userLanguage;
  return navigator.language;
};

// get current locale from url
export default () => {
  let [, locale, path] = /^\/(\w{2})(\/.*|)$/.exec(window.location.pathname) || [];
  if (!path) path = window.location.pathname;

  if (!locales.includes(locale)) {
    locale = getUserLocale() || getBrowserLanguage().slice(0, 2);
    if (!locales.includes(locale)) locale = 'en';
    window.history.replaceState(undefined, undefined, `/${locale}${path === '/' ? '' : path}`);
  }

  return locale;
};
