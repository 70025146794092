import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import './index.css';

const Controls = props =>
  <div className="page-user-controls">
    <NavLink
      to={`/user/${props.user}/touches`}
      className="page-user-controls__item"
      activeClassName="page-user-controls__item_active"
      exact
    >
      <FormattedMessage id="page_user.link_to_touches" />
    </NavLink>
    <NavLink
      to={`/user/${props.user}/favorites`}
      className="page-user-controls__item"
      activeClassName="page-user-controls__item_active"
      exact
    >
      <FormattedMessage id="page_user.link_to_favorites" />
    </NavLink>
    <NavLink
      to={`/user/${props.user}`}
      className="page-user-controls__item"
      activeClassName="page-user-controls__item_active"
      exact
    >
      <FormattedMessage id="page_user.link_to_uploaded" />
    </NavLink>
  </div>;

Controls.propTypes = {
  user: PropTypes.string,
};

export default Controls;
