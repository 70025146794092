import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CarouselMobile from './Layouts/Mobile';
import CarouselDesktop from './Layouts/Desktop';
import Stub from './Stub';
import './index.css';

const colors = ['#C65656', '#C6A056', '#56C65A', '#56C6AB', '#56B2C6', '#5668C6', '#BD56C6'];
class EventsPanel extends React.Component {
  constructor(props) {
    super(props);
    const [slideWidth, slidesToShow] = this.getSlideWidth();

    this.state = {
      slideWidth,
      slidesToShow,
      initialSlideWidth: 300,
      initialSlideHeight: 200,
    };
  }

  componentDidMount() {
    const { slidesToShow, slideWidth } = this.state;
    const containerWidth = this.containerEl.offsetWidth;
    const initialSlideWidth = (containerWidth / slidesToShow) * slideWidth - 20;
    const initialSlideHeight = initialSlideWidth * 0.54;
    this.setState({ initialSlideWidth, initialSlideHeight });
  }

  getSlideWidth() {
    let slidesToShow = 1;
    let slideWidth = 0.95;
    if (typeof window !== 'undefined') {
      if (window.innerWidth >= 1366) {
        slidesToShow = 2;
        slideWidth = 0.85;
      } else if (window.innerWidth >= 768) {
        slidesToShow = 2;
        slideWidth = 0.9;
      } else if (window.innerWidth >= 480) {
        slideWidth = 0.65;
      } else {
        slideWidth = 0.75;
      }
    }

    return [slideWidth, slidesToShow];
  }

  render() {
    const CarouselComponent = this.props.isMobile ? CarouselMobile : CarouselDesktop;

    return (
      <div className="events-panel" ref={el => (this.containerEl = el)}>
        <h2 className="events-panel__title">
          <FormattedMessage id="page-home.title-events" />
        </h2>

        <div className="events-panel__carousel" style={{ height: this.state.initialSlideHeight }}>
          {!this.props.isLoaded && (
            <Stub
              isMobile={this.props.isMobile}
              itemWidth={this.state.slideWidth}
              itemsCount={this.state.slidesToShow}
            />
          )}
          {this.props.isLoaded && (
            <CarouselComponent
              data={this.props.data}
              colors={colors}
              initialSlideWidth={this.state.initialSlideWidth}
              initialSlideHeight={this.state.initialSlideHeight}
              slidesToShow={this.state.slidesToShow}
              slideWidth={this.state.slideWidth}
            />
          )}
        </div>
      </div>
    );
  }
}

EventsPanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
  isLoaded: PropTypes.bool,
};

EventsPanel.defaultProps = {
  data: [],
  isMobile: false,
  isLoaded: false,
};

export default EventsPanel;
