import { connect } from 'react-redux';
import { logoutRequest } from '../../actions/auth';
import { openModal } from '../../actions/modal';
import UserPanel from '../../components/UserPanel';
import AuthModal from '../AuthModal/index';

const mapStateToProps = state => ({
  isAuth: !!state.auth.myId,
  id: state.auth.myId,
  locale: state.intl.locale,
  locales: state.intl.locales,
  username: state.auth.myId ? state.users.entities[state.auth.myId].username : null,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutRequest()),
  onLoginClick: () => dispatch(openModal(AuthModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
