import * as actions from '../../actions/uploadServers';

export const initialState = {
  isLoading: false,
  isLoaded: false,
  data: null,
  error: null,
};

const servers = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_UPLOAD_SERVERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null,
        data: null,
      };

    case actions.FETCH_UPLOAD_SERVERS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isLoaded: true,
        error: null,
      };

    case actions.FETCH_UPLOAD_SERVERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default servers;
