import React, { Component } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import PageSmile from '../../components/PageSmile';
import { fetchSmileRequest } from '../../actions/smiles';

class PageSmileContainer extends Component {
  componentDidUpdate(prevProps) {
    const { isLoaded, smilesIsLoaded, slug } = this.props;
    const smilesWasLoaded = !prevProps.smilesIsLoaded && smilesIsLoaded;
    if (smilesWasLoaded && isLoaded === false) {
      this.props.fetchSmileRequest(slug);
    }
  }

  render() {
    return <PageSmile {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  const { slug } = props.match.params;
  const smile = state.smiles.data.find(item => item.slug === slug);
  const listId = smile ? `smile/${smile.id}/${smile.slug}/${smile.statQuery}` : undefined;
  const [mediaId] = get(state.media.lists, [listId, 'ids'], []);
  const media = state.media.entities[mediaId];
  const server = media && media.file && state.uploadServers.data[media.file.server];

  return {
    ...smile,
    smilesIsLoaded: state.smiles.isLoaded,
    slug,
    listId,
    isLoaded: !!smile,
    isMobile: state.env.isMobile,
    locale: state.intl.locale,
    media,
    server,
  };
};

const mapDispatchToProps = {
  fetchSmileRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSmileContainer);
