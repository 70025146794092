import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import SearchHints from '../../components/PageHome/SearchHints';
import { fetchSearchHintsRequest } from '../../actions/searchHints';

class SearchHintsContainer extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.fetchSearchHints();
  }

  render() {
    return <SearchHints {...this.props} />;
  }
}

SearchHintsContainer.propTypes = {
  fetchSearchHints: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  hints: state.searchHints.hints,
  isLoaded: state.searchHints.isLoaded,
  isMobile: state.env.isMobile,
});

const mapDispatchToProps = {
  fetchSearchHints: fetchSearchHintsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHintsContainer);
