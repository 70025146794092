import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './index.css';

const LinearProgress = (props) => {
  let parts = null;
  if (props.parts) {
    parts = <span className="parts">{`${props.done}/${props.parts}`}</span>;
  }

  return (
    <div className="linear-progress">
      <div className="value" style={{ width: `${props.value}%` }} />
      <div className="text">
        <FormattedMessage id="upload.progress_text" />
        {parts}
      </div>
    </div>
  );
};

LinearProgress.propTypes = {
  value: PropTypes.number.isRequired,
  parts: PropTypes.number,
  done: PropTypes.number,
};

export default LinearProgress;
