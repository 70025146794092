import { connect } from 'react-redux';
import Media from '../../components/Media';
import mediaTextValues from '../../utils/mediaTextValues';

const mapStateTopProps = (state, props) => {
  const media = state.media.entities[props.id];
  if (!media) return {};

  const creator = state.users.entities[media.creator];
  let dateCreated = media.date;
  if (dateCreated) dateCreated = (new Date(dateCreated)).toISOString();

  return {
    ...media,
    textValues: props.textValues || mediaTextValues({ media, creator }),
    dateCreated,
    server: media.file && state.uploadServers.data[media.file.server],
    autoPlay: props.autoPlay || state.autoPlay,
    isMobile: state.env.isMobile,
    clientType: state.env.clientType,
  };
};

export default connect(mapStateTopProps)(Media);
