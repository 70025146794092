import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import MediaThumb from '../../Media/MediaThumb';
import TagThumbTitle from '../TagThumbTitle';
import { tagToURI } from '../../../utils/tagToURI';

const TagThumb = props =>
  <MediaThumb
    showActions={false}
    isMobile={props.isMobile}
    href={`/gifs/${tagToURI(props.id)}`}
    paddingTop="60%"
    id={props.cover}
    title={<TagThumbTitle isMobile={props.isMobile} type={props.type}>{props.id}</TagThumbTitle>}
    alt={props.intl.formatMessage({ id: 'tag_image_alt' }, { name: props.id, category: props.category })}
  />;

TagThumb.propTypes = {
  id: PropTypes.string,
  category: PropTypes.string,
  cover: PropTypes.string,
  type: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  intl: intlShape,
};

export default injectIntl(TagThumb);
