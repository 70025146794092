export default (state = {}, action) => {
  if (action.entities && action.entities.tags) {
    return {
      ...state,
      ...action.entities.tags,
    };
  }

  return state;
};
