import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import pull from 'lodash/pull';
import difference from 'lodash/difference';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PageSearch from '../../components/PageSearch';
import { tagFromURI } from '../../utils/tagToURI';
import { getSmartTags } from '../SmartTags/getSmartTags';
import { fetchFullTagRequest } from '../../actions/fullTags';

class PageTag extends Component {
  UNSAFE_componentWillMount() {
    this.checkTagExisting(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.checkTagExisting(nextProps);
    }
  }

  async checkTagExisting(props) {
    if (!props.isLoaded) {
      props.fetchFullTagRequest(tagFromURI(props.match.params.id));
    }
  }

  render() {
    const { id } = this.props.match.params;

    if (!this.props.fullTag) {
      return <Redirect to={`/s/${id}`} />;
    }

    const tagLocales = this.props.fullTag ? difference(this.props.fullTag.locales, pull(this.props.fullTag._unseen, 'en')) : [];

    return <PageSearch {...this.props} showHreflang={tagLocales} showLdJSON tagExists key={id} />;
  }
}

PageTag.propTypes = {
  isLoaded: PropTypes.bool,
  match: PropTypes.object.isRequired, // eslint-disable-line
};

PageTag.defaultProps = {
  isLoaded: false,
};

const mapStateToProps = (state, props) => {
  const tagId = props.match.params.id;
  const mediaFilter = `tag/${tagId}`;
  const mediaList = state.media.lists[mediaFilter];
  const firstMediaId = get(mediaList, 'ids.0');
  const firstMedia = state.media.entities[firstMediaId];

  const fullTag = state.fullTags[tagId];
  const { tags } = getSmartTags(mediaFilter, [tagFromURI(tagId)], state);
  const name = tagFromURI(tagId);
  const messageValues = { tag: name };
  messageValues.tag1 = tags[0] && tags[0].tag._id; // eslint-disable-line no-underscore-dangle
  messageValues.tag2 = tags[1] && tags[1].tag._id; // eslint-disable-line no-underscore-dangle

  return {
    mediaFilter,
    firstMedia,
    name,
    messageValues,
    fullTag,
    isUnsafeTag: fullTag && fullTag.dangerLocales ? fullTag.dangerLocales.includes(state.intl.locale) : false,
    server: firstMedia && firstMedia.file && state.uploadServers.data[firstMedia.file.server],
    locale: state.intl.locale,
    isMobile: state.env.isMobile,
    count: get(state, ['media', 'lists', mediaFilter, 'ids', 'length'], 0),
    url: `/gifs/${props.match.params.id}`,
    isLoaded: get(mediaList, 'isLoaded'),
  };
};

const mapDispatchToProps = {
  fetchFullTagRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTag);
