import * as actions from '../../actions/fullTags';

export default (state = {}, action) => {
  if (action.entities && action.entities.fullTags) {
    return {
      ...state,
      ...action.entities.fullTags,
    };
  }

  if (action.type === actions.FETCH_FULL_TAG_FAILURE) {
    return {
      ...state,
      [action.slug]: false,
    };
  }

  return state;
};
