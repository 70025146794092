import PropTypes from 'prop-types';
import React from 'react';
import ModalProvider from '../../../containers/ModalProvider';
import AlertProvider from '../../../containers/AlertProvider';
import VerticalMenuDesktop from '../../VerticalMenuDesktop';
import HorizontalMenu from '../../../containers/HorizontalMenu';
import SearchDropdown from '../../../containers/SearchDropdown';
import './Desktop.css';

const App = props => (
  <div>
    <AlertProvider />
    <ModalProvider />
    <div className="app desktop">
      <div className="vertical-menu-container">
        <VerticalMenuDesktop />
      </div>
      <div className="app-content">
        <HorizontalMenu />
        <div className="app-content__search">
          <SearchDropdown />
        </div>
        {props.children}
      </div>
    </div>
  </div>
);

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
