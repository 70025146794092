import { SHOW_ALERT, CLOSE_ALERT } from '../../actions/alertMessage';

const initialState = {
  message: null,
  type: null,
  values: {},
  isOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        type: action.alertType,
        values: action.values,
        message: action.message,
        isOpen: true,
      };
    case CLOSE_ALERT:
      return {
        ...state,
        isOpen: false,
      };
    default: return state;
  }
};
