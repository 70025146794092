import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedHTMLMessage } from 'react-intl';
import MediaMasonry from '../../../containers/MediaMasonry';
import Meta from '../../../containers/Meta';
import AutoPlayButton from '../../../containers/AutoPlayButton';
import emptyImgGif from '../../../assets/empty-uploaded.gif';
import emptyImgWebp from '../../../assets/empty-uploaded.webp';
import './index.css';

const PartTouches = props => (
  <div className="page-user-part-touches">
    <Helmet>
      <meta name="robots" content="noindex, follow" />
    </Helmet>
    <Meta id="page_user_touches" showHreflang={false} values={{ user: props.username }} />
    <div className="page-user-part-touches__header">
      <div className="page-user-part-touches__title">
        <FormattedHTMLMessage id="page_user_history.h1" values={{ user: props.username }} />
        <span className="page-user-part-touches__count">
          {props.counters.touches}
        </span>
      </div>
      <AutoPlayButton />
    </div>
    <div className="page-user-part-touches__content">
      <MediaMasonry
        forceUpdate
        save
        id={`user/${props.match.params.id}/touches`}
        empty={(
          <div style={{ textAlign: 'center' }}>
            <picture>
              <source srcSet={emptyImgWebp} type="image/webp" />
              <source srcSet={emptyImgGif} type="image/gif" />
              <img src={emptyImgGif} alt="empty content" />
            </picture>
            <div className="page-user-part-added__content-empty">
              <FormattedHTMLMessage
                id="page_user.empty_touches"
                values={{ user: props.username }}
              />
            </div>
          </div>
        )}
      />
    </div>
  </div>
);

PartTouches.propTypes = {
  username: PropTypes.string,
  counters: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.object.isRequired, // eslint-disable-line
};

PartTouches.defaultProps = {
  username: '',
  counters: {
    touches: 0,
  },
};

export default PartTouches;
