export const ENABLE_AUTOPLAY = 'ENABLE_AUTOPLAY';
export const DISABLE_AUTOPLAY = 'DISABLE_AUTOPLAY';

export const enable = () => ({
  type: ENABLE_AUTOPLAY,
});

export const disable = () => ({
  type: DISABLE_AUTOPLAY,
});
