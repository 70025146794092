import PropTypes from 'prop-types';
import React from 'react';
import Swipeable from 'react-swipeable';
import { FontIcon } from '../../Common';
import './index.css';
import gtm from '../../../utils/gtm';

const BUTTON_WIDTH = 60;

class SwipeMedia extends React.Component {
  constructor(props) {
    super(props);
    this.swipingLeft = this.swiping.bind(this, 'right');
    this.swipingRight = this.swiping.bind(this, 'left');
    this.swipedLeft = this.swiped.bind(this, 'right');
    this.swipedRight = this.swiped.bind(this, 'left');
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isMobile) return;
    if (prevProps.id !== this.props.id) {
      this.el_left.style.left = `-${BUTTON_WIDTH}px`;
      this.el_right.style.right = `-${BUTTON_WIDTH}px`;
    }
  }

  swiping(prop, e, absX) {
    const el = this[`el_${prop}`];
    el.style[prop] = `${Math.min(0, -BUTTON_WIDTH + absX)}px`;
  }

  swiped(prop, e, deltaX) {
    if (Math.abs(deltaX) < BUTTON_WIDTH) {
      const el = this[`el_${prop}`];
      el.style[prop] = `-${BUTTON_WIDTH}px`;
      return;
    }

    switch (prop) {
      case 'right':
        this.props.gotoNextPost();
        gtm({ event: 'page_media_swipe_next' });
        break;
      case 'left':
        this.props.gotoPrevPost();
        gtm({ event: 'page_media_swipe_prev' });
        break;
      default: throw new Error('unknown prop');
    }
  }

  render() {
    const content = (
      <div
        ref={el => (this.el = el)}
        className={`page-media-swipe ${this.props.isMobile ? 'mobile' : 'desktop'}`}
      >
        <div className="container">
          {this.props.showPrevButton && (
            <div
              ref={el => (this.el_left = el)}
              className="swipe-left"
              onClick={this.props.gotoPrevPost}
            >
              <FontIcon name="icon-arrow-left-2" color="#fff" size={44} />
            </div>
          )}
          {this.props.children}
          {this.props.showNextButton && (
            <div
              ref={el => (this.el_right = el)}
              className="swipe-right"
              onClick={this.props.gotoNextPost}
            >
              <FontIcon name="icon-arrow-right-2" color="#fff" size={44} />
            </div>
          )}
        </div>
      </div>
    );

    if (!this.props.isMobile) return content;

    return (
      <Swipeable
        onSwipingLeft={this.props.showNextButton ? this.swipingLeft : undefined}
        onSwipingRight={this.props.showPrevButton ? this.swipingRight : undefined}
        onSwipedLeft={this.props.showNextButton ? this.swipedLeft : undefined}
        onSwipedRight={this.props.showPrevButton ? this.swipedRight : undefined}
      >
        {content}
      </Swipeable>
    );
  }
}

SwipeMedia.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  gotoPrevPost: PropTypes.func.isRequired,
  gotoNextPost: PropTypes.func.isRequired,
  showPrevButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
};

SwipeMedia.defaultProps = {
  showPrevButton: false,
  showNextButton: false,
};

export default SwipeMedia;
