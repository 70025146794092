export const WorkerEvents = {
  IDLE: 'idle',
  WORKING: 'working',
  LOADED: 'loaded',
  ERROR: 'error',
  PROGRESS: 'progress',
  WRITE_SOURCE_FILE_DONE: 'write_source_file_done',
  READ_SOURCE_FILE_DONE: 'read_source_file_done',
  CUT_SOURCE_FILE_DONE: 'cut_source_file_done',
  READ_DEST_FILE_DONE: 'read_dest_file_done',
};

export const WorkerCommands = {
  WRITE_SOURCE_FILE: 'WRITE_SOURCE_FILE',
  READ_SOURCE_FILE: 'READ_SOURCE_FILE',
  CUT_SOURCE_FILE: 'CUT_SOURCE_FILE',
  READ_DEST_FILE: 'READ_DEST_FILE',
};
