import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontIcon, Button } from '../../../Common';
import './index.css';

class SocialButtons extends React.Component {
  constructor(props) {
    super(props);
    this.handleFacebookClick = this.handleClick.bind(this, 'facebook');
    this.handleGoogleClick = this.handleClick.bind(this, 'google');
  }

  handleClick(type) {
    this.props.onClick(type);
  }

  render() {
    return (
      <div className="auth-social">
        <Button
          block
          size="s50"
          type="facebook"
          color="#3163a2"
          icon={<FontIcon name="icon-facebook" color="#fff" size={27} />}
          onClick={this.handleFacebookClick}
        >
          <FormattedMessage id="auth.facebook" />
        </Button>
        <Button
          size="s50"
          color="#dc3534"
          icon={<FontIcon name="icon-google" size={27} />}
          onClick={this.handleGoogleClick}
        />
      </div>
    );
  }
}

SocialButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SocialButtons;
