import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedHTMLMessage } from 'react-intl';
import MediaMasonry from '../../../containers/MediaMasonry';
// import PartEmpty from '../../../containers/PageUser/PartEmpty';
import Meta from '../../../containers/Meta';
import AutoPlayButton from '../../../containers/AutoPlayButton';
import emptyImg from '../../../assets/empty-favorites.gif';
import './index.css';

const PartAdded = props => (
  <div className="page-user-part-added">
    <Helmet>
      <meta name="robots" content="noindex, follow" />
    </Helmet>
    <Meta showHreflang={false} id="page_user_favorites" values={{ user: props.username }} />
    <div className="page-user-part-added__header">
      <div className="page-user-part-added__title">
        <span className="page-user-part-added__favorites">
          <FormattedHTMLMessage id="page_user_favorites.h1" values={{ user: props.username }} />
        </span>
        <span className="page-user-part-added__favorites-count">
          {props.counters.likes}
        </span>
      </div>
      <AutoPlayButton />
    </div>
    <div className="page-user-part-added__content">
      <MediaMasonry
        save
        forceUpdate
        id={`user/${props.match.params.id}/favorites`}
        empty={(
          <div style={{ textAlign: 'center' }}>
            <img src={emptyImg} alt="" />
            <div className="page-user-part-added__content-empty">
              <FormattedHTMLMessage
                id="page_user.empty_favorites"
                values={{ user: props.username }}
              />
            </div>
          </div>
        )}
      />
    </div>
  </div>
);

PartAdded.propTypes = {
  username: PropTypes.string,
  counters: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.object.isRequired, // eslint-disable-line
};

PartAdded.defaultProps = {
  username: '',
  counters: {
    likes: 0,
  },
};

export default PartAdded;
