import { connect } from 'react-redux';
import SmartTags from '../../components/SmartTags';
import { getSmartTags } from './getSmartTags';

const mapStateToProps = (state, props) => {
  const { listId, exclude = [] } = props;

  return getSmartTags(listId, exclude, state);
};

export default connect(mapStateToProps)(SmartTags);
