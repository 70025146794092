import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Meta from '../Meta';
import { getMediaSrc } from '../../utils/url';

const PageMediaMeta = (props) => {
  const meta = [];
  const { clientType, server, moderated, intl, values, id, mediaId, pageUrl } = props;
  const title = intl.formatMessage({ id: `${id}.meta_og_title` }, values);

  const oembedUrl = `${server}/embedded/oembed?url=${encodeURIComponent(pageUrl)}`;
  const links = [
    { rel: 'alternate', type: 'application/json+oembed', href: `${oembedUrl}&format=json`, title },
    { rel: 'alternate', type: 'text/xml+oembed', href: `${oembedUrl}&format=xml`, title },
  ];

  // disable indexing for non-moderated media
  if (moderated === false) meta.push({ name: 'robots', content: 'noindex, follow' });

  // generate meta tags for crawlers
  const file = props.file || {};
  const previewImage = getMediaSrc(server, file.path, 'gif', { width: 300 });

  if (['facebook', 'vk'].includes(clientType)) {
    meta.push({
      property: 'og:image',
      content: getMediaSrc(
        server,
        file.path,
        'gif',
        { preset: `${clientType}_play` },
      ),
    });
  } else if (clientType === 'ok') {
    meta.push({ property: 'og:type', content: 'video.other' });
    meta.push({
      property: 'og:image',
      content: getMediaSrc(
        server,
        file.path,
        'jpeg',
        { preset: 'ok_play' },
      ),
    });
  } else if (clientType === 'telegram') {
    const mp4 = getMediaSrc(server, file.path, 'mp4');
    meta.push(
      { property: 'og:image', content: previewImage },
      { property: 'og:type', content: 'video.other' },
      { property: 'og:video', content: mp4 },
      { property: 'og:video:type', content: 'video/mp4' },
      { property: 'og:video:width', content: file.width },
      { property: 'og:video:height', content: file.height },
      { name: 'twitter:card', content: 'player' },
      { name: 'twitter:player', content: `${server}/embedded/media/${mediaId}` },
      { name: 'twitter:player:stream', content: mp4 },
      { name: 'twitter:player:stream:content_type', content: 'video/mp4' },
      { name: 'twitter:player:width', content: file.width },
      { name: 'twitter:player:height', content: file.height },
      { name: 'twitter:image', content: previewImage },
    );
  } else if (clientType === 'twitter') {
    const twitterPreviewImage = getMediaSrc(
      server,
      file.path,
      'gif',
      { preset: 'twitter_play' },
    );

    meta.push(
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:image', content: twitterPreviewImage },
    );
  } else {
    const gif = `${server}/${mediaId}.gif`;
    const mp4 = `${server}/${mediaId}.mp4`;

    meta.push(
      { property: 'og:type', content: 'video.other' },
      { property: 'og:image', content: gif },
      { property: 'og:image:type', content: 'image/gif' },
      { property: 'og:image:width', content: file.width },
      { property: 'og:image:height', content: file.height },
      { property: 'og:video', content: mp4 },
      { property: 'og:video:secure_url', content: mp4 },
      { property: 'og:video:type', content: 'video/mp4' },
      { property: 'og:video:width', content: file.width },
      { property: 'og:video:height', content: file.height },
    );
  }

  return <Meta {...props} meta={meta} links={links} />;
};

PageMediaMeta.propTypes = {
  id: PropTypes.string.isRequired,
  clientType: PropTypes.string.isRequired,
  server: PropTypes.string,
  pageUrl: PropTypes.string,
  mediaId: PropTypes.string,
  moderated: PropTypes.bool,
  intl: intlShape,
  values: PropTypes.object, // eslint-disable-line
  file: PropTypes.object, // eslint-disable-line
};

const mapStateToProps = (state, props) => {
  const media = state.media.entities[props.mediaId] || {};
  return {
    pageUrl: state.env.url,
    file: media.file,
    clientType: state.env.clientType,
    server: media.file && state.uploadServers.data[media.file.server],
  };
};

export default connect(mapStateToProps)(injectIntl(PageMediaMeta));
