/*eslint-disable */
import React from 'react';
import './index.css';

export default () => (
  <div className="page-privacy">
    <title>Privacy Policy</title>

    <h1>Privacy Policy</h1>
    <p>This Privacy Policy (the "Policy") describes the policies and procedures on the collection, use and disclosure of your information in connection with your use of gifer.com (the "Site"), the services, features, or content we offer (collectively with the Site, the "Services"). When you use the Services, you are consenting to the collection, transfer, manipulation, storage, disclosure and other uses of your information as described in this Policy.</p>

    <h2>1. Information we collect and the purposes for which we use it</h2>
    <p>If you create an Account, you will provide information that could be Personal Data, such as your email address and a password. Additionally, if you register for or access the Services using a third party services (such as your social media account login credentials), we receive data that you have made available to share through such services, which include your user ID and name. You acknowledge that this information may be personal to you, and by creating an Account and providing Personal Data to us, you allow others, including us, to identify you and therefore may not be anonymous.</p>
    <p>Some features of the Services allow you to provide content to the Services, including personal data if you so choose. User contributions include, but are not limited to, Video, GIFs, written comments, links, and pictures. All content submitted by you to the Services may be retained by us indefinitely, even after you terminate your account or your access to the Services is terminated.</p>
    <p>The information we gather enables us to personalize, improve, understand and continue to operate the Services.</p>

    <h2>2. Use of Cookies</h2>
    <p>We may use cookies to enable our servers to recognize your web browser and tell us how and when you visit the Site and otherwise use the Services. Our cookies do not, by themselves, contain any personal data. However, we may match cookies with your personal data to identify you, and we may use cookies to identify that your web browser has accessed aspects of the Services and may associate that information with your account if you have one.</p>
    <p>We may allow third party web analytics service providers to use cookies or similar technologies to collect information about your use of the Services. We use Google Analytics to help us understand how users use the Services and to enhance your experience when you use the Services. For more information, please go to <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a> and to opt out of data recording and analysis by Google Analytics on the Services, please visit <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
    <p>We may allow third party vendors, including Google, use cookies to serve ads based on a user's prior visits to Site. Google's use of the DoubleClick cookie enables it and its partners to serve ads to you based on your visit to Site and/or other sites on the internet. You can opt out of the use of the cookies for interest-based advertising by visiting <a href="https://adssettings.google.com">Ads Settings</a> and <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.</p>


    <h2>3. Information you contribute</h2>
    <p>Every content item uploaded to Services or posted there is public – whether uploaded directly without going through a user account, or uploaded via a user account – and has its own URL. No matter what your privacy settings are, every image can always be accessed and viewed by anyone who types in that exact URL. No image uploaded to Services is ever completely hidden from public view.</p>

    <h2>4. Accessing, correcting, and disclosing of personal data</h2>
    <p>Because we don't collect information about the identity of our users and don't receive any information from third parties enabling us to identify our users, we have no means of knowing, or providing information about you we might have on our servers – unless you have user account. If you have an account, you may log in and access your data, correct whatever information you deem to be incorrect or delete your account contacting us by clicking "Report" button on any Site page.</p>
    <p>We do not provide any personal data to third parties, at the same time we reserve the right to disclose any information as we reasonably believe is necessary to satisfy any applicable law, regulation, legal process or governmental request.</p>

    <h2>5. Data Protection</h2>
    <p>We take every reasonable precaution to protect the data on our servers from loss, misuse, unauthorized access, disclosure, alteration, or destruction, taking into account the risk level and the nature of the data. You are responsible for taking every reasonable precaution on your end to protect any unauthorized person from accessing your account.</p>

    <h2>6. Miscellaneous</h2>
    <p>We may revise this Policy from time to time without notice. If you have any questions or concerns regarding privacy using the Services, please send us a detailed message to us using "Report" button on any Site page.</p>
  </div>
);
