import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { tagToURI, tagFromURI } from '../../../utils/tagToURI';
import './index.css';

const Tag = (props) => {
  let name = tagFromURI(props.id);
  if (props.rel) name = <h3 rel={props.rel}>{name}</h3>;
  else if (props.itemProp) name = <h3 itemProp={props.itemProp}>{name}</h3>;

  return (
    <div className="page-media-tag">
      <Link to={`/gifs/${tagToURI(props.id)}`}>
        {name}
      </Link>
      &nbsp;
    </div>
  );
};

Tag.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rel: PropTypes.string,
  itemProp: PropTypes.string,
};

export default Tag;
