import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, FontIcon } from '../../Common';
import './index.css';

class EmbedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedFixed: false,
      copiedAdaptive: false,
    };
  }

  handleClickCopyFixed = () => {
    this.setState({ copiedFixed: true, copiedAdaptive: false });
    setTimeout(() => this.setState({ copiedFixed: false }), 1000);
  }

  handleClickCopyAdaptive = () => {
    this.setState({ copiedAdaptive: true, copiedFixed: false });
    setTimeout(() => this.setState({ copiedAdaptive: false }), 1000);
  }

  handleClickInput(event) {
    event.target.select();
  }

  render() {
    const { intl } = this.context;
    const { id, width, height } = this.props;
    const embedUrl = `${process.env.REACT_APP_DOMAIN}/embed/${id}`;
    const fixedWidth = 480;
    const fixedHeight = (height * (fixedWidth / width)).toFixed(3);
    const paddingTop = `${((height / width) * 100).toFixed(3)}%`;
    const fixedCode = `<iframe src="${embedUrl}" width=${fixedWidth} height=${fixedHeight} frameBorder="0" allowFullScreen></iframe><p><a href="${process.env.REACT_APP_DOMAIN}">${intl.formatMessage({ id: 'embed-modal.via' })}</a></p>`;
    const adaptiveCode = `<div style="padding-top:${paddingTop};position:relative;"><iframe src="${embedUrl}" width="100%" height="100%" style='position:absolute;top:0;left:0;' frameBorder="0" allowFullScreen></iframe></div><p><a href="${process.env.REACT_APP_DOMAIN}">${intl.formatMessage({ id: 'embed-modal.via' })}</a></p>`;

    return (
      <div className="embed-modal-form">
        <p className="embed-modal-form__input-label">
          <FormattedMessage id="embed-modal.fixed-label" />
        </p>
        <div className="embed-modal-form__input-container">
          <input
            className="embed-modal-form__input"
            value={fixedCode}
            onClick={this.handleClickInput}
            spellCheck={false}
          />
          <CopyToClipboard text={fixedCode}>
            <div>
              <Button
                className="embed-modal-form__copy_mobile"
                type="gradient"
                size="small"
                onClick={this.handleClickCopyFixed}
                icon={this.state.copiedFixed
                  ? <FontIcon name="icon-complete" size={24} />
                  : <FontIcon name="icon-copy" />}
              />
              <Button
                type="gradient"
                size="s50"
                onClick={this.handleClickCopyFixed}
                className="embed-modal-form__copy_desktop"
              >
                {this.state.copiedFixed
                  ? <FormattedMessage id="embed-modal.copy-done" />
                  : <FormattedMessage id="embed-modal.copy" />}
              </Button>
            </div>
          </CopyToClipboard>
        </div>

        <p className="embed-modal-form__input-label">
          <FormattedMessage id="embed-modal.adaptive-label" />
        </p>
        <div className="embed-modal-form__input-container">
          <input
            className="embed-modal-form__input"
            value={adaptiveCode}
            onClick={this.handleClickInput}
            spellCheck={false}
          />
          <CopyToClipboard text={adaptiveCode}>
            <div>
              <Button
                className="embed-modal-form__copy_mobile"
                type="gradient"
                size="small"
                onClick={this.handleClickCopyAdaptive}
                icon={this.state.copiedAdaptive
                  ? <FontIcon name="icon-complete" size={24} />
                  : <FontIcon name="icon-copy" />}
              />
              <Button
                type="gradient"
                size="s50"
                onClick={this.handleClickCopyAdaptive}
                className="embed-modal-form__copy_desktop"
              >
                {this.state.copiedAdaptive
                  ? <FormattedMessage id="embed-modal.copy-done" />
                  : <FormattedMessage id="embed-modal.copy" />}
              </Button>
            </div>
          </CopyToClipboard>
        </div>
      </div>
    );
  }
}

EmbedForm.contextTypes = {
  intl: PropTypes.objectOf(PropTypes.any),
};

EmbedForm.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

EmbedForm.defaultProps = {
  width: 200,
  height: 0,
};

export default EmbedForm;
