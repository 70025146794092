import { combineReducers } from 'redux';
import entities from './entities';
import lists from './lists';
import lastFilter from './lastFilter';
import pageFilter from './pageFilter';

export default combineReducers({
  entities,
  lists,
  lastFilter,
  pageFilter,
});
