import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import randomColor from 'randomcolor';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, FontIcon } from '../Common';
import EventsPanel from '../../containers/EventsPanel';
import SmilesPanel from '../../containers/SmilesPanel';
import UserPanel from '../../containers/UserPanel';
import UserLinks from '../../containers/VerticalMenuMobile/UserLinks';
import { getMediaSrc } from '../../utils/url';
import './index.css';

const VerticalMenuMobile = (props) => {
  useEffect(() => {
    document.querySelector('body').classList.add('noscroll');
    return () => document.querySelector('body').classList.remove('noscroll');
  }, []);

  return (
    <div className="vertical-menu-mobile">
      <div className="vertical-menu-mobile__user-panel-wrap">
        <Button
          size="s50"
          onClick={props.closeMenu}
          icon={(
            <FontIcon
              name="icon-close"
              size="26px"
              color="#FFFFFF"
            />
          )}
        />
        <div className="vertical-menu-mobile__user-panel">
          <UserPanel />
        </div>
      </div>

      <div className="vertical-menu-mobile__content">
        <div className="vertical-menu-mobile__smiles">
          <SmilesPanel />
        </div>
        <div className="vertical-menu-mobile__user-links">
          <UserLinks />
        </div>
        <div className="vertical-menu-mobile__events">
          <EventsPanel isMobile />
        </div>
        <div className="vertical-menu-mobile__categories">
          <h2 className="vertical-menu-mobile__title">
            <FormattedMessage id="menu.categories" />
          </h2>
          <div className="vertical-menu-mobile__categories-items">
            {props.categories.map((category) => {
              const gifSrc = getMediaSrc('https://i.gifer.com', `${category.image}_mob`, 'gif');
              const wepbSrc = getMediaSrc('https://i.gifer.com', `${category.image}_w200`, 'webp');
              const color = typeof window === 'undefined' ? '#000000' : randomColor({ luminosity: 'light' });
              return (
                <Link
                  to={`/category/${category.slug}`}
                  className="vertical-menu-mobile__categories-item"
                  key={category.id}
                >
                  <picture
                    className="vertical-menu-mobile__categories-item-bg"
                    style={{ backgroundColor: color }}
                  >
                    <source srcSet={wepbSrc} type="image/webp" />
                    <source srcSet={gifSrc} type="image/gif" />
                    <img
                      key={gifSrc}
                      className="vertical-menu-mobile__categories-item-image"
                      itemProp="contentUrl"
                      alt=""
                      src={gifSrc}
                    />
                  </picture>
                  <div className="vertical-menu-mobile__categories-item-name">
                    {category.name}
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="vertical-menu-mobile__links">
            <Link className="vertical-menu-mobile__links-item" to="/p/privacy">
              <FormattedMessage id="menu.privacy-policy" />
            </Link>
            <Link className="vertical-menu-mobile__links-item" to="/p/tos">
              <FormattedMessage id="menu.terms-of-service" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

VerticalMenuMobile.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  closeMenu: PropTypes.func,
};

VerticalMenuMobile.defaultProps = {
  categories: [],
  closeMenu: () => { },
};

export default VerticalMenuMobile;
