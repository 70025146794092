import PropTypes from 'prop-types';
import React from 'react';

const Masonry = props => (
  <ul style={{ columnCount: props.columnCount, columnGap: 10 }}>
    {props.data.map((val, key) => (
      <li key={val} style={{ width: '100%', marginBottom: 10, breakInside: 'avoid' }}>
        <section>{props.render(key)}</section>
      </li>
    ))}
  </ul>
);


Masonry.propTypes = {
  columnCount: PropTypes.number,
  data: PropTypes.array.isRequired, // eslint-disable-line
  render: PropTypes.func.isRequired,
};

Masonry.defaultProps = {
  columnCount: 1,
};

export default Masonry;
