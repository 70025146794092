import { takeEvery, put } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { closeMenu } from '../../actions/menu';

function* handleChangeRoute() {
  yield put(closeMenu());

  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }
}

export function* watchChangeRoute() {
  yield takeEvery(LOCATION_CHANGE, handleChangeRoute);
}
