import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import intl from './intl';
import menu from './menu';
import auth from './auth';
import categories from './categories';
import tags from './tags';
import fullTags from './fullTags';
import media from './media';
import uploadServers from './uploadServers';
import users from './users';
import autoPlay from './autoPlay';
import search from './search';
import searchHints from './searchHints';
import modal from './modal';
import upload from './upload';
import env from './env';
import alertMessage from './alertMessage';
import events from './events';
import smiles from './smiles';

export default combineReducers({
  env,
  intl,
  menu,
  auth,
  categories,
  tags,
  fullTags,
  uploadServers,
  media,
  users,
  autoPlay,
  search,
  searchHints,
  modal,
  upload,
  alertMessage,
  events,
  smiles,
  router: routerReducer,
});
