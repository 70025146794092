import PropTypes from 'prop-types';
import React from 'react';
import './index.css';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') this.props.onSubmit();
  }

  render() {
    const { style, error, ...props } = this.props;
    if (error) style.borderBottomColor = '#e75152';

    return (
      <input
        {...props}
        style={style}
        className="auth-input"
        onKeyPress={this.handleKeyPress}
      />
    );
  }
}

Input.propTypes = {
  style: PropTypes.object,  // eslint-disable-line
  error: PropTypes.any,     // eslint-disable-line
  onSubmit: PropTypes.func,
};

Input.defaultProps = {
  style: {},
  error: null,
  onSubmit: () => {},
};

export default Input;
