// import { normalize } from 'normalizr';
import request from '../index';
import UserSchema from '../../../schemas/user';

const BASE_URL = process.env.REACT_APP_GATEWAY_API;

export const login = data =>
  request('login', {
    data,
    method: 'POST',
    normalize: UserSchema,
  });

export const signup = data =>
  request('signup', {
    data,
    method: 'POST',
    normalize: UserSchema,
  });

export const fetchMe = () =>
  request('api/users/me', {
    normalize: UserSchema,
  });

export const logout = () =>
  request('logout', {
    method: 'POST',
  });

export const socialLogin = provider => (
  new Promise((resolve, reject) => {
    const w = window.open(`${BASE_URL}/auth/${provider}`, 'sauth', 'width=600,height=600,resizable=yes,scrollbars=yes,status=yes');
    const interval = setInterval(() => {
      if (w.closed) {
        clearInterval(interval);
        fetchMe()
          .then(resolve)
          .catch(reject);
      }
    }, 500);
  })
);

export const checkEmail = email =>
  request(`api/emails/${email}`)
    .then(() => true)
    .catch(() => false);

