import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import HighlightText from '../../Common/HighlightText';
import './index.css';

const ResultItem = props =>
  <Link to={props.href} className={`search-results-item ${props.isActive ? 'search-results-item_active' : ''}`}>
    {props.leftIcon}
    <span className="name">
      {props.highlight
        ? <HighlightText highlight={props.highlight} text={props.title} />
        : props.title
      }
    </span>
  </Link>;

ResultItem.propTypes = {
  highlight: PropTypes.string,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  leftIcon: PropTypes.node,
  isActive: PropTypes.bool,
};

export default ResultItem;
