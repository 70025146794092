import React from 'react';
import { FFmpegProvider } from './contexts/ffmpegContext';
import MakerRoot from './components/root';
import Layout from './components/layout';
import Meta from '../../containers/Meta';
import './index.css';

const PageGifMaker = () => {
  return (
    <FFmpegProvider>
      <Layout>
        <Meta id="page_maker" />
        <MakerRoot />
      </Layout>
    </FFmpegProvider>
  );
};

export default PageGifMaker;
