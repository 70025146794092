import { OPEN_MENU, CLOSE_MENU } from '../../actions/menu';
import { OPEN_MODAL } from '../../actions/modal';

export const initialState = {
  isOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_MENU:
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: false,
      };

    default: return state;
  }
};
