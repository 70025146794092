import PropTypes from 'prop-types';
import React from 'react';
import randomColor from 'randomcolor';
import throttle from 'lodash/throttle';
import GridSize from '../GridSize';

class Masonry extends React.Component {
  constructor(props) {
    super(props);
    this.height = 0;
    this.cells = [];
    this.state = { top: 0, collCount: 0, cellWidth: 0, visible: [] };
    this.handleScroll = throttle(this.handleScroll, 50);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.calc(nextProps, this.state);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.refresh !== nextState.refresh;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.calcVisible(this.state);
    this.lazyLoad();
  }

  handleReceiveDimensions = (changes) => {
    this.calc(this.props, {
      ...this.state,
      ...changes,
    });
    this.setState(changes);
  }

  lazyLoad() {
    const { onInfiniteLoad, isInfiniteLoading, data, offset } = this.props;

    if (!onInfiniteLoad || isInfiniteLoading || this.lastDataLength === data.length) {
      return;
    }

    if (document.body.clientHeight - (window.scrollY + window.innerHeight) <= offset) {
      this.lastDataLength = data.length;
      onInfiniteLoad();
    }
  }

  calc(props, state) {
    const colsY = new Array(state.collCount).fill(0);
    this.cells = [];
    props.data.forEach((val, key) => {
      const height = props.cellHeight(key, state.cellWidth);
      const i = colsY.reduce((l, v, k) => (v < colsY[l] ? k : l), 0);
      this.cells.push({
        height,
        index: key,
        width: state.cellWidth,
        left: i * (state.cellWidth + props.gutter),
        top: colsY[i],
        bottom: colsY[i] + height,
      });
      colsY[i] += height + props.gutter;
    });

    this.height = Math.max(...colsY);
    this.calcVisible(state);
  }

  calcVisible(state) {
    const visibleTop = window.scrollY - state.top;
    const visibleBottom = visibleTop + window.innerHeight;
    const visibleCells = this.cells.filter(cell =>
      cell.top <= visibleBottom && cell.bottom >= visibleTop);
    this.setState({ visible: visibleCells, refresh: !state.refresh });

    if (typeof this.props.onVisibleChange === 'function') {
      this.props.onVisibleChange(visibleCells, this.props.data);
    }
  }

  render() {
    const containerStyle = {
      height: this.height,
      position: 'relative',
    };

    return (
      <div>
        <GridSize
          {...this.props}
          onChange={this.handleReceiveDimensions}
        />
        <ul style={containerStyle}>
          {this.state.visible.map(cell => (
            <li
              key={cell.index}
              style={{
                backgroundColor: randomColor({ luminosity: 'light', seed: cell.index }),
                position: 'absolute',
                left: cell.left,
                top: cell.top,
                width: cell.width,
                height: cell.height,
              }}
            >
              <section>
                {this.props.render(cell.index, cell.width, cell.height)}
              </section>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

Masonry.propTypes = {
  isInfiniteLoading: PropTypes.bool,
  offset: PropTypes.number,
  data: PropTypes.array.isRequired, // eslint-disable-line
  render: PropTypes.func.isRequired,
  onInfiniteLoad: PropTypes.func,
  onVisibleChange: PropTypes.func,
};

Masonry.defaultProps = {
  offset: 0,
  isInfiniteLoading: false,
  onInfiniteLoad: null,
  onVisibleChange: undefined,
};

export default Masonry;
