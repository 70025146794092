import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../Modal';
import Button from '../../../Common/Button';
import Success from './Success';
import fetch from '../../../../utils/api';
import './index.css';

class PopupReview extends React.Component {
  constructor(props) {
    super(props);
    this.formEl = React.createRef();
    this.state = { isSent: false };
  }

  handleClickSend = (event) => {
    const isFormValid = this.formEl.current.checkValidity();
    if (isFormValid) {
      event.preventDefault();
      this.setState({ isSent: true });
      const { email, text } = this.formEl.current.elements;
      fetch('api/feedbacks', {
        method: 'POST',
        body: JSON.stringify({
          type: 'feedback',
          email: email.value,
          media: this.props.mediaId,
          ref: window.location.href,
          text: text.value,
        }),
      });
    }
  };

  renderForm() {
    return (
      <form
        className="page-media-popup-review"
        onSubmit={this.handleClickSend}
        ref={this.formEl}
      >
        <div className="page-media-popup-review__row page-media-popup-review__row_2x">
          <h1 className="page-media-popup-review__h1">
            <FormattedMessage id="page-media.popup-review__title" />
          </h1>
        </div>

        <div className="page-media-popup-review__row">
          <input
            name="email"
            type="email"
            className="page-media-popup-review__input"
            placeholder={this.context.intl.formatMessage({ id: 'page-media.popup-review__email' })}
          />
        </div>

        <div className="page-media-popup-review__row page-media-popup-review__row_2x">
          <textarea
            required
            className="page-media-popup-review__textarea"
            name="text"
            placeholder={this.context.intl.formatMessage({ id: 'page-media.popup-review__textarea' })}
          />
        </div>

        <div className="page-media-popup-review__row">
          <Button type="gradient" onClick={this.handleClickSend} block>
            <FormattedMessage id="page-media.popup-review__send" />
          </Button>
        </div>
      </form >
    );
  }

  renderSuccess() {
    return (
      <Success handleClickClose={this.props.closeModal} />
    );
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal}>
        {this.state.isSent
          ? this.renderSuccess()
          : this.renderForm()
        }
      </Modal>
    );
  }
}

PopupReview.contextTypes = {
  intl: PropTypes.objectOf(PropTypes.any),
};

PopupReview.propTypes = {
  mediaId: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
};

PopupReview.defaultProps = {
  closeModal: () => { },
};

export default PopupReview;
