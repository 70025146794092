import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

class Button extends Component {
  prepareClasses() {
    const classes = [];
    classes.push('button');
    classes.push(`type-${this.props.type}`);
    classes.push(`size-${this.props.size}`);
    classes.push(`shape-${this.props.shape}`);
    if (!this.props.children) classes.push('without-children');
    if (this.props.block) classes.push('block');
    if (this.props.animateIcon) classes.push('animate-icon');
    if (this.props.disabled) classes.push('disabled');
    return `${classes.join(' ')} ${this.props.className}`;
  }

  render() {
    const { icon, iconPosition, children, href, disabled, onClick, iconStyle } = this.props;
    let Element = 'button';
    if (href) Element = this.props.external ? 'a' : Link;
    const style = { ...this.props.style };
    if (this.props.color) style.backgroundColor = this.props.color;
    const linkOpts = {
      ...this.props.nativeProps,
      className: this.prepareClasses(),
      style,
    };
    if (href) {
      if (this.props.external) {
        linkOpts.target = '_blank';
        linkOpts.href = href;
      } else {
        linkOpts.to = href;
      }
      linkOpts.activeClassName = this.props.activeClassName;
      linkOpts.exact = this.props.exact;
      linkOpts.rel = this.props.rel;
    } else {
      linkOpts.onClick = !disabled && onClick;
    }

    return (
      <Element {...linkOpts} aria-label="Button">
        {icon && iconPosition === 'left' &&
          <span style={iconStyle} className={`button-icon ${children ? 'left-icon' : ''}`}>
            {icon}
          </span>
        }
        {!!children &&
          <span className="children">
            {children}
          </span>
        }
        {icon && iconPosition === 'right' &&
          <span style={iconStyle} className={`button-icon ${children ? 'right-icon' : ''}`}>
            {icon}
          </span>
        }
      </Element>
    );
  }
}

Button.propTypes = {
  style: PropTypes.object, // eslint-disable-line
  nativeProps: PropTypes.object, // eslint-disable-line
  external: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  iconStyle: PropTypes.object, // eslint-disable-line
  type: PropTypes.oneOf([
    'disabled',
    'default',
    'primary',
    'success',
    'transparent',
    'black-transparent',
    'link',
    'play',
    'pause',
    'gradient',
  ]),
  size: PropTypes.oneOf(['small', 'middle', 'large', 's50']),
  shape: PropTypes.oneOf(['box', 'circle', 'round']),
  block: PropTypes.bool,
  animateIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool,
  rel: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  style: {},
  nativeProps: {},
  color: null,
  rel: null,
  animateIcon: false,
  external: false,
  children: '',
  className: '',
  iconStyle: {},
  block: false,
  disabled: false,
  type: 'default',
  size: 'middle',
  shape: 'box',
  iconPosition: 'left',
  exact: true,
  onClick: () => { },
};

export default Button;
