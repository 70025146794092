import * as actions from '../../actions/categories';

export default (state = {}, action) => {
  switch (action.type) {
    case actions.CATEGORY_TAGS_REQUEST:
      return {
        ...state,
        [action.categoryId]: {
          isLoaded: false,
          isLoading: true,
          error: null,
          ids: [],
        },
      };

    case actions.CATEGORY_TAGS_SUCCESS:
      return {
        ...state,
        [action.categoryId]: {
          isLoaded: true,
          isLoading: false,
          error: null,
          ids: action.ids,
        },
      };

    case actions.CATEGORY_TAGS_FAILURE:
      return {
        ...state,
        [action.categoryId]: {
          isLoaded: false,
          isLoading: false,
          error: action.error,
        },
      };
    default: return state;
  }
};
