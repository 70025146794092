import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontIcon, Button } from '../../Common/index';
import Popup from '../Popup/index';
import './Desktop.css';

const UserPanel = (props) => {
  const buttonStyle = { justifyContent: 'flex-start' };

  return (
    <div className="menu-user-panel desktop">
      {props.isAuth ?
        <Button
          style={buttonStyle}
          size="small"
          href={`/user/${props.id}/touches`}
          icon={(
            <FontIcon
              name="icon-user"
              style={{
                marginLeft: -12,
                padding: 10,
                backgroundColor: 'rgb(109, 181, 172)',
              }}
            />
          )}
        >{props.username}</Button> :
        <Button
          style={buttonStyle}
          size="small"
          onClick={props.onLoginClick}
          icon={<FontIcon name="icon-login" />}
        ><FormattedMessage id="menu.auth_button" /></Button>
      }
      <Popup
        isAuth={props.isAuth}
        onLogout={props.logout}
        locales={props.locales}
        locale={props.locale}
      >
        {props.isAuth ?
          <Button className="menu-user-panel__settings" size="small">
            <FontIcon name="icon-gear" size={24} color="#b3b3b3" />
          </Button> :

          <Button
            size="small"
            style={{ minWidth: 0 }}
            icon={<FontIcon name="icon-globe" size={23} />}
          >
            {props.locale.toUpperCase()}
          </Button>
        }
      </Popup>
    </div>
  );
};

UserPanel.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string,
  username: PropTypes.string,
  logout: PropTypes.func.isRequired,
  onLoginClick: PropTypes.func.isRequired,
};

export default UserPanel;
