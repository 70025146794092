import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape } from 'react-intl';

const Meta = ({ id, values, intl, url, locales, page = 1, meta, links, showHreflang }) => {
  const messageValues = {
    ...values,
    page: '',
  };

  // add {page} value
  if (page && page > 1) {
    messageValues.page = intl.formatMessage({ id: 'page_tag.meta_page' }, { page });
  }

  const metaTags = [
    { property: 'og:title', content: intl.formatMessage({ id: `${id}.meta_og_title` }, messageValues) },
    { property: 'og:description', content: intl.formatMessage({ id: `${id}.meta_og_description` }, messageValues) },
    { name: 'description', content: intl.formatMessage({ id: `${id}.meta_description` }, messageValues) },
    { name: 'keywords', content: intl.formatMessage({ id: `${id}.meta_keywords` }, messageValues) },
    ...meta,
  ];

  metaTags.push({
    name: 'author',
    content: messageValues.user || intl.formatMessage({ id: 'meta.author' }),
  });

  // generate <link rel="alternate" hreflang="...
  const hreflangs = [];
  if ((showHreflang === true || (Array.isArray(showHreflang) && showHreflang.length > 1)) && page === 1) {
    (Array.isArray(showHreflang) ? showHreflang : locales).forEach(locale => hreflangs.push({
      rel: 'alternate',
      hreflang: locale,
      href: `${process.env.REACT_APP_DOMAIN}/${locale}${url === '/' ? '' : url}`,
    }));
  }

  return (
    <Helmet
      htmlAttributes={{ lang: intl.locale }}
      title={intl.formatMessage({ id: `${id}.meta_title` }, messageValues)}
      meta={metaTags}
      link={[...links, ...hreflangs]}
    />
  );
};

Meta.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.number,
  values: PropTypes.object, // eslint-disable-line
  meta: PropTypes.arrayOf(PropTypes.object),
  links: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string.isRequired,
  url: PropTypes.string,
  showHreflang: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Meta.defaultProps = {
  page: 1,
  url: null,
  links: [],
  meta: [],
  showHreflang: true,
};

export default injectIntl(Meta);
