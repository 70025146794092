import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, FontIcon } from '../Common';
import './index.css';

const AutoPlayButton = props =>
  <div className="autoplay-button">
    {
      props.text
        ? <span className="text"><FormattedMessage id="autoplay" /></span>
        : null
    }
    {
      props.autoPlay
        ? <Button
          size="small"
          onClick={props.disable}
          type="pause"
          icon={<FontIcon name="icon-pause" size="24px" />}
        />
        : <Button
          size="small"
          onClick={props.enable}
          type="play"
          icon={<FontIcon name="icon-play" size="24px" />}
        />
    }
  </div>;

AutoPlayButton.propTypes = {
  autoPlay: PropTypes.bool,
  text: PropTypes.bool,
  disable: PropTypes.func,
  enable: PropTypes.func,
};

export default AutoPlayButton;
