import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontIcon } from '../../Common';
import AutoPlayButton from '../../../containers/AutoPlayButton';
import './index.css';
import { tagToURI, tagFromURI } from '../../../utils/tagToURI';
import gtm from '../../../utils/gtm';

class OtherMediaControls extends Component {
  componentDidMount() {
    if (this.props.filter.section === undefined) {
      gtm({ event: 'view_related_gifs' });
    } else if (['tag', 'search'].includes(this.props.filter.section)) {
      gtm({ event: 'view_tag_gifs' });
    } else if (this.props.filter.section === 'popular') {
      gtm({ event: 'view_popular_gifs' });
    } else if (this.props.filter.section === 'event') {
      gtm({ event: 'view_events_gifs' });
    } else if (this.props.filter.section === 'smile') {
      gtm({ event: 'view_smiles_gifs' });
    }
  }

  render() {
    if (!this.props.sourceTag && !this.props.showAutoPlay) return null;

    return (
      <div style={this.props.style} className="page-media-other-controls">
        <div>
          {this.props.filter.section === 'user'
            && (
              <Link
                push
                to={`/user/${tagToURI(this.props.filter.user)}${this.props.filter.type === 'uploaded' ? '' : `/${this.props.filter.type}`}`}
                className="other-gif-title"
              >
                <FontIcon size="27px" name="icon-arrow-left-2" />
                <span className="page-media-other-controls__soource-tag">
                  <FormattedMessage
                    id="page_media.other_gif_user"
                    values={{ user: this.props.name }}
                  />
                </span>
              </Link>
            )
          }

          {this.props.filter.section === 'tag'
            && (
              <Link push to={`/gifs/${tagToURI(this.props.sourceTag)}`} className="other-gif-title">
                <FontIcon size="27px" name="icon-arrow-left-2" />
                <span className="page-media-other-controls__soource-tag">
                  {tagFromURI(this.props.sourceTag)}
                </span>
              </Link>
            )
          }

          {this.props.filter.section === 'search'
            && (
              <Link push to={`/s/${tagToURI(this.props.sourceTag)}`} className="other-gif-title">
                <FontIcon size="27px" name="icon-arrow-left-2" />
                <span className="page-media-other-controls__soource-tag">
                  {tagFromURI(this.props.sourceTag)}
                </span>
              </Link>
            )
          }

          {this.props.filter.section === 'popular'
            && (
              <Link push to="/" className="other-gif-title">
                <FontIcon size="27px" name="icon-arrow-left-2" />
                <span className="page-media-other-controls__soource-tag">
                  <FormattedMessage id="page_media.other_gif_popular" />
                </span>
              </Link>
            )
          }

          {this.props.filter.section === 'event'
            && (
              <Link push to={`/events/${this.props.filter.slug}`} className="other-gif-title">
                <FontIcon size="27px" name="icon-arrow-left-2" />
                <span className="page-media-other-controls__soource-tag">
                  {this.props.name}
                </span>
              </Link>
            )
          }

          {this.props.filter.section === 'smile' && (
            <Link push to={`/emoji/${this.props.filter.slug}`} className="other-gif-title">
              <FontIcon size="27px" name="icon-arrow-left-2" />
              <span className="page-media-other-controls__soource-tag">
                <img src={this.props.image} alt="return to the smile set" style={{ width: 35, marginRight: 10 }} />
                <FormattedMessage id="common.back" />
              </span>
            </Link>
          )}

          {this.props.filter.section === undefined
            && (
              <h2 className="other-gif-title">
                <FormattedMessage id="page_media.other_gif" />
              </h2>
            )
          }
        </div>
        {this.props.showAutoPlay && <AutoPlayButton />}
      </div>
    );
  }
}

OtherMediaControls.propTypes = {
  showAutoPlay: PropTypes.bool.isRequired,
  sourceTag: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
  filter: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  image: PropTypes.string,
  section: PropTypes.string,
};

OtherMediaControls.defaultProps = {
  section: undefined,
  name: undefined,
  image: undefined,
  sourceTag: undefined,
  filter: {},
};

export default OtherMediaControls;
