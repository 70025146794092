import { normalize } from 'normalizr';
import MediaSchema from '../../../schemas/media';
import request from '../index';
import parseMediaFilter from '../../parseMediaFilter';

export const getMediaList = async ({
  id, skip = 0, rnd = null, limit = process.env.REACT_APP_ON_PAGE, locale,
}) => {
  const filter = parseMediaFilter(id);
  const searchQuery = filter.query || filter.tag;
  let url = 'api/media?';
  if (filter.user && filter.type === 'uploaded') {
    url = `api/users/${filter.user}/media?`;
  } else if (filter.user && filter.type === 'favorites') {
    url = `api/users/${filter.user}/likes/media?`;
  } else if (filter.user && filter.type === 'touches') {
    url = `api/users/${filter.user}/touches?`;
  } else if (filter.section === 'event') {
    url = `api/events/${encodeURIComponent(filter.id)}/media?`;
  } else if (filter.section === 'smile') {
    url = `api/smiles/${encodeURIComponent(filter.id)}/media?`;
  } else if (searchQuery) {
    url = `api/search/media?q=${encodeURIComponent(searchQuery)}`;
  }

  let byMedia = '';
  if (filter.media) {
    byMedia = `&by_media=${filter.media}${rnd ? `&rnd=${rnd}` : ''}`;
  }

  const data = await request(`${url}&limit=${limit}&skip=${skip}&include=creator,tags.tag${byMedia}`, { locale });
  const filteredData = data.filter(item => !!item);
  return normalize(filteredData, [MediaSchema]);
};

export const getMedia = (id, locale) => (
  request(`api/media/${id}?include=creator,tags.tag`, {
    locale,
    normalize: MediaSchema,
  })
);

export const createMedia = data => (
  request('api/media', {
    data,
    method: 'post',
    normalize: MediaSchema,
  })
);

export const incStat = (data, locale) => (
  request('api/stats', {
    data,
    locale,
    method: 'post',
  })
);
