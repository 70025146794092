import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, FontIcon } from '../../Common';
import Tag from '../Tag';
import './index.css';

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      extend: false,
      maxWidth: undefined,
    };
  }

  componentDidMount() {
    if (this.props.isMobile) return;
    this.calcMaxWidth();
  }

  calcMaxWidth() {
    const maxWidth = this.container.clientWidth - 40;
    let tagsWidth = 0;
    for (let i = 0; i < this.container.children.length; i += 1) {
      const child = this.container.children[i];
      const nextWidth = tagsWidth + child.getBoundingClientRect().width;
      if (nextWidth > maxWidth) {
        this.setState({ showMore: true, maxWidth: tagsWidth });
        break;
      }
      tagsWidth = nextWidth;
    }
  }

  render() {
    const classes = [
      'page-media-tags',
      [this.state.showMore ? 'page-media-tags_show-more' : ''],
      [this.state.extend ? 'page-media-tags_extend' : ''],
    ].join(' ');

    return (
      <div className={classes} style={this.props.style}>
        <ul
          itemProp="keywords"
          className="tags"
          ref={el => (this.container = el)}
          style={{ maxWidth: this.state.maxWidth ? `${this.state.maxWidth}px` : undefined }}
        >
          {this.props.isLoaded && this.props.tags.map(({ tag }) => (
            <li className="tag" key={tag.id}>
              <Tag id={tag.id} rel="tag" />
            </li>
          ))}
          {!this.props.isLoaded && [...Array(8)].map((el, index) => (
            <li className="stub" key={index} /> // eslint-disable-line
          ))}
        </ul>

        {this.props.showMore && this.state.showMore && (
          <Button
            className="page-media-tags__show-more-button"
            size="small"
            shape="circle"
            type="transparent"
            onClick={() => this.setState({ showMore: false, extend: true, maxWidth: undefined })}
            icon={<FontIcon name="icon-point" size="27px" />}
          />
        )}
      </div>
    );
  }
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object, // eslint-disable-line
  isMobile: PropTypes.bool,
  showMore: PropTypes.bool,
  isLoaded: PropTypes.bool,
};

Tags.defaultProps = {
  tags: [],
  style: {},
  isMobile: false,
  showMore: true,
  isLoaded: false,
};

export default Tags;
