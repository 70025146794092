import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import MediaThumb from '../../components/Media/MediaThumb';
import { incMediaStat } from '../../actions/media';
import { likeMediaRequest } from '../../actions/likes';
import { openModal } from '../../actions/modal';
import AuthModal from '../AuthModal/index';
import { showAlert } from '../../actions/alertMessage';
import { send } from '../../utils/ws';
import { analyticsEvent } from '../../utils/gtm';

class MediaThumbContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClickCopy = this.handleClickCopy.bind(this);
    this.handleClickLike = this.handleClickLike.bind(this);
  }

  handleClickCopy(event) {
    event.stopPropagation();

    const message = ['click', this.props.mainTag || '-tsymbal-', this.props.id, this.props.locale].join('\n');
    send(message);

    if (this.props.mainTag) {
      analyticsEvent('ab', 'Использовал гифку после поиска');
    }

    this.props.incMediaStat({
      type: 'other',
      media: this.props.id,
      tag: this.props.mainTag,
    });

    this.props.showAlert('alert.url_copied');
  }

  handleClickLike(event) {
    event.stopPropagation();
    const isLiked = !this.props.liked;

    if (this.props.isAuth) {
      this.props.likeMediaRequest(this.props.id, isLiked);
    } else {
      this.props.openModal(AuthModal);
    }

    if (isLiked) {
      const message = ['click', this.props.mainTag || '-tsymbal-', this.props.id, this.props.locale].join('\n');
      send(message);

      if (this.props.mainTag) {
        analyticsEvent('ab', 'Использовал гифку после поиска');
      }

      this.props.incMediaStat({
        type: 'like',
        media: this.props.id,
        tag: this.props.mainTag,
      });
    }
  }

  render() {
    return (
      <MediaThumb
        {...this.props}
        onClickCopy={this.handleClickCopy}
        onClickLike={this.handleClickLike}
      />
    );
  }
}

MediaThumbContainer.propTypes = {
  id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  liked: PropTypes.bool,
  isAuth: PropTypes.bool,
  likeMediaRequest: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  incMediaStat: PropTypes.func.isRequired,
  mainTag: PropTypes.string,
  showAlert: PropTypes.func,
};

const mapStateTopProps = (state, props) => {
  const media = state.media.entities[props.id];
  let tags = get(state.media.entities, [props.id, 'tags']);
  const mainMediaTags = get(state.media.entities, [props.mainMedia, 'tags']);

  // tags order similar to main media
  if (tags && props.mainMedia && mainMediaTags) {
    tags = tags.sort((a, b) => {
      let ai = state.media.entities[props.mainMedia].tags.indexOf(a);
      let bi = state.media.entities[props.mainMedia].tags.indexOf(b);
      if (ai === -1) ai = 100;
      if (bi === -1) bi = 100;
      return ai - bi;
    });
  } else if (tags && props.mainTag && tags.includes(props.mainTag)) {
    // main tag (usually search query) to the top
    tags.splice(0, 0, tags.splice(tags.indexOf(props.mainTag), 1)[0]);
  }

  // for search engines show only 8 first tags
  if (typeof window === 'undefined' && tags) tags = tags.slice(0, 4);

  const server = media && media.file && state.uploadServers.data[media.file.server];
  const downloadURL = server ? `${server}/${props.id}.gif` : '';

  return {
    tags,
    liked: !!media.liked,
    isMobile: state.env.isMobile,
    isAuth: !!state.auth.myId,
    downloadURL,
    locale: state.intl.locale,
    safeStatus: media.safeStatus,

  };
};

const mapDispatchToProps = {
  incMediaStat,
  likeMediaRequest,
  openModal,
  showAlert,
};

export default connect(mapStateTopProps, mapDispatchToProps)(MediaThumbContainer);
