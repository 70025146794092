export const FETCH_SEARCH_HINTS_REQUEST = 'FETCH_SEARCH_HINTS_REQUEST';
export const FETCH_SEARCH_HINTS_FAILURE = 'FETCH_SEARCH_HINTS_FAILURE';
export const FETCH_SEARCH_HINTS_SUCCESS = 'FETCH_SEARCH_HINTS_SUCCESS';

export const fetchSearchHintsRequest = () => ({
  type: FETCH_SEARCH_HINTS_REQUEST,
});

export const fetchSearchHintsFailure = error => ({
  error,
  type: FETCH_SEARCH_HINTS_FAILURE,
});

export const fetchSearchHintsSuccess = hints => ({
  hints,
  type: FETCH_SEARCH_HINTS_SUCCESS,
});
