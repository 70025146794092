/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../assets/gifer_adv.svg';

class Adsense extends React.Component {
  componentDidMount() {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.error(error);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const banner = `<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-8501187329546963"
     data-ad-slot="${this.props.slot}"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>`;

    return (
      <div
        style={{
          overflow: 'hidden',
          minHeight: 280,
          background: `#161616 url('${IconSvg}') center no-repeat`,
        }}
        dangerouslySetInnerHTML={{ __html: banner }}
      />
    );
  }
}

Adsense.propTypes = {
  slot: PropTypes.number,
};

Adsense.defaultProps = {
  slot: 9628811922,
};

export default Adsense;
