import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
// import Modal from '../../../containers/Modal';
import { Button } from '../../Common';
import './index.css';

class ModalUploadGif extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: '' };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChangeUrl = this.handleChangeUrl.bind(this);
  }

  onButtonClick() {
    this.dropzone.open();
  }

  onDrop(files) {
    this.props.onSelect(files);
  }

  handleChangeUrl(event) {
    this.setState({ url: event.target.value });
  }

  handleKeyPress(event) {
    if (event.key !== 'Enter') return;
    this.props.onSelect([this.state.url]);
  }

  render() {
    return (
      <div className="upload-gif">
        {this.props.showTitle &&
          <div className="upload-gif__title">
            <FormattedMessage id="upload.tooltip_title" />
          </div>
        }

        <Dropzone
          disableClick
          style={{}}
          ref={(el) => { this.dropzone = el; }}
          onDrop={this.onDrop}
          accept={['image/gif', 'video/*']}
        >
          <Button
            block
            type="default"
            onClick={this.onButtonClick}
            size="s50"
          >
            <FormattedMessage id="upload.button_upload" />
          </Button>

          <div className="upload-gif__separator">
            <FormattedMessage id="or" />
          </div>

          <input
            className="upload-gif__input"
            type="text"
            placeholder={this.props.intl.formatMessage({ id: 'upload.placeholder_link' })}
            value={this.state.url}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChangeUrl}
            autoFocus={this.props.autoFocus}
          />
        </Dropzone>
      </div>
    );
  }
}

ModalUploadGif.propTypes = {
  intl: intlShape.isRequired,
  onSelect: PropTypes.func,
  showTitle: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

ModalUploadGif.defaultProps = {
  showTitle: false,
  autoFocus: false,
  onSelect: () => { },
};

export default injectIntl(ModalUploadGif);
