import PropTypes from 'prop-types';
import React from 'react';
import ModalProvider from '../../../containers/ModalProvider';
import AlertProvider from '../../../containers/AlertProvider';
import VerticalMenuMobile from '../../../containers/VerticalMenuMobile';
import HorizontalMenu from '../../../containers/HorizontalMenu';
import MobileSearch from '../MobileSearch';
import './Mobile.css';

const App = props => (
  <div>
    <AlertProvider />
    <ModalProvider />
    <div className="app mobile">
      {props.isMenuOpen && (
        <VerticalMenuMobile />
      )}
      <div className="app-content">
        <HorizontalMenu />
        <div className="app-content__search">
          <MobileSearch />
        </div>
        {props.children}
      </div>
    </div>
  </div>
);

App.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default App;
