import angrySvg from '../assets/smiles/angry.svg';
import cryingSvg from '../assets/smiles/crying.svg';
import facepalmSvg from '../assets/smiles/facepalm.svg';
import fuckYouSvg from '../assets/smiles/fuck-you.svg';
import lolSvg from '../assets/smiles/lol.svg';
import loveSvg from '../assets/smiles/love.svg';
import thumbsUpSvg from '../assets/smiles/thumbs-up.svg';
import shockSvg from '../assets/smiles/shock.svg';

const svgs = {
  angry: angrySvg,
  crying: cryingSvg,
  facepalm: facepalmSvg,
  lol: lolSvg,
  shock: shockSvg,
  love: loveSvg,
  'fuck-you': fuckYouSvg,
  'thumbs-up': thumbsUpSvg,
};

export default svgs;
