export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const OPEN_SEARCH = 'OPEN_SEARCH';
export const CLOSE_SEARCH = 'CLOSE_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const searchRequest = query => ({
  type: SEARCH_REQUEST,
  query,
});

export const searchFailure = error => ({
  type: SEARCH_FAILURE,
  error,
});

export const searchSuccess = matches => ({
  type: SEARCH_SUCCESS,
  matches,
});

export const openSearch = () => ({
  type: OPEN_SEARCH,
});

export const closeSearch = () => ({
  type: CLOSE_SEARCH,
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});
