import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Header } from '../../Common';
import AutoPlayButton from '../../../containers/AutoPlayButton';
import MediaMasonry from '../../../containers/MediaMasonry';

const PopularMedia = ({ intl }) => {
  const url = process.env.REACT_APP_DOMAIN;

  return (
    <div className="popular-media" itemScope itemType="http://schema.org/ImageGallery">
      <link itemProp="url" content={url} />
      <link itemProp="mainEntityOfPage" content={url} />
      <meta itemProp="keywords" content={intl.formatMessage({ id: 'page_home.article_keywords' })} />


      <div className="header">
        <div className="d1">
          <Header
            element="h1"
            title={
              <span itemProp="headline name">
                {intl.formatMessage({ id: 'page_home.popular_gifs' })}
              </span>
            }
          />

          {typeof window === 'undefined' &&
          <div className="sub-header">
            <FormattedMessage id="page_home.popular_gifs_desc" />
          </div>
          }

        </div>
        <AutoPlayButton />
      </div>
      <MediaMasonry save id="popular" />
    </div>
  );
};

PopularMedia.propTypes = {
  intl: intlShape,
};

export default injectIntl(PopularMedia);
