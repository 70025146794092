import { takeEvery, call, put, select } from 'redux-saga/effects';
import { getLocale } from '../selectors';
import * as actions from '../../actions/events';
import { getEvents, getEvent } from '../../utils/api/events';

function* fetchEvents() {
  try {
    const locale = yield select(getLocale);
    const data = yield call(getEvents, locale);
    yield put(actions.fetchEventsSuccess(data));
  } catch (error) {
    yield put(actions.fetchEventsFailure(error.message));
  }
}

function* fetchEvent(action) {
  try {
    const data = yield call(getEvent, action.slug);
    yield put(actions.fetchEventSuccess(data));
  } catch (error) {
    yield put(actions.fetchEventFailure(error.message));
  }
}

export function* watchEventsRequest() {
  yield takeEvery(actions.FETCH_EVENTS_REQUEST, fetchEvents);
}

export function* watchEventRequest() {
  yield takeEvery(actions.FETCH_EVENT_REQUEST, fetchEvent);
}
