import * as actions from '../../actions/autoPlay';

export default (state = false, action) => {
  switch (action.type) {
    case actions.ENABLE_AUTOPLAY:
      return true;
    case actions.DISABLE_AUTOPLAY:
      return false;
    default: return state;
  }
};
