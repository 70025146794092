import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initEnv, changeEnvVariable } from '../../actions/env';
import { initIntl } from '../../actions/intl';
import { initAuth } from '../../actions/auth';
import { fetchCategoriesRequest } from '../../actions/categories';
import { fetchUploadServersRequest } from '../../actions/uploadServers';
import App from '../../components/App';
import { initAnalytics } from '../../utils/gtm';
import { init as initWs } from '../../utils/ws';

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.initEnv();
    this.props.initIntl();
    this.props.initAuth();
    this.props.fetchCategoriesRequest();
    this.props.fetchUploadServersRequest();
  }

  componentDidMount() {
    initWs();
    initAnalytics();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    this.props.changeEnvVariable('width', window.innerWidth);
  }

  render() {
    return this.props.isReady
      ? <App {...this.props} />
      : null;
  }
}

AppContainer.propTypes = {
  isReady: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  initEnv: PropTypes.func.isRequired,
  initIntl: PropTypes.func.isRequired,
  initAuth: PropTypes.func.isRequired,
  fetchCategoriesRequest: PropTypes.func.isRequired,
  fetchUploadServersRequest: PropTypes.func.isRequired,
  changeEnvVariable: PropTypes.func.isRequired,
};

AppContainer.defaultProps = {
  isReady: false,
};

const mapStateToProps = (state) => {
  const { locale } = state.intl;
  const messages = state.intl.messages[locale];
  const isReady = messages && state.categories.fetch.isLoaded && state.uploadServers.isLoaded;

  return {
    messages,
    locale,
    isReady,
    isMobile: state.env.isMobile,
  };
};

const mapDispatchToProps = {
  initEnv,
  initIntl,
  initAuth,
  fetchCategoriesRequest,
  fetchUploadServersRequest,
  changeEnvVariable,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
