import * as actions from '../../actions/likes';

export default (state = {}, action) => {
  if (action.entities && action.entities.media) {
    return {
      ...state,
      ...action.entities.media,
    };
  }

  switch (action.type) {
    case actions.LIKE_MEDIA_REQUEST:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          liked: action.like,
        },
      };

    case actions.LIKE_MEDIA_FAILURE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          liked: !action.like,
        },
      };
    default: return state;
  }
};
