import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserPanel from '../../containers/PageUser/UserPanel';
import PartTouches from '../../containers/PageUser/PartTouches';
import PartUploaded from '../../containers/PageUser/PartUploaded';
import PartFavorites from '../../containers/PageUser/PartFavorites';
import Controls from './Controls';
import './index.css';
import AnalyticsPageView from '../AnalyticsPageView';

const PageUser = props => (
  <div className={`page-user ${props.isMobile ? 'page-user_mobile' : 'page-user_desktop'}`}>
    <Helmet>
      <meta name="robots" content="noindex, follow" />
    </Helmet>
    <AnalyticsPageView />
    <UserPanel
      user={props.match.params.id}
      isOwner={props.isOwner}
    />
    {props.isOwner
      && (
      <div className="page-user__controls">
        <Controls user={props.match.params.id} />
      </div>
      )}
    <Route exact path="/user/:id/touches" component={PartTouches} />
    <Route exact path="/user/:id" component={PartUploaded} />
    <Route exact path="/user/:id/favorites" component={PartFavorites} />
  </div>
);

PageUser.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired, // eslint-disable-line
};

export default PageUser;
