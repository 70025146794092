import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import FontIcon from '../../Common/FontIcon';
import Media from '../../../containers/Media';
import Tags from '../../../containers/PageMedia/Tags';
import User from '../../../containers/PageMedia/User';
import MasonryObserver from '../../MasonryObserver';
import OtherMediaControls from '../../../containers/PageMedia/OtherMediaControls';
import Controls from '../../../containers/PageMedia/Controls';
import SocialButtons from '../SocialButtons';
import SwipeMedia from '../SwipeMedia';
import ActionAnimation from '../../ActionAnimation';
import FullscreenView from '../FullscreenView';
import TooltipBlame from '../TooltipBlame';
import { Button } from '../../Common';
import Adsense from '../../Adsense';
import { send } from '../../../utils/ws';
import { analyticsEvent } from '../../../utils/gtm';
import './Desktop.css';

class PageMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animationAction: undefined,
      fullscreen: false,
      showMasonry: typeof window === 'undefined',
      blameTooltipDisplay: false,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)) {
      return;
    }

    switch (event.keyCode) {
      case 38:
      case 87: {
        event.preventDefault();
        this.setState({ fullscreen: true });
        break;
      }

      case 27:
      case 40:
      case 83: {
        if (this.state.fullscreen === false) break;
        event.preventDefault();
        this.setState({ fullscreen: false });
        break;
      }

      case 37:
      case 65: {
        event.preventDefault();
        this.goPrev();
        break;
      }

      case 39:
      case 68: {
        event.preventDefault();
        this.goNext();
        break;
      }

      default:
        break;
    }
  }

  goNext() {
    if (!this.props.nextMediaId) return;
    this.props.goTo(`/${this.props.nextMediaId}`);
  }

  goPrev() {
    if (!this.props.prevMediaId) return;
    this.props.goTo(`/${this.props.prevMediaId}`);
  }

  render() {
    return (
      <div className="page-media desktop">
        {this.state.fullscreen && (
          <FullscreenView
            server={this.props.server}
            id={this.props.id}
            onClickPrev={this.goNext}
            onClickNext={this.goPrev}
            clickMedia={() => this.setState({ fullscreen: false })}
          />
        )}

        {this.props.isLoaded && (
          <>
            <main
              itemProp="mainEntity"
              itemScope
              itemType="http://schema.org/ImageObject"
            >
              <article>
                {typeof window === 'undefined' && (
                  <h1 itemProp="name caption">
                    {this.props.intl.formatMessage(
                      { id: `${this.props.textKey}.h1` },
                      this.props.textValues,
                    )}
                  </h1>
                )}

                <div className="image-block">
                  <div className="image">
                    <meta itemProp="representativeOfPage" content="true" />
                    <meta
                      itemProp="url"
                      content={`/${this.props.intl.locale}${
                        this.props.location.pathname
                      }`}
                    />
                    {this.state.animationAction && (
                      <div className="page-media__image-animation">
                        <ActionAnimation
                          {...this.state.animationAction}
                          onAnimationEnd={() =>
                            this.setState({ animationAction: undefined })}
                        />
                      </div>
                    )}
                    <figure>
                      <div className="bg">
                        <SwipeMedia
                          id={this.props.id}
                          isMobile={false}
                          gotoPrevPost={this.goPrev}
                          gotoNextPost={this.goNext}
                          showPrevButton={!!this.props.prevMediaId}
                          showNextButton={!!this.props.nextMediaId}
                        >
                          <Media
                            main
                            onError={() => this.setState({ showMasonry: true })}
                            onLoad={() => this.setState({ showMasonry: true })}
                            onContextMenu={this.props.onContextMenu}
                            id={this.props.id}
                            textValues={this.props.textValues}
                            alt={this.props.intl.formatMessage(
                              { id: `${this.props.textKey}.imageAlt` },
                              this.props.textValues,
                            )}
                          />
                        </SwipeMedia>
                      </div>
                      {typeof window === 'undefined' && (
                        <figcaption className="desc" itemProp="description">
                          {this.props.intl.formatMessage(
                            { id: `${this.props.textKey}.image_description` },
                            this.props.textValues,
                          )}
                        </figcaption>
                      )}
                    </figure>
                  </div>
                  <aside className="image-right-block">
                    {typeof window === 'undefined' && (
                      <div>
                        <div className="image-size">
                          {this.props.intl.formatMessage({
                            id: 'page_media.resolution',
                          })}
                          :&nbsp;
                          <span itemProp="width">{this.props.width}</span>
                          &nbsp;x&nbsp;
                          <span itemProp="height">{this.props.height}</span>
                          px
                        </div>
                      </div>
                    )}
                    <Controls
                      id={this.props.id}
                      onClickEmbed={() => {
                        const message = [
                          'click',
                          this.props.sourceTag || '-tsymbal-',
                          this.props.id,
                          this.props.intl.locale,
                        ].join('\n');

                        send(message);

                        if (this.props.sourceTag) {
                          analyticsEvent('ab', 'Использовал гифку после поиска');
                        }
                      }}
                      onLike={() => {
                        const message = [
                          'click',
                          this.props.sourceTag || '-tsymbal-',
                          this.props.id,
                          this.props.intl.locale,
                        ].join('\n');
                        send(message);

                        if (this.props.sourceTag) {
                          analyticsEvent('ab', 'Использовал гифку после поиска');
                        }

                        this.props.incStat({
                          type: 'other',
                          media: this.props.id,
                          tag: this.props.sourceTag,
                        });
                        this.setState({
                          animationAction: {
                            icon: <FontIcon name="icon-like" color="#ef4a4a" />,
                            text: (
                              <FormattedMessage id="page_media.animation_added" />
                            ),
                          },
                        });
                      }}
                      onCopyUrl={() => {
                        const message = [
                          'click',
                          this.props.sourceTag || '-tsymbal-',
                          this.props.id,
                          this.props.intl.locale,
                        ].join('\n');
                        send(message);

                        if (this.props.sourceTag) {
                          analyticsEvent('ab', 'Использовал гифку после поиска');
                        }

                        this.props.incStat({
                          type: 'other',
                          media: this.props.id,
                          tag: this.props.sourceTag,
                        });
                      }}
                      onClickDownload={() => {
                        const message = [
                          'click',
                          this.props.sourceTag || '-tsymbal-',
                          this.props.id,
                          this.props.intl.locale,
                        ].join('\n');
                        send(message);

                        if (this.props.sourceTag) {
                          analyticsEvent('ab', 'Использовал гифку после поиска');
                        }

                        this.props.incStat({
                          type: 'other',
                          media: this.props.id,
                          tag: this.props.sourceTag,
                        });
                      }}
                    />

                    <div className="page-media__social">
                      <SocialButtons
                        {...this.props}
                        onClick={() => {
                          const message = [
                            'click',
                            this.props.sourceTag || '-tsymbal-',
                            this.props.id,
                            this.props.intl.locale,
                          ].join('\n');
                          send(message);

                          if (this.props.sourceTag) {
                            analyticsEvent(
                              'ab',
                              'Использовал гифку после поиска',
                            );
                          }

                          this.props.incStat({
                            type: 'other',
                            media: this.props.id,
                            tag: this.props.sourceTag,
                          });
                        }}
                      />
                    </div>
                    {this.props.creator && <User id={this.props.creator} />}

                    <div className="page-media__blame">
                      <TooltipBlame
                        onOpen={() =>
                          this.setState({ blameTooltipDisplay: true })}
                        onClose={() =>
                          this.setState({ blameTooltipDisplay: false })}
                        mediaId={this.props.id}
                      >
                        <Button
                          className={`page-media__blame-button ${
                            this.state.blameTooltipDisplay
                              ? 'page-media__blame-button_active'
                              : ''
                          }`}
                          icon={<FontIcon name="icon-flag" size={16} />}
                          size="small"
                          type="transparent"
                        >
                          <FormattedMessage id="page-media.blame-text" />
                        </Button>
                      </TooltipBlame>
                    </div>
                  </aside>
                </div>
              </article>
            </main>

            <section className="page-media__tags">
              {typeof window === 'undefined' && (
                <h2 style={{ marginBottom: 10 }}>
                  {this.props.intl.formatMessage({
                    id: 'page_media.tags_title',
                  })}
                </h2>
              )}
              <Tags key={`tags-${this.props.id}`} id={this.props.id} isLoaded />
            </section>

            {this.props.ad && (
              <div className="page-media__banner" key={this.props.id}>
                <Adsense slot={3075011861} />
              </div>
            )}

            <section className="other-media">
              <OtherMediaControls
                key={`other-media-${this.props.id}`}
                sourceTag={this.props.sourceTag}
                showAutoPlay
              />
              {this.state.showMasonry && (
                <div
                  className={`page-media__media-masonry page-media__media-masonry_section_${
                    this.props.section
                  }`}
                >
                  <MasonryObserver
                    locale={this.props.intl.locale}
                    key={this.props.id}
                    id={this.props.mediaFilter}
                    mainMedia={this.props.id}
                    statPayload={{ tag: this.props.sourceTag }}
                    contextMenuEvent={`desktop_right_button_gif_${
                      this.props.section
                    }`}
                    offset={500}
                  />
                </div>
              )}
            </section>
          </>
        )}
      </div>
    );
  }
}

PageMedia.propTypes = {
  intl: intlShape,
  textValues: PropTypes.object, // eslint-disable-line
  textKey: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  prevMediaId: PropTypes.string,
  nextMediaId: PropTypes.string,
  creator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mediaFilter: PropTypes.string,
  sourceTag: PropTypes.string,
  goTo: PropTypes.func.isRequired,
  incStat: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  server: PropTypes.string,
  section: PropTypes.string,
};

PageMedia.defaultProps = {
  intl: undefined,
  textKey: undefined,
  width: undefined,
  height: undefined,
  prevMediaId: undefined,
  nextMediaId: undefined,
  creator: undefined,
  mediaFilter: undefined,
  sourceTag: undefined,
  server: undefined,
  section: undefined,
};

export default injectIntl(PageMedia);
