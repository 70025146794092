import { connect } from 'react-redux';
import User from '../../components/PageMedia/User';

const mapStateToProps = (state, props) => {
  const user = state.users.entities[props.id] || {};
  return {
    ...user,
    name: user.username,
    href: `/user/${user.id}`,
  };
};

export default connect(mapStateToProps)(User);
