import { takeEvery, put } from 'redux-saga/effects';
import * as actions from '../../actions/env';
import * as autoplayActions from '../../actions/autoPlay';
import getClientType from '../../utils/getClientType';
import speed from '../../utils/detectspeed';
import storage from '../../utils/localStorage';
import gtm from '../../utils/gtm';

function* initEnv() {
  // redirect from ?search=... to the /s/...
  const searchParamMatches = /search=([^&]+)/.exec(window.location.search || '');
  if (searchParamMatches && searchParamMatches[1]) {
    window.location.href = `${window.location.origin}/s/${searchParamMatches[1]}`;
    return;
  }

  let isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone|mobile/i.test(navigator.userAgent);
  isMobile = isMobile || (window && (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch))); // eslint-disable-line

  yield put(actions.changeEnvVariable('isMobile', !!isMobile));
  yield put(actions.changeEnvVariable('width', window.innerWidth));
  yield put(actions.changeEnvVariable('height', window.innerHeight));
  yield put(actions.changeEnvVariable('url', window.location.href));
  yield put(actions.changeEnvVariable('clientType', getClientType(navigator.userAgent)));
  yield put(actions.changeEnvVariable('speed', speed));

  const minimalConnectionSpeed = 8;
  const storageAutoplay = JSON.parse(storage.getItem('autoplay'));
  const autoAutoplay = storageAutoplay === null && speed >= minimalConnectionSpeed;
  if (storageAutoplay === true) {
    yield put(autoplayActions.enable());
  } else if (autoAutoplay) {
    yield put(autoplayActions.enable());
    gtm({ event: 'auto_autoplay' });
  }
}

export function* watchInitEnv() {
  yield takeEvery(actions.INIT_ENV, initEnv);
}
