import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import FontIcon from '../../Common/FontIcon';
import Media from '../../../containers/Media';
import Tags from '../../../containers/PageMedia/Tags';
import User from '../../../containers/PageMedia/User';
import MasonryObserver from '../../MasonryObserver';
import OtherMediaControls from '../../../containers/PageMedia/OtherMediaControls';
import Controls from '../../../containers/PageMedia/Controls';
import SocialButtons from '../SocialButtons';
import SwipeMedia from '../SwipeMedia';
import ActionAnimation from '../../ActionAnimation';
import Adsense from '../../Adsense';
import { send } from '../../../utils/ws';
import { analyticsEvent } from '../../../utils/gtm';
import './Mobile.css';

class PageMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animationAction: undefined,
      showMasonry: typeof window === 'undefined',
      blameTooltipDisplay: false,
    };

    if (typeof window !== 'undefined') {
      this.maxHeight = window.innerHeight - 150;
    }
  }

  render() {
    return (
      <div className="page-media mobile">
        <main
          itemProp="mainEntity"
          itemScope
          itemType="http://schema.org/ImageObject"
        >
          <article>
            <meta itemProp="representativeOfPage" content="true" />
            <meta
              itemProp="url"
              content={`/${this.props.intl.locale}${this.props.location.pathname}`}
            />

            {typeof window === 'undefined' && (
              <h1 itemProp="name caption">
                <FormattedMessage
                  id={`${this.props.textKey}.h1`}
                  values={this.props.textValues}
                />
              </h1>
            )}

            <div className="page-media__swipe">
              {this.state.animationAction && (
                <div className="page-media__image-animation">
                  <ActionAnimation
                    {...this.state.animationAction}
                    onAnimationEnd={() =>
                      this.setState({ animationAction: undefined })}
                  />
                </div>
              )}

              <figure>
                <SwipeMedia
                  isMobile
                  id={this.props.id}
                  gotoPrevPost={() => this.props.goTo(`/${this.props.prevMediaId}`)}
                  gotoNextPost={() => this.props.goTo(`/${this.props.nextMediaId}`)}
                  showPrevButton={!!this.props.prevMediaId}
                  showNextButton={!!this.props.nextMediaId}
                >
                  <Media
                    main
                    id={this.props.id}
                    onContextMenu={this.props.onContextMenu}
                    onError={() => this.setState({ showMasonry: true })}
                    onLoad={() => this.setState({ showMasonry: true })}
                    maxHeight={this.maxHeight}
                  />
                </SwipeMedia>

                {typeof window === 'undefined' && (
                  <figcaption className="desc" itemProp="description">
                    {this.props.intl.formatMessage(
                      { id: `${this.props.textKey}.image_description` },
                      this.props.textValues,
                    )}
                  </figcaption>
                )}
              </figure>
              <aside>
                {typeof window === 'undefined' && (
                  <div className="dimensions">
                    <div className="image-size">
                      {this.props.intl.formatMessage({ id: 'page_media.resolution' })}
                      :&nbsp;
                      <span itemProp="width">{this.props.width}</span>
                      &nbsp;x&nbsp;
                      <span itemProp="height">{this.props.height}</span>
                      px
                    </div>
                  </div>
                )}

                {this.props.isLoaded && (
                  <>
                    <div
                      className={`page-media__scrollable-content ${
                        this.state.blameTooltipDisplay
                          ? 'page-media__scrollable-content_disable'
                          : ''
                      }`}
                    >
                      <div className="page-media__controls">
                        <Controls
                          onClickEmbed={() => {
                            const message = [
                              'click',
                              this.props.sourceTag || '-tsymbal-',
                              this.props.id,
                              this.props.intl.locale,
                            ].join('\n');
                            send(message);

                            if (this.props.sourceTag) {
                              analyticsEvent('ab', 'Использовал гифку после поиска');
                            }
                          }}
                          onBlameTooltipOpen={() =>
                            this.setState({ blameTooltipDisplay: true })}
                          onBlameTooltipClose={() =>
                            this.setState({ blameTooltipDisplay: false })}
                          id={this.props.id}
                          onLike={() => {
                            const message = [
                              'click',
                              this.props.sourceTag || '-tsymbal-',
                              this.props.id,
                              this.props.intl.locale,
                            ].join('\n');
                            send(message);

                            if (this.props.sourceTag) {
                              analyticsEvent('ab', 'Использовал гифку после поиска');
                            }

                            this.props.incStat({
                              type: 'other',
                              media: this.props.id,
                              tag: this.props.sourceTag,
                            });
                            this.setState({
                              animationAction: {
                                icon: <FontIcon name="icon-like" color="#ef4a4a" />,
                                text: (
                                  <FormattedMessage id="page_media.animation_added" />
                                ),
                              },
                            });
                          }}
                          onCopyUrl={() => {
                            const message = [
                              'click',
                              this.props.sourceTag || '-tsymbal-',
                              this.props.id,
                              this.props.intl.locale,
                            ].join('\n');
                            send(message);

                            if (this.props.sourceTag) {
                              analyticsEvent('ab', 'Использовал гифку после поиска');
                            }

                            this.props.incStat({
                              type: 'other',
                              media: this.props.id,
                              tag: this.props.sourceTag,
                            });
                          }}
                          onClickDownload={() => {
                            const message = [
                              'click',
                              this.props.sourceTag || '-tsymbal-',
                              this.props.id,
                              this.props.intl.locale,
                            ].join('\n');

                            send(message);

                            if (this.props.sourceTag) {
                              analyticsEvent('ab', 'Использовал гифку после поиска');
                            }

                            this.props.incStat({
                              type: 'other',
                              media: this.props.id,
                              tag: this.props.sourceTag,
                            });
                          }}
                        />
                      </div>
                      <SocialButtons
                        isMobile
                        {...this.props}
                        onClick={() => {
                          const message = [
                            'click',
                            this.props.sourceTag || '-tsymbal-',
                            this.props.id,
                            this.props.intl.locale,
                          ].join('\n');
                          send(message);

                          if (this.props.sourceTag) {
                            analyticsEvent('ab', 'Использовал гифку после поиска');
                          }

                          this.props.incStat({
                            type: 'other',
                            media: this.props.id,
                            tag: this.props.sourceTag,
                          });
                        }}
                      />
                    </div>
                    {this.props.creator && <User id={this.props.creator} />}
                  </>
                )}
              </aside>
            </div>
          </article>
        </main>
        {this.props.isLoaded && (
          <>
            <section className="page-media__tags">
              <Tags id={this.props.id} key={`tags-${this.props.id}`} isMobile isLoaded />
            </section>

            {this.props.ad && (
              <div className="page-media__banner">
                <Adsense slot={3075011861} />
              </div>
            )}

            <section>
              <OtherMediaControls
                key={`other-media-${this.props.id}`}
                style={{ marginTop: 20 }}
                sourceTag={this.props.sourceTag}
                showAutoPlay
              />

              {this.state.showMasonry && (
                <div
                  className={`page-media__media-masonry page-media__media-masonry_section_${
                    this.props.section
                  }`}
                >
                  <MasonryObserver
                    locale={this.props.intl.locale}
                    key={this.props.id}
                    id={this.props.mediaFilter}
                    mainMedia={this.props.id}
                    statPayload={{ tag: this.props.sourceTag }}
                    contextMenuEvent={`mobile_copy_image_gif_${
                      this.props.section
                    }`}
                    offset={500}
                  />
                </div>
              )}
            </section>
          </>
        )}
      </div>
    );
  }
}

PageMedia.propTypes = {
  intl: intlShape,
  isLoaded: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  textKey: PropTypes.string,
  prevMediaId: PropTypes.string,
  nextMediaId: PropTypes.string,
  sourceTag: PropTypes.string,
  mediaFilter: PropTypes.string,
  creator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textValues: PropTypes.object, // eslint-disable-line
  goTo: PropTypes.func.isRequired,
  incStat: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  section: PropTypes.string,
};

PageMedia.defaultProps = {
  intl: undefined,
  textKey: undefined,
  prevMediaId: undefined,
  nextMediaId: undefined,
  sourceTag: undefined,
  mediaFilter: undefined,
  creator: undefined,
  section: undefined,
};

export default injectIntl(PageMedia);
