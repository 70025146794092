import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import PageEvent from '../../components/PageEvent';
import { fetchEventRequest } from '../../actions/events';

class PageEventContainer extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.isLoaded === false) {
      this.props.fetchEventRequest(this.props.id);
    }
  }

  render() {
    return <PageEvent {...this.props} />;
  }
}

PageEventContainer.propTypes = {
  id: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  fetchEventRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;
  const event = state.events.data.find(x => x.slug === id);
  const listId = event ? `event/${event.id}/${event.slug}/${event.statQuery}` : undefined;
  const serverId = get(event, 'cover.file.server');
  const server = state.uploadServers.data[serverId];

  return {
    isLoaded: !!event,
    ...event,
    id,
    isMobile: state.env.isMobile,
    locale: state.intl.locale,
    server,
    listId,
  };
};

const mapDispatchToProps = {
  fetchEventRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEventContainer);
