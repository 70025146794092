import React from 'react';
import debounce from 'lodash/debounce';
import MediaMasonry from '../../containers/MediaMasonry';
import { send } from '../../utils/ws';

class MasonryObserver extends React.Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = debounce(this.handleVisibleChange.bind(this), 100);
    this.wereVisible = [];
  }

  handleVisibleChange(visibleCells, data) {
    const { tag: query } = this.props.statPayload || {};
    const visibleIds = visibleCells.map(cell => data[cell.index]);
    const newVisibleIds = visibleIds.filter(x => !this.wereVisible.includes(x));
    if (!newVisibleIds.length) return;

    const message = ['view', query || '-tsymbal-', newVisibleIds.join(','), this.props.locale].join('\n');

    send(message);
    this.wereVisible = [...this.wereVisible, ...newVisibleIds];
  }

  render() {
    return <MediaMasonry {...this.props} onVisibleChange={this.handleVisibleChange} />;
  }
}

export default MasonryObserver;
