import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, FontIcon } from '../../Common';
import Modal from '../../Modal';
import ShareButtons from '../../ShareButtons';
import EmbedForm from '../../../containers/EmbedModal/EmbedForm';
import './index.css';

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: 'main',
      copied: false,
    };
  }

  handleClickCopy = () => {
    this.setState({ copied: true });
    this.props.onClickCopy();
    setTimeout(() => this.setState({ copied: false }), 2000);
  }

  render() {
    return (
      <div className="page-embed-share">
        <Modal closeModal={this.props.onCloseRequest}>
          <div className="page-embed-share__content">
            {this.state.section !== 'main' && (
              <div className="page-embed-share__back-button">
                <Button
                  onClick={() => this.setState({ section: 'main' })}
                  type="transparent"
                  icon={<FontIcon name="icon-arrow-left-2" color="white" size="24px" />}
                />
              </div>
            )}

            {(this.state.section === 'main' || this.state.section === 'share') && (
              <div className={`page-embed-share__socials ${this.state.section === 'share' ? 'page-embed-share__socials_extended' : ''}`}>
                <ShareButtons pageUrl={this.props.gifSrc} />

                {this.state.section === 'main' && (
                  <Button
                    type="black-transparent"
                    onClick={() => this.setState({ section: 'share' })}
                    className="page-embed-share__share-more-button"
                    size="s50"
                    icon={<FontIcon name="icon-point" />}
                  />
                )}
              </div>
            )}

            {this.state.section === 'main' && (
              <div className="page-embed-share__actions">
                <Button
                  type="black-transparent"
                  href={this.props.downloadUrl}
                  onClick={this.props.onClickDownload}
                  nativeProps={{ download: true }}
                  icon={<FontIcon name="icon-download" />}
                  size="small"
                  style={{ height: 40 }}
                  block
                  external
                >
                  <FormattedMessage id="page-embed-share.download-gif" />
                </Button>

                <CopyToClipboard text={this.props.gifSrc}>
                  <Button
                    onClick={this.handleClickCopy}
                    type="black-transparent"
                    icon={<FontIcon name="icon-copy-url" />}
                    size="small"
                    style={{ height: 40 }}
                    block
                  >
                    {this.state.copied
                      ? <FormattedMessage id="page-embed-share.copy-gif-done" />
                      : <FormattedMessage id="page-embed-share.copy-gif" />}
                  </Button>
                </CopyToClipboard>

                <Button
                  type="transparent"
                  className="page-embed-share__embed-button"
                  icon={<FontIcon name="icon-embed" />}
                  size="small"
                  style={{ height: 40 }}
                  onClick={() => this.setState({ section: 'embed' })}
                  block
                >
                  <FormattedMessage id="page-embed-share.embed" />
                </Button>
              </div>
            )}

            {this.state.section === 'embed' && (
              <div className="page-embed-share__embed">
                <EmbedForm id={this.props.id} />
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

Share.propTypes = {
  id: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired,
  gifSrc: PropTypes.string.isRequired,
  onClickDownload: PropTypes.func,
  onClickCopy: PropTypes.func,
  onCloseRequest: PropTypes.func,
};

Share.defaultProps = {
  onClickDownload: () => { },
  onClickCopy: () => { },
  onCloseRequest: () => { },
};

export default Share;
