import React from 'react';
import ClientMasonry from './ClientMasonry';
import ServerMasonry from './ServerMasonry';

const Masonry = (props) => {
  if (typeof window === 'undefined') {
    return <ServerMasonry {...props} />;
  }
  return <ClientMasonry {...props} />;
};

export default Masonry;
