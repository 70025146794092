import PropTypes from 'prop-types';
import React from 'react';

const ModalProvider = (props) => {
  const Modal = props.component;
  return (
    Modal
    ? <Modal {...props.modalProps} />
    : null
  );
};

ModalProvider.propTypes = {
  modalProps: PropTypes.object, // eslint-disable-line
  component: PropTypes.func,
};

export default ModalProvider;
