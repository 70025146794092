export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const CATEGORY_TAGS_REQUEST = 'CATEGORY_TAGS_REQUEST';
export const CATEGORY_TAGS_SUCCESS = 'CATEGORY_TAGS_SUCCESS';
export const CATEGORY_TAGS_FAILURE = 'CATEGORY_TAGS_FAILURE';

export const fetchCategoriesRequest = () => ({
  type: FETCH_CATEGORIES_REQUEST,
});

export const fetchCategoriesSuccess = (ids, entities) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  ids,
  entities,
});

export const fetchCategoriesFailure = error => ({
  type: FETCH_CATEGORIES_FAILURE,
  error,
});

export const categoryTagsRequest = categoryId => ({
  type: CATEGORY_TAGS_REQUEST,
  categoryId,
});

export const categoryTagsSuccess = (categoryId, ids, entities) => ({
  type: CATEGORY_TAGS_SUCCESS,
  categoryId,
  ids,
  entities,
});

export const categoryTagsFailure = (categoryId, error) => ({
  type: CATEGORY_TAGS_FAILURE,
  categoryId,
  error,
});
