import PropTypes from 'prop-types';
import React from 'react';

class GridSize extends React.Component {
  componentDidMount() {
    this.handleReceiveDimensions();
    window.addEventListener('resize', this.handleReceiveDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleReceiveDimensions);
  }

  handleReceiveDimensions = () => {
    const offset = this.ref.getBoundingClientRect();
    const top = offset.top + window.scrollY;
    const collCount = Math.min(
      Math.floor(offset.width / this.props.minWidth),
      this.props.maxCollCount,
    );
    const cellWidth = (offset.width - (this.props.gutter * (collCount - 1))) / collCount;

    if (collCount !== this.collCount || cellWidth !== this.cellWidth || top !== this.top) {
      this.collCount = collCount;
      this.cellWidth = cellWidth;
      this.top = top;
      this.props.onChange({ collCount, cellWidth, top });
    }
  }

  render() {
    return (
      <div ref={el => this.ref = el} style={{ height: 1, opacity: 0 }} />
    );
  }
}

GridSize.propTypes = {
  maxCollCount: PropTypes.number,
  minWidth: PropTypes.number,
  gutter: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

GridSize.defaultProps = {
  minWidth: 150,
  maxCollCount: 4,
  gutter: 15,
};

export default GridSize;
