import uniq from 'lodash/uniq';

export default ({ media = {}, creator }) => {
  const textValues = {};

  // tags vars
  let tags = [];
  if (media.tags) {
    tags = media.tags.map(({ tag }) => tag.id);
    tags = uniq(tags);
  }

  for (let i = 1, j = 0; i < 11; i++, j++) { // eslint-disable-line no-plusplus
    const tag = tags[j];
    const Tag = tag ? `${tag.charAt(0).toUpperCase()}${tag.slice(1)}` : '';
    textValues[`tag${i}`] = tag ? `${tag}, ` : '';
    textValues[`Tag${i}`] = tag ? `${Tag}, ` : '';
    textValues[`tag${i}_s`] = tag ? `${tag} ` : '';
    textValues[`Tag${i}_s`] = tag ? `${Tag} ` : '';
  }
  textValues.tag11_ = tags.slice(10).join(', '); // eslint-disable-line no-underscore-dangle
  textValues.tag11_ = textValues.tag11_ ? `${textValues.tag11_} ` : textValues.tag11_;  // eslint-disable-line no-underscore-dangle
  textValues.tags = tags.join(', ');
  textValues.tags = textValues.tags ? `${textValues.tags} ` : textValues.tags;

  // image size vars
  textValues.x = media.width || '';
  textValues.y = media.height || '';

  // user
  textValues.user = creator ? creator.username : '';
  return textValues;
};
