import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SmilesPanel from '../../components/SmilesPanel';
import { fetchSmilesRequest } from '../../actions/smiles';

class SmilesPanelContainer extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.isLoaded === false) {
      this.props.fetchSmilesRequest();
    }
  }

  render() {
    return <SmilesPanel {...this.props} />;
  }
}

SmilesPanelContainer.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  fetchSmilesRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ...state.smiles,
  locale: state.intl.locale,
});

const mapDispatchToProps = {
  fetchSmilesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmilesPanelContainer);
