import request from '../index';
import TagSchema from '../../../schemas/tag';

export const search = (query, locale) =>
  request(`api/search/autocomplete?q=${query}`, {
    locale,
  });

export const getHints = locale =>
  request('api/tags?type=search_hints', {
    locale,
    normalize: [TagSchema],
  });
