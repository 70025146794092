import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontIcon } from '../../Common';
import './index.css';

const Category = props => (
  <li className={props.className}>
    <Link to={props.to}>
      {props.icon}
      {props.text}
    </Link>
  </li>
);

Category.propTypes = {
  icon: PropTypes.element,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Category.dedaultProps = {
  className: '',
};

const Categories = props => (
  <ul className="vertical-menu-categories">
    {props.isAuth &&
      <Category
        icon={<FontIcon name="icon-like" color="#c65656" size={24} />}
        className="main"
        to={`/user/${props.myId}/favorites`}
        text={<FormattedMessage id="menu.favorites" />}
      />
    }
    <Category
      icon={<FontIcon name="icon-reactions" color="#fdca47" size={24} />}
      className="main last-main"
      to={`/category/${props.specialCategory.slug}`}
      text={props.specialCategory.name}
    />

    {props.categories.map(category => (
      <Category
        key={category.id}
        to={`/category/${category.slug}`}
        text={category.name}
      />
    ))}
  </ul>
);

Categories.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  specialCategory: PropTypes.object.isRequired,
  myId: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Categories;
