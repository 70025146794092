import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, FontIcon } from '../../Common';
import UserPanel from '../../../containers/UserPanel';
import ModalUploadGif from '../../../containers/UploadGif/Modal';
import './Desktop.css';

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = { showTooltip: false };
    this.handleClick = this.handleClick.bind(this);
    this.handleClickUpload = this.handleClickUpload.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) { // eslint-disable-line
      this.setState({ showTooltip: false });
    }
  }

  handleClickUpload(event) {
    event.stopPropagation();
    if (!this.props.isAuth) {
      this.props.openAuthPopup();
    } else if (this.state.showTooltip) {
      this.closeTooltip();
    } else {
      this.openTooltip();
    }
  }

  handleClick(event) {
    if (!this.tooltip) return;
    const inside = this.tooltip.contains(event.target);
    if (inside === false) this.closeTooltip();
  }

  openTooltip() {
    this.setState({ showTooltip: true });
    window.addEventListener('click', this.handleClick);
  }

  closeTooltip() {
    this.setState({ showTooltip: false });
    window.removeEventListener('click', this.handleClick);
  }

  render() {
    return (
      <header className="hor-menu desktop">
        <div className="controls">
          <UserPanel />
          <div className="hor-menu__upload">
            <Button
              className={`hor-menu__upload-button ${this.state.showTooltip ? 'hor-menu__upload-button_active' : ''}`}
              icon={<FontIcon name="icon-plus-web" color="#fff" size={27} />}
              size="small"
              onClick={this.handleClickUpload}
            >
              <FormattedMessage id="menu.upload_button" />
            </Button>

            {this.state.showTooltip && (
              <div className="hor-menu__upload-tooltip" ref={el => (this.tooltip = el)}>
                <ModalUploadGif autoFocus />
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

Desktop.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  openAuthPopup: PropTypes.func.isRequired,
  // onUploadClick: PropTypes.func.isRequired,
};

export default withRouter(Desktop);
