import request from '../index';

export const getEvents = locale =>
  request(`api/events?include=cover&date=${new Date()}`, { locale });

export const getEvent = async (slug) => {
  const url = `api/events?slug=${encodeURIComponent(slug)}&include=cover`;
  const [event] = await request(url, { locale: 'all' });
  return event;
};
