export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const fetchUserRequest = id => ({
  id,
  type: FETCH_USER_REQUEST,
});

export const fetchUserFailure = error => ({
  error,
  type: FETCH_USER_FAILURE,
});

export const fetchUserSuccess = (id, entities) => ({
  id,
  entities,
  type: FETCH_USER_SUCCESS,
});
