import seedrandom from 'seedrandom';
import { getMediaSrc } from './url';

export const getGalleryLdJson = ({
  intl,
  intlPrefix,
  media,
  server,
  url,
  seedKey,
  messageValues,
}) => {
  const mediaAuthor = media.author || 'unknown';
  const mediaDate = media.meta && media.meta.date
    ? (new Date(media.meta.date)).toISOString()
    : media.createdAt;
  const thumbnailUrl = getMediaSrc(server, media.file.path, 'gif', { width: 300 });
  const startDay = new Date();
  startDay.setHours(0, 0, 0, 0);

  // generate datePublished between 2014 & 2018
  const rng = seedrandom(seedKey); // tagName
  const datePublished = new Date(1408532845667 + (rng() * 126230426515));

  return {
    '@context': 'http://schema.org',
    '@type': 'Article',
    author: intl.formatMessage({ id: `${intlPrefix}author` }, messageValues),
    creator: intl.formatMessage({ id: `${intlPrefix}creator` }, messageValues),
    headline: intl.formatMessage({ id: `${intlPrefix}headline` }, messageValues),
    name: intl.formatMessage({ id: `${intlPrefix}name` }, messageValues),
    url,
    mainEntityOfPage: url,
    keywords: intl.formatMessage({ id: `${intlPrefix}keywords` }, messageValues),
    description: intl.formatMessage({ id: `${intlPrefix}description` }, messageValues),
    datePublished,
    dateModified: startDay.toISOString(),
    publisher: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: intl.formatMessage({ id: `${intlPrefix}publisher` }, messageValues),
      logo: {
        '@type': 'ImageObject',
        url: 'https://gifer.b-cdn.net/icons/768x1004.png',
      },
    },
    image: {
      '@context': 'http://schema.org',
      '@type': 'ImageObject',
      author: mediaAuthor,
      creator: mediaAuthor,
      name: intl.formatMessage({ id: `${intlPrefix}image_name` }, messageValues),
      keywords: intl.formatMessage({ id: `${intlPrefix}image_keywords` }, messageValues),
      description: intl.formatMessage({ id: `${intlPrefix}image_description` }, messageValues),
      url,
      contentUrl: `${server}/${media.id}.gif`,
      thumbnailUrl,
      width: media.width,
      height: media.height,
      dateCreated: mediaDate,
      uploadDate: mediaDate,
      representativeOfPage: true,
    },
    video: {
      '@context': 'http://schema.org',
      '@type': 'VideoObject',
      author: mediaAuthor,
      creator: mediaAuthor,
      name: intl.formatMessage({ id: `${intlPrefix}image_name` }, messageValues),
      keywords: intl.formatMessage({ id: `${intlPrefix}image_keywords` }, messageValues),
      description: intl.formatMessage({ id: `${intlPrefix}image_description` }, messageValues),
      url,
      contentUrl: `${server}/${media.id}.mp4`,
      thumbnailUrl,
      width: media.width,
      height: media.height,
      dateCreated: mediaDate,
      uploadDate: mediaDate,
    },
  };
};
