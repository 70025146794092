import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './index.css';

class ActionAnimation extends Component {
  componentDidMount() {
    const delay = this.props.animationDelay + this.props.fadeMs;
    setTimeout(this.props.onAnimationEnd, delay);
  }

  render() {
    return (
      <div className="page-media-action-animation">
        <div
          className="page-media-action-animation__inner"
          style={{
            animationDelay: `${this.props.animationDelay}ms`,
            animationDuration: `${this.props.fadeMs}ms`,
          }}
        >
          <div className="page-media-action-animation__cover" />
          <div className="page-media-action-animation__icon">
            {this.props.icon}
          </div>

          <div className="page-media-action-animation__text">
            {this.props.text}
          </div>
        </div>
      </div>
    );
  }
}

ActionAnimation.propTypes = {
  text: PropTypes.node,
  icon: PropTypes.node,
  animationDelay: PropTypes.number,
  fadeMs: PropTypes.number,
  onAnimationEnd: PropTypes.func,
};

ActionAnimation.defaultProps = {
  text: 'text',
  icon: 'icon',
  animationDelay: 1000,
  fadeMs: 200,
  onAnimationEnd: () => { },
};

export default ActionAnimation;
