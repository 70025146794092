import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './index.css';

class AlertProvider extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen || this.props.message !== nextProps.message) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.closeAlert();
      }, 2000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const classes = ['alert', this.props.type];
    if (this.props.isOpen) {
      classes.push('visible');
    }

    return (
      <div className="alert-provider">
        <div
          className={classes.join(' ')}
          onClick={() => this.props.closeAlert()}
        >
          {this.props.message
            && <FormattedMessage id={this.props.message} values={this.props.values} />}
        </div>
      </div>
    );
  }
}

AlertProvider.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string,
  message: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any),
  closeAlert: PropTypes.func.isRequired,
};

AlertProvider.defaultProps = {
  type: 'success',
  message: null,
  values: {},
};

export default AlertProvider;
