import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '../../containers/Modal';
import EmbedForm from '../../containers/EmbedModal/EmbedForm';
import './index.css';

const EmbedModal = ({ id }) => (
  <Modal className="embed-modal">
    <div className="embed-modal__header">
      <FormattedMessage id="embed-modal.greeting" />
    </div>
    <div className="embed-modal__description">
      <FormattedMessage id="embed-modal.description" />
    </div>

    <EmbedForm id={id} />
  </Modal>
);

EmbedModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EmbedModal;
