import { connect } from 'react-redux';
import AutoPlayButton from '../../components/AutoPlayButton';
import { enable, disable } from '../../actions/autoPlay';
import storage from '../../utils/localStorage';

const mapStateToProps = state => ({
  autoPlay: state.autoPlay,
  text: state.env.width > 420,
});

const mapDispatchToProps = dispatch => ({
  enable: () => {
    dispatch(enable());
    storage.setItem('autoplay', JSON.stringify(true));
  },
  disable: () => {
    dispatch(disable());
    storage.setItem('autoplay', JSON.stringify(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoPlayButton);
