import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Header } from '../Common';
import AutoPlayButton from '../../containers/AutoPlayButton';
import CategoryTags from '../../containers/CategoryTags';
import Meta from '../../containers/Meta';
import { getMediaSrc } from '../../utils/url';
import AnalyticsPageView from '../AnalyticsPageView';
import './index.css';

const PageCategory = ({ category, intl }) => (
  <main className="page-category">
    <AnalyticsPageView />
    <Meta
      showHreflang={false}
      id="page_category"
      values={{ category: category.name }}
      url={`/category/${category.slug}`}
      meta={[
        {
          property: 'og:image',
          content: getMediaSrc('https://i.gifer.com', `${category.image}`, 'gif'),
        },
      ]}
    />
    <article>
      <div className="page-category__header">
        <div className="page-category__header-h1">
          <Header title={intl.formatMessage({ id: 'page_category.title' }, { category: category.name })} element="h1" />
        </div>
        <AutoPlayButton />
      </div>
      <CategoryTags id={category.id} />
    </article>
  </main>
);

PageCategory.propTypes = {
  intl: intlShape.isRequired,
  category: PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default injectIntl(PageCategory);
