import { takeEvery, call, put } from 'redux-saga/effects';
import * as actions from '../../actions/likes';
import * as api from '../../utils/api/likes';

function* likeMedia(action) {
  try {
    yield call(api.likeMedia, action.id, action.like);
    yield put(actions.likeMediaSuccess(action.id, action.like));
  } catch (error) {
    yield put(actions.likeMediaFailure(error.message, action.id, action.like));
  }
}

export function* watchLikeMediaRequest() {
  yield takeEvery(actions.LIKE_MEDIA_REQUEST, likeMedia);
}
