import PropTypes from 'prop-types';
import React from 'react';
import { FontIcon } from '../../Common';
import './index.css';

const Avatar = props =>
  <div
    className="user-avatar"
    style={{
      background: 'rgb(109, 181, 172)',
      width: props.size,
      height: props.size,
    }}
  >
    <FontIcon name="icon-user" size={props.iconSize} />
  </div>;

Avatar.propTypes = {
  size: PropTypes.number,
  iconSize: PropTypes.number,
};


Avatar.defaultProps = {
  size: 70,
  iconSize: 70,
};

export default Avatar;
