import { takeEvery, call, put } from 'redux-saga/effects';
import * as actions from '../../actions/users';
import * as api from '../../utils/api/users';

function* fetchUser(action) {
  try {
    const { result, entities } = yield call(api.getUser, action.id);
    yield put(actions.fetchUserSuccess(result, entities));
  } catch (error) {
    yield put(actions.fetchUserFailure(error.message));
  }
}

export function* watchFetchUserRequest() {
  yield takeEvery(actions.FETCH_USER_REQUEST, fetchUser);
}
