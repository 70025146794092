import request from '../index';

export const getSmiles = locale =>
  request('api/smiles', { locale });

export const getSmile = async (slug) => {
  const url = `api/smiles?slug=${encodeURIComponent(slug)}`;
  const [smile] = await request(url, { locale: 'all' });
  return smile;
};
