import { connect } from 'react-redux';
import get from 'lodash/get';
import PageHome from '../../components/PageHome';

const mapStateToProps = (state) => {
  const firstMediaId = get(state.media.lists.popular, 'ids.0');
  const firstMedia = state.media.entities[firstMediaId];

  return {
    locale: state.intl.locale,
    firstMedia,
    isMobile: state.env.isMobile,
    server: firstMedia && firstMedia.file && state.uploadServers.data[firstMedia.file.server],
  };
};

export default connect(mapStateToProps)(PageHome);
