import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontIcon, Button } from '../../Common';
import Popup from '../Popup';
import './Mobile.css';

const UserPanel = props => (
  <div className="menu-user-panel mobile">
    {props.isAuth === true && (
      <Button
        type="transparent"
        style={{ justifyContent: 'flex-start', color: '#fff' }}
        href={`/user/${props.id}/touches`}
        size="s50"
        icon={(
          <FontIcon
            name="icon-user"
            size="26px"
            className="menu-user-panel__user-icon"
          />
        )}
      >
        {props.username}
      </Button>
    )}

    {props.isAuth === false && (
      <Button
        size="s50"
        style={{ justifyContent: 'flex-start', color: '#fff' }}
        onClick={props.onLoginClick}
        type="transparent"
        icon={<FontIcon name="icon-login" size="24px" />}
      >
        <FormattedMessage id="menu.auth_button" />
      </Button>
    )}

    <Popup
      isMobile
      isAuth={props.isAuth}
      onLogout={props.logout}
      locales={props.locales}
      locale={props.locale}
    >
      {props.isAuth === true && (
        <Button
          size="s50"
          type="transparent"
          icon={<FontIcon name="icon-gear" size={27} color="#fff" />}
        />
      )}

      {props.isAuth === false && (
        <Button
          style={{ color: '#fff', minWidth: 0 }}
          size="s50"
          type="transparent"
          icon={<FontIcon name="icon-globe" size={27} color="#fff" />}
        >
          {props.locale.toUpperCase()}
        </Button>
      )}
    </Popup>
  </div>
);

UserPanel.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string,
  username: PropTypes.string,
  logout: PropTypes.func.isRequired,
  onLoginClick: PropTypes.func.isRequired,
};

UserPanel.defaultProps = {
  id: undefined,
  username: undefined,
};

export default UserPanel;
