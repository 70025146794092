export const getMediaSrc = (host, path, baseFormat, params = {}) => {
  const { width, height, preset, addHttp = false } = params;
  const mediaPath = `${path}.${baseFormat}`;
  let link;

  const options = [];
  if (width) options.push(`w${width}`);
  if (height) options.push(`h${height}`);

  if (options.length) {
    options.push('preview');
    link = `${host}/fetch/${options.join('-')}/${mediaPath}`;
  } else if (preset) {
    link = `${host}/preset/${preset}/${mediaPath}`;
  } else {
    link = `${host}/origin/${mediaPath}`;
  }

  if (addHttp && !/^http/.test(link)) link = `https:${link}`;
  return link;
};
