export const LIKE_MEDIA_REQUEST = 'LIKE_MEDIA_REQUEST';
export const LIKE_MEDIA_FAILURE = 'LIKE_MEDIA_FAILURE';
export const LIKE_MEDIA_SUCCESS = 'LIKE_MEDIA_SUCCESS';

export const likeMediaRequest = (id, like = true) => ({
  id,
  like,
  type: LIKE_MEDIA_REQUEST,
});

export const likeMediaFailure = (error, id, like) => ({
  id,
  like,
  error,
  type: LIKE_MEDIA_FAILURE,
});

export const likeMediaSuccess = (id, like) => ({
  id,
  like,
  type: LIKE_MEDIA_SUCCESS,
});
