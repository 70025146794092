export const FETCH_MEDIA_LIST_REQUEST = 'FETCH_MEDIA_LIST_REQUEST';
export const FETCH_MEDIA_LIST_SUCCESS = 'FETCH_MEDIA_LIST_SUCCESS';
export const FETCH_MEDIA_LIST_FAILURE = 'FETCH_MEDIA_LIST_FAILURE';
export const SAVE_MEDIA_LIST_FILTER = 'SAVE_MEDIA_LIST_FILTER';
export const SAVE_MEDIA_PAGE_FILTER = 'SAVE_MEDIA_PAGE_FILTER';

export const fetchMediaListRequest = (list, skip, limit) => ({
  list,
  skip,
  limit,
  type: FETCH_MEDIA_LIST_REQUEST,
});

export const fetchMediaListSuccess = (list, ids, entities, append, count) => ({
  type: FETCH_MEDIA_LIST_SUCCESS,
  list,
  ids,
  append,
  count,
  entities,
});

export const fetchMediaListFailure = (list, error) => ({
  type: FETCH_MEDIA_LIST_FAILURE,
  list,
  error,
});

export const saveMediaListFilter = filter => ({
  filter,
  type: SAVE_MEDIA_LIST_FILTER,
});

export const saveMediaPageFilter = filter => ({
  filter,
  type: SAVE_MEDIA_PAGE_FILTER,
});
