export const SHOW_ALERT = 'SHOW_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';

export const showAlert = (message, alertType = 'success', values = {}) => ({
  values,
  message,
  alertType,
  type: SHOW_ALERT,
});

export const closeAlert = () => ({
  type: CLOSE_ALERT,
});
