import get from 'lodash/get';
import ReactGA from 'react-ga'

const gtm = (args) => {
  const f = typeof window === 'undefined' ? [] : get(window, 'dataLayer', []);
  return f.push(args);
};

export function initAnalytics() {
  ReactGA.initialize('UA-100945136-2');
}

export function analyticsPageView() {
  if (typeof window !== 'undefined') {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

export function analyticsEvent(category, action) {
  ReactGA.event({ category, action, nonInteraction: true });
}

export default gtm;
