import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getMediaSrc } from '../../utils/url';
import { FontIcon, Button } from '../Common';
import Header from './Header';
import Share from './Share';
import './index.css';

class PageEmbed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: true,
      showShare: false,
    };
  }

  handleClickShare = () => {
    this.setState({
      showHeader: false,
      showShare: true,
    });
  }

  handleCloseShare = () => {
    this.setState({
      showHeader: true,
      showShare: false,
    });
  }

  render() {
    const { id, server, file, alt, locale, width, height } = this.props;

    const croppedMaxWidth = 400;
    const croppedMaxHeight = height * (croppedMaxWidth / width);

    const originalPath = getMediaSrc(this.props.server, file.path, 'gif');
    const croppedPath = getMediaSrc(this.props.server, `${file.path}_w200`, 'gif');
    const gifSrc = `${server}/${id}.gif`;
    const wepbSrc = `${server}/${id}.webp`;
    const downloadUrl = `${server}/embedded/download/${id}.gif`;
    const url = `${process.env.REACT_APP_DOMAIN}/${id}`;

    return (
      <div className={`page-embed ${this.props.isMobile ? 'page-embed_mobile' : 'page-embed_desktop'}`}>
        {this.state.showHeader && (
          <div className="page-embed__header">
            <Header onClickShare={this.handleClickShare} />
          </div>
        )}

        <div className="page-embed__header_mini">
          <Button
            href={url}
            external
            type="transparent"
            icon={<FontIcon name="icon-share" color="white" />}
          />
        </div>

        {this.state.showShare && (
          <div className="page-embed__share">
            <Share
              id={id}
              locale={locale}
              onCloseRequest={this.handleCloseShare}
              downloadUrl={downloadUrl}
              gifSrc={gifSrc}
            />
          </div>
        )}
        <a href={`${url}?utm_source=${encodeURIComponent(document.referrer)}&utm_medium=embed-action&utm_campaign=iFRame`} target="_blank" rel="noopener noreferrer" className="page-embed__media">
          {server && (
            <picture>
              <source
                media={`(max-width: ${croppedMaxWidth}px) and (max-height: ${croppedMaxHeight}px)`}
                srcSet={croppedPath}
                type="image/gif"
              />

              {file.formats.includes('webp') && (
                <source srcSet={wepbSrc} type="image/webp" />
              )}

              <source srcSet={originalPath} type="image/gif" />
              <img
                className="page-embed__image"
                itemProp="contentUrl"
                alt={alt}
                src={originalPath}
              />
            </picture>
          )}
        </a>
      </div>
    );
  }
}

PageEmbed.propTypes = {
  isMobile: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  server: PropTypes.string,
  file: PropTypes.objectOf(PropTypes.any),
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

PageEmbed.defaultProps = {
  isMobile: false,
  alt: undefined,
  server: undefined,
  width: 0,
  height: 0,
  file: {
    formats: [],
  },
};

export default PageEmbed;
