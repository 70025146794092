export const FETCH_MEDIA_REQUEST = 'FETCH_MEDIA_REQUEST';
export const FETCH_MEDIA_FAILURE = 'FETCH_MEDIA_FAILURE';
export const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS';
export const CREATE_MEDIA_REQUEST = 'CREATE_MEDIA_REQUEST';
export const CREATE_MEDIA_SUCCESS = 'CREATE_MEDIA_SUCCESS';
export const CREATE_MEDIA_FAILURE = 'CREATE_MEDIA_FAILURE';
export const ADD_MEDIA_TO_LIST = 'ADD_MEDIA_TO_LIST';
export const INC_MEDIA_STAT = 'INC_MEDIA_STAT';
export const INC_MEDIA_STAT_FAILURE = 'INC_MEDIA_STAT_FAILURE';

export const fetchMediaRequest = id => ({
  id,
  type: FETCH_MEDIA_REQUEST,
});

export const fetchMediaFailure = error => ({
  error,
  type: FETCH_MEDIA_FAILURE,
});

export const fetchMediaSuccess = (id, entities) => ({
  id,
  entities,
  type: FETCH_MEDIA_SUCCESS,
});

export const createMediaRequest = media => ({
  media,
  type: CREATE_MEDIA_REQUEST,
});

export const createMediaFailure = error => ({
  error,
  type: CREATE_MEDIA_FAILURE,
});

export const createMediaSuccess = (id, entities) => ({
  id,
  entities,
  type: CREATE_MEDIA_SUCCESS,
});

export const addMediaToList = (list, id) => ({
  id,
  list,
  type: ADD_MEDIA_TO_LIST,
});

export const incMediaStat = data => ({
  data,
  type: INC_MEDIA_STAT,
});

export const incMediaStatFailure = error => ({
  error,
  type: INC_MEDIA_STAT_FAILURE,
});
