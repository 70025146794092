import React from 'react';
import PropTypes from 'prop-types';
import Item from '../../Item';
import './index.css';

const CarouselMobile = ({ data, colors, slideWidth, slidesToShow }) => (
  <ul className="events-panel-carousel-mobile">
    {data.map((item, i) => (
      <li
        key={item.id}
        className="events-panel__item"
        style={{ width: `${(slideWidth * 100) / slidesToShow}%` }}
      >
        <article className="events-panel__item-inner">
          <Item {...item} borderColor={colors[i % colors.length]} />
        </article>
      </li>
    ))}
  </ul>
);

CarouselMobile.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.arrayOf(PropTypes.string),
  slideWidth: PropTypes.number,
  slidesToShow: PropTypes.number,
};

CarouselMobile.defaultProps = {
  data: [],
  colors: [],
  slideWidth: 1,
  slidesToShow: 1,
};

export default CarouselMobile;
