import React from 'react';
import Mobile from './Layouts/Mobile';
import Desktop from './Layouts/Desktop';
import DeviceLayout from '../../containers/DeviceLayout';

const SearchResults = props =>
  <DeviceLayout
    mobile={<Mobile {...props} />}
    desktop={<Desktop {...props} />}
  />;

export default SearchResults;
