import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, FontIcon } from '../../Common';
import Input from '../Input';
import SocialButtons from './SocialButtons';
import './index.css';

class PartHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: props.email };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.clearAuthError();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const emailCheck = nextProps.emailChecks[this.state.email];
    if (typeof emailCheck !== 'undefined') {
      this.props.onSubmitEmail(this.state.email, emailCheck);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.checkEmail(this.state.email);
  }

  render() {
    return (
      <div className="auth-part-home content">
        <div className="header">
          <FormattedMessage id="auth.greeting" />
        </div>
        <SocialButtons onClick={this.props.socialLogin} />
        <div style={{ marginTop: 10 }}>
          {this.props.showInput
            ? (
              <form onSubmit={this.handleSubmit}>
                <Input
                  autoFocus
                  required
                  type="email"
                  value={this.state.email}
                  onChange={event => this.setState({ email: event.target.value })}
                  placeholder={this.props.intl.formatMessage({ id: 'auth.placeholder.email' })}
                />
                <Button
                  block
                  nativeProps={{ type: 'submit' }}
                  type="gradient"
                  iconPosition="right"
                  icon={<FontIcon name="icon-arrow-right" color="#fff" size={23} />}
                  style={{ marginTop: 10 }}
                  size="s50"
                >
                  <FormattedMessage id="next" />
                </Button>
              </form>
            )
            : (
              <Button block onClick={this.props.toggleInput} size="s50">
                <FormattedMessage id="auth.email_login_button" />
              </Button>
            )}
        </div>
        <div
          className="other-methods-button"
          onClick={this.props.showOtherMethods}
        >
          <FormattedMessage id="auth.header_social_auth" />
        </div>
      </div>
    );
  }
}

PartHome.propTypes = {
  intl: intlShape.isRequired,
  email: PropTypes.string,
  showInput: PropTypes.bool.isRequired,
  emailChecks: PropTypes.object.isRequired, // eslint-disable-line
  onSubmitEmail: PropTypes.func.isRequired,
  showOtherMethods: PropTypes.func.isRequired,
  checkEmail: PropTypes.func.isRequired,
  clearAuthError: PropTypes.func.isRequired,
  socialLogin: PropTypes.func.isRequired,
  toggleInput: PropTypes.func.isRequired,
};

PartHome.defaultProps = {
  email: '',
};

export default injectIntl(PartHome);
