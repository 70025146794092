import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FontIcon from '../../Common/FontIcon';
import './index.css';

class FullscreenView extends Component {
  componentDidMount() {
    document.querySelector('body').classList.add('noscroll');
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove('noscroll');
  }

  render() {
    const mp4Src = `${this.props.server}/${this.props.id}.mp4`;

    return (
      <div className="fullscreenview">
        <div
          className="fullscreenview__nav fullscreenview__nav-left"
          onClick={this.props.onClickPrev}
        >
          <div className="fullscreenview__nav-icon">
            <FontIcon name="icon-arrow-left-2" color="#fff" size={50} />
          </div>
        </div>

        <div className="fullscreenview__media" onClick={this.props.clickMedia}>
          <video
            key={mp4Src}
            className="fullscreenview__media-video"
            loop
            autoPlay
            muted
            playsInline
          >
            <source src={mp4Src} type="video/mp4" />
          </video>
        </div>

        <div
          className="fullscreenview__nav fullscreenview__nav-right"
          onClick={this.props.onClickNext}
        >
          <div className="fullscreenview__nav-icon">
            <FontIcon name="icon-arrow-right-2" color="#fff" size={50} />
          </div>
        </div>
      </div>
    );
  }
}

FullscreenView.propTypes = {
  id: PropTypes.string.isRequired,
  server: PropTypes.string,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
  clickMedia: PropTypes.func,
};

export default FullscreenView;
