import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './index.css';
import { injectIntl, intlShape } from 'react-intl';

const SmileItem = ({ slug, name, src, isDimmed, isActive, intl }) => {
  const classes = [
    'smiles-panel-smile-item',
    isDimmed ? 'smiles-panel-smile-item_dimmed' : '',
    isActive ? 'smiles-panel-smile-item_active' : '',
  ];

  return (
    <NavLink className={classes.join(' ')} to={`/emoji/${slug}`}>
      <img
        src={src}
        alt={intl.formatMessage({ id: 'smile_alt' }, { name })}
        className="smiles-panel-smile-item__image"
      />
    </NavLink>
  );
};

SmileItem.propTypes = {
  slug: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDimmed: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SmileItem);
