import PropTypes from 'prop-types';
import React from 'react';
import './index.css';

const HighlightText = ({ highlight, text, className }) => {
  const highlightTrimmed = highlight.trim().replace(/[-[\]{}()*+?,\\^$|#\s]/g, '\\$&');
  const regexp = new RegExp(`((?:\\s)|(?:^))(${highlightTrimmed})`, 'ig');
  const parts = text.split(regexp);

  const newText = parts.map((part, i) => {
    const partLowered = part.toLowerCase();
    const highlightLowered = highlightTrimmed.toLowerCase();
    return partLowered === highlightLowered // eslint-disable-line
      ? <span key={i} className={className}>{part}</span>
      : <span key={i}>{part}</span>;
  });

  return (
    <span className="highlight-text">
      {newText}
    </span>
  );
};

HighlightText.propTypes = {
  highlight: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
};

HighlightText.defaultProps = {
  className: 'highlight-text__highlight',
};

export default HighlightText;
