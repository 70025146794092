import React, { Component } from 'react';
import PropTypes from 'prop-types';
import randomColor from 'randomcolor';
import { Link } from 'react-router-dom';
import { tagToURI } from '../../../utils/tagToURI';
import './index.css';

class SearchHintItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      color: randomColor({ luminosity: 'light' }),
    };
  }

  render() {
    const { color, isHovered } = this.state;
    const { hint } = this.props;

    return (
      <Link
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
        onTouchStart={() => this.setState({ isHovered: true })}
        onTouchEnd={() => this.setState({ isHovered: false })}
        style={{
          color: isHovered ? 'black' : color,
          background: isHovered ? color : undefined,
        }}
        key={hint}
        to={`/gifs/${tagToURI(hint)}`}
      >
        {hint}
      </Link>
    );
  }
}

SearchHintItem.propTypes = {
  hint: PropTypes.string.isRequired,
};

const SearchHints = props => (
  <ul className={`search-hints ${props.isMobile ? 'mobile' : 'desktop'}`}>
    {props.isLoaded && props.hints.map(hint => (
      <li className="search-hints__item" key={hint}>
        <SearchHintItem hint={hint} />
      </li>
    ))}
    {!props.isLoaded && [...Array(6)].map((el, index) => (
      <li className="search-hints__stub" key={index} /> //eslint-disable-line
    ))}
  </ul>
);

SearchHints.propTypes = {
  hints: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool,
  isLoaded: PropTypes.bool,
};

SearchHints.defaultProps = {
  isMobile: false,
  isLoaded: false,
};

export default SearchHints;
