import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as actions from '../../actions/searchHints';
import { getLocale } from '../selectors';
import * as api from '../../utils/api/search';

function* fetchSearchHints() {
  try {
    const locale = yield select(getLocale);
    const { result } = yield call(api.getHints, locale);
    yield put(actions.fetchSearchHintsSuccess(result));
  } catch (error) {
    yield put(actions.fetchSearchHintsFailure(error));
  }
}

export function* watchFetchSearchHintsRequest() {
  yield takeEvery(actions.FETCH_SEARCH_HINTS_REQUEST, fetchSearchHints);
}
